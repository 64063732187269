export const keys = {
  LS_PENDING_IMAGES_FOR_UPLOAD: 'pending-images-for-upload',
  LS_SHOW_UPLOADING_STATUS_LIST: 'show-uploading-status-list',
  LS_LAST_SEEN_CHANGELOG_VERSION: 'last-seen-changelog-version-2',
  LS_OVERRIDDEN_ACCOUNT_ID: 'overridden-account-id',
  LS_SAVED_LOCALE: 'saved-locale',
  SS_SCREENER_FILTER_VALUE: 'screener-filter-value',
  SS_SCREENING_LIST_PERIOD: 'screening-list-period',
  SS_SCREENING_LIST_STATUS: 'screening-list-status',
}
