import { DB_WASTE_ITEMS, setIDOnCreate } from './_helper'

export default class WasteItem {
  constructor({
    id,
    groupedTypeID,
    isDone,
    materialAmount,
    coatingAmount,
  }) {
    this.id = setIDOnCreate(id, DB_WASTE_ITEMS)
    this.groupedTypeID = groupedTypeID
    this.isDone = isDone || false
    this.materialAmount = materialAmount ?? ''
    this.coatingAmount = coatingAmount ?? ''
  }
}
