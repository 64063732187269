/* eslint-disable no-use-before-define */
import { union } from 'lodash-es'
import { firebase, db } from '@/firebase/init'
import { keys } from '@/globals/javascript/_util/keys'
import Screening from '@/globals/javascript/models/Screening'
import EventBus from '@/EventBus'
import {
  DB_ACCOUNTS,
  DB_ADDRESS_IMAGES,
  DB_BUILDINGS,
  DB_META_DATA,
  DB_PCB_SCREENINGS,
  DB_SAMPLES,
  DB_SCREENINGS,
  DB_TEST_RESULTS,
  DB_USERS,
  DB_UNITS,
  DB_TYPES,
  DB_WASTE_ITEMS,
} from '@/globals/javascript/models/_helper'
import Type from '@/globals/javascript/models/Type'
import Sample from '@/globals/javascript/models/Sample'
import { Building } from '@/globals/javascript/models/units/Building'
import { Floor } from '@/globals/javascript/models/units/Floor'
import { Apartment } from '@/globals/javascript/models/units/Apartment'
import WasteItem from '@/globals/javascript/models/WasteItem'

export const actions = {
  resetAllScreenings: ({ commit }) => {
    commit('resetAllLoadedScreenings')
    commit('resetCurrentScreening')
  },
  // Getters
  getCurrentScreening: ({ commit, getters, dispatch }, screeningID) => {
    if (getters.currentScreening.id === screeningID) {
      return
    }

    // Reset current screening data
    commit('resetCurrentScreening')

    // Get screening data as listener
    const unsubscribe = db.collection(DB_SCREENINGS).doc(screeningID)
      .onSnapshot((doc) => {
        const data = new Screening({
          ...doc.data(),
          id: doc.id,
        })
        commit('updateCurrentScreening', {
          screeningID,
          unsubscribe,
          data,
        })

        // Reset related screenings
        if (getters.currentEnterpriseID !== data.enterpriseID) {
          commit('resetRelatedScreenings')
          commit('updateCurrentEnterpriseID', data.enterpriseID)
        }

        // Get related screenings if needed
        if (!getters.relatedScreeningsLoaded && data.enterpriseID) {
          dispatch('getRelatedScreenings', {
            currentScreeningID: data.id,
            enterpriseID: data.enterpriseID,
          })
        }
      })
  },
  getRelatedScreenings: ({ commit }, { currentScreeningID, enterpriseID }) => {
    const unsubscribe = db
      .collection(DB_SCREENINGS)
      .where('enterpriseID', '==', enterpriseID)
      .where('id', '!=', currentScreeningID)
      .onSnapshot(async (querySnapshot) => {
        const relatedScreeningsPromises = querySnapshot.docs.map(async (doc) => {
          const screening = {
            data: null,
            types: [],
            samples: [],
            units: [],
            testResults: [],
          }

          // Get screening data
          screening.data = new Screening({ ...doc.data() })

          // Get screening types
          const typesSnapshot = await db
            .collection(DB_SCREENINGS)
            .doc(screening.data.id)
            .collection(DB_TYPES)
            .get()
          typesSnapshot.docs.forEach((doc) => {
            screening.types.push(new Type({ ...doc.data() }))
          })

          // Get screening samples
          const samplesSnapshot = await db
            .collection(DB_SCREENINGS)
            .doc(screening.data.id)
            .collection(DB_SAMPLES)
            .get()
          samplesSnapshot.docs.forEach((doc) => {
            screening.samples.push(new Sample({ ...doc.data() }))
          })

          // Get screening units
          const unitsSnapshot = await db
            .collection(DB_SCREENINGS)
            .doc(screening.data.id)
            .collection(DB_UNITS)
            .where('isSelected', '==', true)
            .get()
          unitsSnapshot.docs.forEach((doc) => {
            const data = doc.data()
            if (data.type === 'building') {
              screening.units.push(new Building({ ...data }))
            }
            if (data.type === 'floor') {
              screening.units.push(new Floor({ ...data }))
            }
            if (data.type === 'apartment') {
              screening.units.push(new Apartment({ ...data }))
            }
          })

          // Get screening test results
          const testResultsSnapshot = await db
            .collection(DB_SCREENINGS)
            .doc(screening.data.id)
            .collection(DB_TEST_RESULTS)
            .doc(screening.data.id)
            .get()
          const testResultsData = testResultsSnapshot?.data?.()
          screening.testResults = testResultsData?.testResults ?? []

          return screening
        })

        const relatedScreenings = await Promise.all(relatedScreeningsPromises)

        commit('updateRelatedScreenings', { relatedScreenings, unsubscribe })
      })
  },
  getScreeningMetaData: ({ commit, getters }, screeningID) => {
    if (getters.currentScreeningMetaDataLoaded) {
      return
    }

    const unsubscribe = db
      .collection(DB_SCREENINGS)
      .doc(screeningID)
      .collection(DB_META_DATA)
      .doc(screeningID)
      .onSnapshot((doc) => {
        commit('updateCurrentScreeningMetaData', {
          unsubscribe,
          data: doc.data(),
        })
      })
  },
  getScreeningBuildings: ({ commit, getters }, screeningID) => {
    if (getters.currentScreeningBuildingsLoaded) {
      return
    }

    const unsubscribe = db
      .collection(DB_SCREENINGS)
      .doc(screeningID)
      .collection(DB_BUILDINGS)
      .onSnapshot((querySnapshot) => {
        const buildings = []
        querySnapshot.forEach((doc) => {
          buildings.push(doc.data())
        })
        commit('updateCurrentScreeningBuildings', { buildings, unsubscribe })
      })
  },
  getScreeningAddressImages: ({ commit, getters }, screeningID) => {
    if (getters.addressImagesLoaded) {
      return
    }
    const unsubscribe = db
      .collection(DB_SCREENINGS)
      .doc(screeningID)
      .collection(DB_ADDRESS_IMAGES)
      .doc(screeningID)
      .onSnapshot((doc) => {
        commit('updateCurrentScreeningAddressImages', {
          unsubscribe,
          addressImages: doc.data(),
        })
      })
  },
  getScreeningPCBScreenings: ({ commit, getters }, screeningID) => {
    if (getters.currentScreeningPCBScreeningLoaded) {
      return
    }

    const unsubscribe = db
      .collection(DB_SCREENINGS)
      .doc(screeningID)
      .collection(DB_PCB_SCREENINGS)
      .onSnapshot((querySnapshot) => {
        const pcbScreenings = []
        querySnapshot.forEach((doc) => {
          pcbScreenings.push({
            ...doc.data(),
            id: doc.id,
          })
        })

        commit('updateCurrentScreeningPCBScreenings', { pcbScreenings, unsubscribe })
      })
  },
  getCurrentScreeningTestResults: ({ commit, getters }, screeningID) => {
    if (getters.currentScreeningTestResultsLoaded) {
      return
    }

    const unsubscribe = db
      .collection(DB_SCREENINGS)
      .doc(screeningID)
      .collection(DB_TEST_RESULTS)
      .doc(screeningID)
      .onSnapshot((doc) => {
        commit('updateCurrentScreeningTestResults', {
          unsubscribe,
          testResults: doc.data(),
        })
      })
  },
  getCurrentScreeningWasteItems: ({ commit, getters }, screeningID) => {
    if (getters.currentScreeningWasteItemsLoaded) {
      return
    }

    const unsubscribe = db
      .collection(DB_SCREENINGS)
      .doc(screeningID)
      .collection(DB_WASTE_ITEMS)
      .onSnapshot((querySnapshot) => {
        const wasteItems = []
        querySnapshot.forEach((doc) => {
          wasteItems.push(new WasteItem({
            ...doc.data(),
          }))
        })

        commit('updateCurrentScreeningWasteItems', { wasteItems, unsubscribe })
      })
  },
  getAllScreenings: ({ commit, getters }) => {
    const fromDate = new Date()
    const savedPeriod = window.sessionStorage.getItem(keys.SS_SCREENING_LIST_PERIOD)
    const period = savedPeriod ? Number(savedPeriod) : 1
    fromDate.setMonth(fromDate.getMonth() - period)

    const accountID = getters.currentUserAccountId
    const unsubscribe = db.collection(DB_SCREENINGS)
      .where('accountID', '==', accountID)
      .where('lastUpdated', '>=', fromDate)
      .orderBy('lastUpdated', 'desc')
      .onSnapshot((querySnapshot) => {
        const allScreenings = []
        querySnapshot.forEach((doc) => {
          allScreenings.push(new Screening({
            ...doc.data(),
            id: doc.id,
          }))
        })
        commit('updateAllScreenings', { allScreenings, unsubscribe })
      })
  },
  getAllScreeningsAgain: ({ commit, dispatch }) => {
    commit('resetAllLoadedScreenings')
    dispatch('getAllScreenings')
  },

  // Setters
  setCurrentScreeningID: ({ commit }, id) => {
    commit('setCurrentScreeningID', id)
  },
  setCurrentScreeningSelectedFilterUnitID: ({ commit }, unitID) => {
    commit('updateCrrentScreeningSelectedFilterUnitID', unitID)
  },
  addScreening: ({ getters }, selectedAddress) => {
    let createdByUserID = getters.currentUser.id
    if (getters.overridenAccountID) {
      // Admin and on behalf of other account, we need to set createdByUserID
      // to a person actually in account
      createdByUserID = (getters.allUsers.find(
        (user) => user.isScreener,
      ) || getters.allUsers[0]
      )?.id
    }
    const screening = new Screening({
      createdByUserID,
      accountID: getters.currentAccount.id,
      address: {
        address: `${ selectedAddress.data.adresseringsvejnavn } ${ selectedAddress.data.husnr }`,
        postalCode: selectedAddress.data.postnr,
        city: selectedAddress.data.postnrnavn,
      },
    })

    try {
      // Add screening to firebase
      db.collection(DB_SCREENINGS).doc(screening.id).set({
        ...screening,
      })

      // Add meta data to screening
      db.collection(DB_SCREENINGS).doc(screening.id).collection(DB_META_DATA).doc(screening.id)
        .set({
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
          address: selectedAddress,
        })

      EventBus.$emit('screening-is-added', screening.id)
    }
    catch (err) {
      EventBus.$emit('screening-added-failed', err)
    }
  },
  setBuilderInfo: ({ getters, dispatch }, {
    fullName, phoneNumber, email, address, postalCode, city,
  }) => {
    db
      .collection(DB_SCREENINGS)
      .doc(getters.currentScreening.id)
      .collection(DB_META_DATA)
      .doc(getters.currentScreening.id)
      .set({
        lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
        builder: {
          fullName,
          phoneNumber,
          email,
          address: {
            address,
            postalCode,
            city,
          },
        },
      }, { merge: true })

    // Update screening
    const screening = new Screening({
      ...getters.currentScreeningData,
    })

    screening.builderFullName = fullName

    dispatch('updateScreening', { screening })
  },
  setTenderDetails: ({ getters, dispatch }, tenderDetails) => {
    // Update screening
    const screening = new Screening({
      ...getters.currentScreeningData,
    })

    screening.tenderDetails = tenderDetails

    dispatch('updateScreening', { screening })
  },
  setProjectDetails: ({ getters, dispatch }, {
    screeningNumber,
    selectedScreener,
    projectType,
    doWasteReview,
    projectDescription,
  }) => {
    const screening = new Screening({
      ...getters.currentScreeningData,
    })
    const isFirstSave = !screening.screeningNumber

    // Save meta data
    db
      .collection(DB_SCREENINGS)
      .doc(getters.currentScreening.id)
      .collection(DB_META_DATA)
      .doc(getters.currentScreening.id)
      .set({
        lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
        projectType,
        doWasteReview,
        projectDescription,
      }, { merge: true })

    // Update screening
    screening.screeningNumber = screeningNumber
    screening.userID = selectedScreener
    screening.projectType = projectType
    dispatch('updateScreening', { screening })

    // Update next screening number
    if (isFirstSave && screeningNumber) {
      db.collection(DB_ACCOUNTS).doc(screening.accountID).set({
        nextScreeningNumber: screeningNumber + 1,
      }, { merge: true })
    }
  },
  setAddressImages: ({ getters, dispatch }, {
    imageList,
    place,
    imageType,
  }) => {
    // Update screening
    const { currentScreeningData } = getters
    const screening = new Screening({
      ...currentScreeningData,
    })

    // Update screening cover image
    if (place === 'overview' && imageType === 'profile') {
      if (!imageList.length && currentScreeningData.frontImage) {
        screening.frontImage = null
      }
      if (imageList.length && !currentScreeningData.frontImage) {
        const image = imageList[0]
        screening.frontImage = image
      }
    }

    if (imageType === 'floorPlans' && !currentScreeningData.isStarted) {
      dispatch('updateScreening', { screening, updateScreeningTimeUsed: true })
    }
    else if (
      imageType === 'cadastralMap'
      || (imageType === 'floorPlans' && currentScreeningData.isCompleted)
    ) {
      dispatch('updateScreening', { screening })
    }
    else {
      dispatch('updateScreening', { screening, setAsStarted: true, setAsNotCompleted: true })
    }

    // Save address images
    const createdAt = getters.addressImages
      ? getters.addressImages.createdAt
      : firebase.firestore.FieldValue.serverTimestamp()
    const dataToSave = {}

    if (place === 'overview') {
      dataToSave.overview = {
        [imageType]: imageList,
      }
    }
    else {
      dataToSave.units = {
        [place]: {
          [imageType]: imageList,
        },
      }
    }

    db.collection(DB_SCREENINGS)
      .doc(getters.currentScreening.id)
      .collection(DB_ADDRESS_IMAGES)
      .doc(getters.currentScreening.id)
      .set({
        ...dataToSave,
        createdAt,
        lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
      }, { merge: true })
  },
  setScreeningPCBScreening: ({ getters, dispatch }, { data, unitID, needsTesting }) => {
    const existingItem = getters.screeningPCBScreenings
      ? getters.screeningPCBScreenings.find((x) => x.id === unitID)
      : false

    const createdAt = existingItem
      ? existingItem.createdAt
      : firebase.firestore.FieldValue.serverTimestamp()

    dispatch('updateScreening', { setAsStarted: true, setAsNotCompleted: true })

    db
      .collection(DB_SCREENINGS)
      .doc(getters.currentScreening.id)
      .collection(DB_PCB_SCREENINGS)
      .doc(unitID)
      .set({
        data,
        needsTesting,
        createdAt,
        lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
      })
  },
  setWasteItem: ({ getters }, wasteItem) => {
    db
      .collection(DB_SCREENINGS)
      .doc(getters.currentScreening.id)
      .collection(DB_WASTE_ITEMS)
      .doc(wasteItem.id)
      .set({
        ...wasteItem,
      })
  },
  setSkippedCategory({ getters, dispatch }, categoryID) {
    const {
      skippedCategories,
      currentScreeningSelectedFilterUnitID,
    } = getters

    // Update screening
    const screening = new Screening({
      ...getters.currentScreeningData,
    })

    if (currentScreeningSelectedFilterUnitID) {
      screening.skippedCategories[currentScreeningSelectedFilterUnitID] = union(
        skippedCategories[currentScreeningSelectedFilterUnitID], [categoryID],
      )
    }
    else {
      screening.skippedCategories.general = union(
        skippedCategories.general, [categoryID],
      )
    }

    dispatch('updateScreening', { screening, setAsStarted: true, setAsNotCompleted: true })
  },
  setRequisitionDeliveryDayOption: ({ dispatch, getters }, value) => {
    // Update screening
    const screening = new Screening({
      ...getters.currentScreeningData,
    })

    screening.requisition.deliveryDayOptions = value

    dispatch('updateScreening', { screening })
  },
  updateScreening: ({ getters, dispatch }, {
    screening,
    setAsStarted,
    setAsCompleted,
    setAsNotCompleted,
    setAsArchived,
    setAsActive,
    setTestDataAsUploaded,
    updateScreeningTimeUsed,
  }) => {
    if (!screening) {
      screening = new Screening({
        ...getters.currentScreeningData,
      })
    }
    let updateSamplingID = false

    if (setAsStarted) {
      screening.updateScreeningTimeUsed()
      screening.isStarted = true

      if (!screening.userID) {
        screening.userID = getters.currentUser.id
      }
      if (!screening.screeningStartTime) {
        screening.screeningStartTime = firebase.firestore.FieldValue.serverTimestamp()
      }
    }

    if (setAsCompleted && !screening.isCompleted) {
      screening.updateScreeningTimeUsed()
      screening.screeningEndTime = firebase.firestore.FieldValue.serverTimestamp()
      screening.isCompleted = true

      if (getters.screeningSamples.length === 0) {
        screening.isTestDataUploaded = true
      }
    }

    if (updateScreeningTimeUsed) {
      screening.updateScreeningTimeUsed()
    }

    if (setAsNotCompleted) {
      screening.isCompleted = false
      screening.screeningEndTime = null
    }

    if (setAsArchived) {
      screening.isArchived = true
      screening.screeningArchivedTime = firebase.firestore.FieldValue.serverTimestamp()
    }

    if (setAsActive) {
      screening.isArchived = false
      screening.screeningArchivedTime = null
    }

    if (setTestDataAsUploaded) {
      screening.isTestDataUploaded = true
    }

    // Set leet and sampling IDs
    if (setAsStarted && !getters.currentScreeningData.samplingID) {
      screening.samplingID = getters.currentUser.nextPersonalSamplingID
      updateSamplingID = true
    }

    // Set last updated
    screening.lastUpdated = firebase.firestore.FieldValue.serverTimestamp()

    // Stats logic. Look if the screening enteres a state where we need to bump a stat
    const stats = {}
    if (!screening.hasStatsTracking('created')) {
      stats.created = true
      screening.trackStats('created')
    }
    if (screening.isStarted && !screening.hasStatsTracking('started')) {
      stats.started = true
      screening.trackStats('started')
    }
    if (screening.isCompleted && !screening.hasStatsTracking('completed')) {
      stats.completed = true
      screening.trackStats('completed')

      // Set screening af billable when screening is completed with no samples
      if (getters.screeningSamples.length === 0 && !screening.hasStatsTracking('billable')) {
        stats.billable = true
        screening.trackStats('billable')
      }
    }
    if (screening.isRequisitionSent && !screening.hasStatsTracking('requisitionSent')) {
      stats.requisitionSent = true
      screening.trackStats('requisitionSent')
    }
    if (screening.isTestDataUploaded && !screening.hasStatsTracking('billable')) {
      stats.billable = true
      screening.trackStats('billable')
    }

    db.collection(DB_SCREENINGS).doc(screening.id).set({
      ...screening,
    })

    // Update user's personal sample number
    if (updateSamplingID) {
      db.collection(DB_USERS).doc(getters.currentUser.id).set({
        nextPersonalSamplingID: getters.currentUser.nextPersonalSamplingID + 1,
      }, { merge: true })
    }

    if (Object.entries(stats).length) {
      dispatch('updateStats', { screening, stats })
    }
  },

  // Archivers
  archiveScreening: (store, id) => {
    db.collection(DB_SCREENINGS).doc(id).set({
      lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
      isArchived: true,
    }, { merge: true })
  },

  // Stats
  updateStats: ({ getters }, { screening, stats }) => {
    const account = getters.currentAccount
    let user = null
    if (screening.userID) {
      user = getters.allUsers.find((user) => user.id === screening.userID)
    }
    else if (screening.createdByUserID) {
      user = getters.allUsers.find((user) => user.id === screening.createdByUserID)
    }

    Object.keys(stats).forEach((key) => {
      stats[key] = firebase.firestore.FieldValue.increment(1)
    })

    const year = (new Date()).getFullYear()
    const month = (new Date()).getMonth() + 1
    const monthYear = `${ year }-${ month }`
    // Bump any stats on account and screener user.
    db.collection(DB_ACCOUNTS).doc(screening.accountID).set({
      overallStats: {
        ...account.overallStats || {},
        ...stats,
      },
      monthlyStats: {
        ...account.monthlyStats || {},
        [monthYear]: {
          ...account.monthlyStats?.[monthYear],
          ...stats,
        },
      },
    }, { merge: true })
    db.collection(DB_USERS).doc(user.id).set({
      overallStats: {
        ...user.overallStats || {},
        ...stats,
      },
      monthlyStats: {
        ...user.monthlyStats || {},
        [monthYear]: {
          ...user.monthlyStats?.[monthYear],
          ...stats,
        },
      },
    }, { merge: true })
  },
}
