import InterConnection from '@/globals/javascript/models/InterConnection'
import { db } from '@/firebase/init'
import {
  DB_INTER_CONNECTIONS,
  DB_SCREENINGS,
} from '@/globals/javascript/models/_helper'

export const interConnections = {
  getScreeningInterconnections: ({ commit, getters }, screeningID) => {
    if (getters.currentScreeningInterConnectionsLoaded) {
      return
    }

    const unsubscribe = db.collection(DB_SCREENINGS)
      .doc(screeningID).collection(DB_INTER_CONNECTIONS)
      .onSnapshot((querySnapshot) => {
        const interConnections = []
        querySnapshot.forEach((doc) => {
          interConnections.push(new InterConnection({
            ...doc.data(),
            id: doc.id,
          }))
        })

        commit('updateCurrentScreeningInterConnections', { interConnections, unsubscribe })
      })
  },
  setScreeningInterConnection: ({ getters, dispatch }, interConnection) => {
    dispatch('updateScreening', { setAsStarted: true, setAsNotCompleted: true })

    db
      .collection(DB_SCREENINGS)
      .doc(getters.currentScreening.id)
      .collection(DB_INTER_CONNECTIONS)
      .doc(interConnection.id)
      .set({
        ...interConnection,
      })
  },
  deleteInterConnection: ({ getters, dispatch }, interConnectionID) => {
    dispatch('updateScreening', { setAsStarted: true, setAsNotCompleted: true })

    db.collection(DB_SCREENINGS)
      .doc(getters.currentScreening.id)
      .collection(DB_INTER_CONNECTIONS)
      .doc(interConnectionID)
      .delete()
  },
}
