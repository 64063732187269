import { firebase } from '@/firebase/init'
import { DB_ACCOUNTS, setIDOnCreate } from '@/globals/javascript/models/_helper'
import Stats from './Stats'

export default class Account extends Stats {
  constructor({
    id,
    name,
    address,
    postalCode,
    city,
    contactPerson,
    companyInitials,
    nextScreeningNumber = 1337,
    euroFinsNumber,
    isActive = true,
    historyLog = [],
    createdDate = firebase.firestore.FieldValue.serverTimestamp(),
    images,
    // Stats properties
    ...rest
  }) {
    super(rest)

    // Raw data
    this.id = setIDOnCreate(id, DB_ACCOUNTS)
    this.name = name
    this.address = address
    this.postalCode = postalCode
    this.city = city
    this.contactPerson = {
      name: contactPerson?.name ?? '',
      phoneNumber: contactPerson?.phoneNumber ?? '',
      email: contactPerson?.email ?? '',
    }
    this.companyInitials = companyInitials
    this.nextScreeningNumber = nextScreeningNumber
    this.euroFinsNumber = euroFinsNumber
    this.isActive = isActive
    this.historyLog = historyLog
    this.createdDate = createdDate
    this.images = {
      mainLogo: images?.mainLogo ?? '',
      emailLogo: images?.emailLogo ?? '',
    }
  }
}
