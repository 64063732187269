<template>
  <div>
    <!-- Tool bar -->
    <div class="ToolBar">
      <!-- Menu icon -->
      <div
        class="IconWrap"
        @click="onBurgerMenuClick">
        <BurgerMenuIcon />
      </div>

      <!-- Logo -->
      <router-link
        :to="{ name: 'AllScreenings' }">
        <img
          class="Logo"
          src="@/assets/svg/logos/milva-logo-white.svg" />
      </router-link>

      <!-- Version indication -->
      <span class="Version">v1</span>
    </div>
    <div/>

    <!-- Menu -->
    <SidePane
      :use-component="MobileMenu"
      :is-showing="showMobileMenu"
      @close-menu="onCloseMenu" />

  </div>
</template>

<script>
import BurgerMenuIcon from '@/components/Icons/BurgerMenuIcon.vue'
import SidePane from '@/components/SidePane.vue'
import MobileMenu from '@/components/MobileMenu/MobileMenu.vue'

export default {
  data() {
    return {
      MobileMenu,
      showMobileMenu: false,
    }
  },
  methods: {
    onBurgerMenuClick() {
      this.showMobileMenu = true
    },
    onCloseMenu() {
      this.showMobileMenu = false
    },
  },
  components: {
    BurgerMenuIcon,
    SidePane,
  },
}
</script>

<style lang="stylus" scoped>
  .ToolBar
    width 100%
    height 52px
    background-color $color_primary
    display flex
    align-items center
    justify-content space-between

  .Version
    background-color #fff
    border-radius 10px
    font-size 1.5rem
    display block
    text-align center
    width 2rem
    height 2rem
    margin-right 20px
    color $color_primary
    margin-left 1rem

  .IconWrap
    height 100%
    width 52px
    display flex
    align-items center
    padding-left 20px
    width 70px
    cursor pointer

  .Logo
    width 80px

  .Placeholder
    width 52px

  @media print
    .ToolBar
      display none
</style>
