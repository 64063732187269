/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-use-before-define */
import { testDataToImport } from '@/globals/javascript/_util/test-results'
import { firebase, db } from '@/firebase/init'
import { mixWB } from '@/globals/javascript/_util/mixins'
import readXlsxFile from 'read-excel-file'
import * as Sentry from '@sentry/browser'
import { DB_SCREENINGS, DB_TEST_RESULTS } from '@/globals/javascript/models/_helper'

export const testDataUpload = {
  state: {
    testDataUploadStatus: '',
  },
  mutations: {
    updateDataUploadStatus: (state, status) => {
      state.testDataUploadStatus = status
    },
  },
  actions: {
    async arrangeData({ dispatch, getters }, { file }) {
      dispatch('updateDataUploadStatus', 'converting-file')

      // Convert excel file to rows
      let rows
      try {
        rows = await readXlsxFile(file)
      }
      catch (err) {
        dispatch('updateDataUploadStatus', 'error-converting')

        Sentry.captureException(err)
        return
      }

      dispatch('updateDataUploadStatus', 'generating-results')

      // Find relevant data + results
      let testResults
      try {
        testResults = await extractRelevantData(getters, rows)
      }
      catch (err) {
        if (err === 'wrong-case-number') {
          dispatch('updateDataUploadStatus', 'error-wrong-case-number')
          return
        }
        dispatch('updateDataUploadStatus', 'error-extract-data')
        Sentry.captureException(err)
        return
      }

      dispatch('updateDataUploadStatus', 'uploading-results')

      // Merge new and old test results
      testResults = mergeTestResults(testResults, getters)

      // Check if all done
      const allSamplesHasTestResults = getAllSamplesStatus(testResults, getters)

      // Save data
      try {
        db.collection(DB_SCREENINGS)
          .doc(getters.currentScreening.id)
          .collection(DB_TEST_RESULTS)
          .doc(getters.currentScreening.id)
          .set({
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
            testResults,
          }, { merge: true })
          .then(() => {
            dispatch('updateDataUploadStatus', 'upload-complete')

            if (allSamplesHasTestResults) {
              dispatch('updateScreening', { setTestDataAsUploaded: true })
            }
          })
          .catch((err) => {
            dispatch('updateDataUploadStatus', 'error-upload-error')
            Sentry.captureException(err)
          })
      }
      catch (err) {
        dispatch('updateDataUploadStatus', 'error-upload-error')
        Sentry.captureException(err)
      }
    },
    updateDataUploadStatus: ({ commit }, status) => {
      commit('updateDataUploadStatus', status)
    },
  },
  getters: {
    testDataUploadStatus: (state) => state.testDataUploadStatus,
  },
}

// Helpers
const extractRelevantData = (getters, rows) => new Promise((resolve, reject) => {
  const {
    caseName,
    caseNumber,
    personalSamplingID,
  } = getters

  // Get available tests
  const caseNameRow = rows[1]
  const caseNumberRow = rows[2]
  const testNameRow = rows[6]
  const allTestRows = rows.splice(9)

  // Check for correct case
  if (caseNameRow[1] === caseName) {
    // Do nothing
  }
  else if (caseNumberRow[1] === caseNumber) {
    // Do nothing
  }
  else if (caseNumberRow[1] === personalSamplingID) {
    // Do nothing
  }
  else {
    const answer = window.confirm(
      mixWB('CASE_NUMBER_DOES_NOT_MATCH_TEXT', [caseNumberRow[1], `${ caseNumber } // ${ personalSamplingID }`]),
    )
    if (!answer) {
      reject('wrong-case-number')
      return
    }
  }

  // Get index for each column to extract data from
  const testColumnNames = testDataToImport.map((x) => x.euroFinsName)
  const columnIndexes = testNameRow.reduce((prev, value, index) => {
    if (testColumnNames.includes(value)) {
      prev[value] = index
    }
    return prev
  }, {})

  const testResults = allTestRows.reduce((prev, row) => {
    const item = {
      sampleNumber: null,
      results: [],
      overallResult: null, // null = not used, 0 = clean, 1 = Contaminated, 2 = Hazardous waste
      metalsAboveThreshold: false,
      cpScreeningAutoProven: false,
    }

    // Get P-number (Will work with '1 - P1 - Text...' and 'P1 - Text...')
    const titleArray = row[1].split('-')
    const pNumber = titleArray.reduce((prev, text) => {
      if (prev) {
        return prev
      }

      if (text.includes('P')) {
        prev = text.replace('P', '')
      }

      return prev
    }, '')

    item.sampleNumber = Number(pNumber)

    // Get value for each test
    testDataToImport.forEach((test) => {
      const testColumnIndex = columnIndexes[test.euroFinsName]
      const resultItem = {
        id: test.id,
        value: null,
        isBelowValue: false,
        isAboveValue: false,
        isTooLowToMeasure: false,
        unit: test.unit || null,
        result: null,
        type: test.type,
      }

      // Get value
      const value = row[testColumnIndex]
      if (value && value !== '#') {
        if (value.toString().includes('<')) {
          resultItem.value = Number(value.replace('<', ''))
          resultItem.isBelowValue = true
        }
        else if (value.toString().includes('>')) {
          resultItem.value = Number(value.replace('>', ''))
          resultItem.isAboveValue = true
        }
        else {
          resultItem.value = value
        }
      }
      else if (value === '#') {
        resultItem.isTooLowToMeasure = true
      }

      // Check for a value
      if (resultItem.value === null && !resultItem.isTooLowToMeasure) {
        item.results.push(resultItem)
        return
      }

      // Get result
      // - Percent and value based
      if (['value-based'].includes(test.type)) {
        if (test.maxValue && resultItem.value > test.maxValue) {
          resultItem.result = 2
        }
        else if (test.minValue && resultItem.value >= test.minValue) {
          resultItem.result = 1
        }
        else {
          resultItem.result = 0
        }
      }

      // - Prove based
      if (test.type === 'prove-based') {
        if (value === 'Påvist') {
          resultItem.result = 2
        }
        else {
          resultItem.result = 0
        }
      }

      // CP screening result reset
      if (test.id === 'cpScreening') {
        resultItem.result = 0
      }

      // Set overall result
      if (resultItem.result !== null) {
        if (item.overallResult === null) {
          item.overallResult = resultItem.result
        }
        else if (resultItem.result > item.overallResult) {
          item.overallResult = resultItem.result
        }
      }

      item.results.push(resultItem)
    })

    // Special rule for 7 metals
    const accumulatedValue = item.results.reduce((prev, item) => {
      if (['Pb', 'Cu', 'Zn'].includes(item.id)) {
        if (item.value > 1000) {
          prev += item.value
        }
      }

      return prev
    }, 0)

    // Check for metals above threshold
    if (accumulatedValue > 2500) {
      item.metalsAboveThreshold = true
    }

    // Check CP results
    const cpScreeningItem = item.results.find((x) => x.id === 'cpScreening')
    const cpShortItem = item.results.find((x) => x.id === 'cpShort')
    const cpMediumItem = item.results.find((x) => x.id === 'cpMedium')

    if (cpScreeningItem.value === 'Ikke påvist') {
      // Do nothing
    }
    else if (cpScreeningItem.value === 'Påvist') {
      // CP auto proven
      if (!cpShortItem.value && !cpMediumItem.value) {
        item.cpScreeningAutoProven = true
      }
      // Has values
      else {
        if (cpShortItem.result === 2) {
          cpScreeningItem.result = 2
        }
        if (cpMediumItem.result === 2) {
          cpScreeningItem.result = 2
        }
      }
    }
    // Has CP test values but CP screening does not
    else if (cpShortItem.value || cpMediumItem.value) {
      cpScreeningItem.result = 0
      cpScreeningItem.value = 'Påvist'
      if (cpShortItem.result === 2) {
        cpScreeningItem.result = 2
      }
      if (cpMediumItem.result === 2) {
        cpScreeningItem.result = 2
      }
    }

    prev.push(item)

    return prev
  }, [])

  resolve(testResults)
})

const mergeTestResults = (testResults, getters) => {
  const { screeningTestResults } = getters

  if (!screeningTestResults) {
    return testResults
  }

  const checkedSampleNumbers = []
  const mergedTestResults = []

  // Loop through new test results
  testResults.forEach((testResult) => {
    checkedSampleNumbers.push(testResult.sampleNumber)

    // Check for CP values and add them to old result
    const cpScreening = testResult.results.find((x) => x.id === 'cpScreening')
    const cpShort = testResult.results.find((x) => x.id === 'cpShort')
    const cpMedium = testResult.results.find((x) => x.id === 'cpMedium')
    if (cpShort.value || cpMedium.value) {
      const oldResult = screeningTestResults.find(
        (x) => x.sampleNumber === testResult.sampleNumber,
      )
      if (!oldResult) {
        mergedTestResults.push(testResult)
        return
      }

      // Set individual results
      // - CP short
      const cpShortTest = testDataToImport.find((x) => x.id === 'cpShort')
      if (cpShortTest.maxValue && cpShort.value > cpShortTest.maxValue) {
        cpShort.result = 2
      }
      else if (cpShortTest.minValue && cpShort.value >= cpShortTest.minValue) {
        cpShort.result = 1
      }
      else {
        cpShort.result = 0
      }
      // - CP medium
      const cpMediumTest = testDataToImport.find((x) => x.id === 'cpMedium')
      if (cpMediumTest.maxValue && cpMedium.value > cpMediumTest.maxValue) {
        cpMedium.result = 2
      }
      else if (cpMediumTest.minValue && cpMedium.value >= cpMediumTest.minValue) {
        cpMedium.result = 1
      }
      else {
        cpMedium.result = 0
      }

      // Set overall result
      // - CP short
      if (oldResult.overallResult === null) {
        oldResult.overallResult = cpShort.result
      }
      else if (cpShort.result > oldResult.overallResult) {
        oldResult.overallResult = cpShort.result
      }
      // - CP medium
      if (oldResult.overallResult === null) {
        oldResult.overallResult = cpMedium.result
      }
      else if (cpMedium.result > oldResult.overallResult) {
        oldResult.overallResult = cpMedium.result
      }

      const cpScreeningIndex = oldResult.results.findIndex((x) => x.id === 'cpScreening')
      const cpShortIndex = oldResult.results.findIndex((x) => x.id === 'cpShort')
      const cpMediumIndex = oldResult.results.findIndex((x) => x.id === 'cpMedium')

      oldResult.results.splice(cpScreeningIndex, 1, cpScreening)
      oldResult.results.splice(cpShortIndex, 1, cpShort)
      oldResult.results.splice(cpMediumIndex, 1, cpMedium)
      oldResult.cpScreeningAutoProven = false
      mergedTestResults.push(oldResult)
    }
    else {
      mergedTestResults.push(testResult)
    }
  })

  // Add old tests for samples not yet checked
  screeningTestResults.forEach((testResult) => {
    if (checkedSampleNumbers.includes(testResult.sampleNumber)) {
      return
    }
    mergedTestResults.push(testResult)
  })

  return mergedTestResults
}

const getAllSamplesStatus = (testResults, getters) => {
  const { screeningSamples } = getters

  const samples = screeningSamples.filter((x) => !!x.sampleNumber)

  return testResults.length === samples.length
}
