import categories from '@/globals/database/Categories.json'
import categoryOtherTypeQuestions from '@/globals/database/CategoryOtherTypeQuestions.json'
import categoryTypeGroups from '@/globals/database/CategoryTypeGroups.json'
import categoryTypes from '@/globals/database/CategoryTypes.json'
import categoryTypeSubQuestions from '@/globals/database/CategoryTypeSubQuestions.json'
import materials from '@/globals/database/Materials.json'
import fractions from '@/globals/database/Fractions.json'
import sampleTypes from '@/globals/database/SampleTypes.json'
import actionTypes from '@/globals/database/ActionTypes.json'
import tags from '@/globals/database/Tags.json'
import coatingTypes from '@/globals/database/CoatingTypes.json'
import underliningTypes from '@/globals/database/UnderliningTypes.json'
import pcbScreeningQuestions from '@/globals/database/PCBScreeningQuestions.json'
import workDescriptionGroups from '@/globals/database/WorkDescriptionGroups.json'
import workDescriptionCases from '@/globals/database/WorkDescriptionCases.json'
import places from '@/globals/database/Places.json'
import floors from '@/globals/database/Floors.json'
import colors from '@/globals/database/Colors.json'
import tenderQuestions from '@/globals/database/TenderQuestions.json'
import { sortBy } from 'lodash-es'

export const materialData = {
  state: {
    categories,
    categoryOtherTypeQuestions,
    categoryTypeGroups,
    categoryTypes,
    categoryTypeSubQuestions,
    materials,
    fractions,
    sampleTypes,
    actionTypes,
    tags,
    coatingTypes,
    underliningTypes,
    pcbScreeningQuestions,
    workDescriptionGroups,
    workDescriptionCases,
    places,
    floors,
    colors,
    tenderQuestions,
  },
  mutations: {},
  actions: {},
  getters: {
    categories: (state) => sortBy(state.categories, ['placeOrder', 'position']),
    categoryOtherTypeQuestions: (state) => sortBy(state.categoryOtherTypeQuestions, ['position']),
    categoryTypeGroups: (state) => state.categoryTypeGroups,
    categoryTypes: (state) => state.categoryTypes,
    categoryTypeSubQuestions: (state) => state.categoryTypeSubQuestions,
    materials: (state) => state.materials,
    fractions: (state) => state.fractions,
    sampleTypes: (state) => state.sampleTypes,
    pcbScreeningQuestions: (state) => state.pcbScreeningQuestions,
    workDescriptionGroups: (state) => state.workDescriptionGroups,
    workDescriptionCases: (state) => state.workDescriptionCases,
    places: (state) => state.places,
    floors: (state) => state.floors,
    colors: (state) => state.colors,
    actionTypes: (state) => state.actionTypes,
    tags: (state) => state.tags,
    coatingTypes: (state) => state.coatingTypes,
    underliningTypes: (state) => state.underliningTypes,
    tenderQuestions: (state) => state.tenderQuestions,
    // Mutated
    categoriesToSections(getters) {
      return getters.categories.reduce((prev, category) => {
        if (category.place === 'outside') {
          prev.outside.push(category)
          return prev
        }
        if (category.place === 'inside') {
          prev.inside.push(category)
          return prev
        }
        prev.other.push(category)
        return prev
      }, { outside: [], inside: [], other: [] })
    },
    categoryTypeGroupsAsArray: (state) => Object.keys(state.categoryTypeGroups).reduce(
      (prev, key) => {
        const item = state.categoryTypeGroups[key]
        prev.push({
          ...item,
          id: key,
        })
        return prev
      }, [],
    ),
    sampleTypesAsArray: (state) => sortBy(Object.keys(state.sampleTypes).reduce(
      (prev, key) => {
        const item = state.sampleTypes[key]
        prev.push({
          ...item,
          id: key,
        })
        return prev
      }, [],
    ), ['position']),
    workDescriptionGroupsAsArray: (state) => Object.keys(state.workDescriptionGroups).reduce(
      (prev, key) => {
        const item = state.workDescriptionGroups[key]
        prev.push({
          ...item,
          id: key,
        })
        return prev
      }, [],
    ),
    workDescriptionCasesAsArray: (state) => Object.keys(state.workDescriptionCases).reduce(
      (prev, key) => {
        const item = state.workDescriptionCases[key]
        prev.push({
          ...item,
          id: key,
        })
        return prev
      }, [],
    ),
    placesAsArray: (state) => Object.keys(state.places).reduce(
      (prev, key) => {
        const item = state.places[key]
        prev.push({
          ...item,
          id: key,
        })
        return prev
      }, [],
    ),
    floorsAsArray: (state) => sortBy(Object.keys(state.floors).reduce(
      (prev, key) => {
        const item = state.floors[key]
        prev.push({
          ...item,
          id: key,
        })
        return prev
      }, [],
    ), ['position']),
    colorsAsArray: (state) => sortBy(Object.keys(state.colors).reduce(
      (prev, key) => {
        const item = state.colors[key]
        prev.push({
          ...item,
          id: key,
        })
        return prev
      }, [],
    ), ['position']),
    actionTypesAsArray: (state) => Object.keys(state.actionTypes).reduce(
      (prev, key) => {
        const item = state.actionTypes[key]
        prev.push({
          ...item,
          id: key,
        })
        return prev
      }, [],
    ),
    tagsAsArray: (state) => Object.keys(state.tags).reduce(
      (prev, key) => {
        const item = state.tags[key]
        prev.push({
          ...item,
          id: key,
        })
        return prev
      }, [],
    ),
    coatingTypesAsArray: (state) => Object.keys(state.coatingTypes).reduce(
      (prev, key) => {
        const item = state.coatingTypes[key]
        prev.push({
          ...item,
          id: key,
        })
        return prev
      }, [],
    ),
    underliningTypesAsArray: (state) => sortBy(Object.keys(state.underliningTypes).reduce(
      (prev, key) => {
        const item = state.underliningTypes[key]
        prev.push({
          ...item,
          id: key,
        })
        return prev
      }, [],
    ), ['position']),
    tenderQuestionsAsArray: (state) => sortBy(Object.keys(state.tenderQuestions).reduce(
      (prev, key) => {
        const item = state.tenderQuestions[key]
        prev.push({
          ...item,
          id: key,
        })
        return prev
      }, [],
    ), ['position']),
  },
}
