<template>
  <div
    v-if="show"
    :class="rootClasses"
    class="LoadingPage">
    <div class="Center">
      <Circular size="medium" />
    </div>
  </div>
</template>

<script>
import Circular from '@/components/Progress/Circular.vue'

export default {
  name: 'LoadingPage',
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    fullPage: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    rootClasses() {
      return {
        NotFullPage: !this.fullPage,
        FullPage: this.fullPage,
      }
    },
  },
  components: { Circular },
}
</script>

<style lang="stylus" scoped>
  .LoadingPage
    top 0
    left 0
    flex-center-children()
    box(100%)
    background-color rgba($color_grey_lightestest, 0.95)
    z-index $loading_page
    &.NotFullPage
      position absolute
    &.FullPage
      position fixed

  .Center
    transform translateY(-50px)
    box(100px)

</style>
