/* eslint-disable no-use-before-define */
import store from '@/store'

export function getPreviousWDCID({ groupedType, previousGroupedTypeResults }) {
  if (groupedType.groupedTypeResult.wdgID === 'WDG-2') {
    return false
  }
  if (!previousGroupedTypeResults) {
    return false
  }
  if (!['both', 'right'].includes(groupedType.leftSideEffectDirection)) {
    return false
  }

  return previousGroupedTypeResults.getRightSideContamindationWDCID()
}

export function getNextWDCID({ groupedType, nextGroupedTypeResults }) {
  if (groupedType.groupedTypeResult.wdgID === 'WDG-2') {
    return false
  }
  if (!nextGroupedTypeResults) {
    return false
  }

  if (!['both', 'left'].includes(groupedType.rightSideEffectDirection)) {
    return false
  }

  return nextGroupedTypeResults.getLeftSideContamindationWDCID()
}

export function getFinalMaterialWDCID() {
  return getCombinedWDCID({
    firstWDCID: this.material.wdcID,
    secondWDCID: this.underlining.wdcID,
  })
}

export function getCorrectMaterialWDCID({ previousWDCID, nextWDCID }) {
  // Combine material and underlining
  let combinedWDCID = getFinalMaterialWDCID.call(this)

  if (!previousWDCID && !nextWDCID) {
    return combinedWDCID
  }

  // Combine with left side
  // - Don't combine if tiles with adhesives
  if (!this.isFirstTypeTilesWithAdhesives) {
    combinedWDCID = getCombinedWDCID({
      firstWDCID: combinedWDCID,
      secondWDCID: previousWDCID,
    })
  }

  // Combine with right side
  // - Don't combine if there is coating
  if (!this.coating.wdcID) {
    combinedWDCID = getCombinedWDCID({
      firstWDCID: combinedWDCID,
      secondWDCID: nextWDCID,
    })
  }

  return combinedWDCID
}

export function getGroupedTypeFinalWDCID({ includeCoating = false }) {
  let finalWDCID = getFinalMaterialWDCID.call(this)

  if (includeCoating) {
    finalWDCID = getCombinedWDCID({
      firstWDCID: finalWDCID,
      secondWDCID: this.coating.wdcID,
    })
  }

  return finalWDCID
}

export function getCorrectCoatingWDCID({ nextWDCID }) {
  if (!nextWDCID) {
    return this.coating.wdcID || 'WDC-1'
  }

  const combinedWDCID = getCombinedWDCID({
    firstWDCID: this.coating.wdcID,
    secondWDCID: nextWDCID,
  })

  return combinedWDCID || 'WDC-1'
}

export function getNeedToBeCleanedStatus({ materialWDCID, coatingWDCID }) {
  if (!this.coating.wdcID) {
    return false
  }

  if (this.wdgID !== 'WDG-2') {
    return false
  }

  const materialContaminationScaleValue = getContaminationScaleValue(
    materialWDCID,
  )
  const coatingContaminationScaleValue = getContaminationScaleValue(
    coatingWDCID,
  )

  if (materialContaminationScaleValue >= coatingContaminationScaleValue) {
    return false
  }

  return true
}

export function getUnderlyingMustBeCleanedStatus(groupedType) {
  if (
    !this.isFirstTypeTilesWithAdhesives
    && !!this.getLeftSideContamindationWDCID()
    && this.getLeftSideContamindationWDCID() !== 'WDC-1'
    && ['left', 'both'].includes(groupedType.leftSideEffectDirection)
    && groupedType.previousGroupedTypes.find((x) => x.groupedTypeResult.wdgID === 'WDG-2')
  ) {
    return true
  }
  return false
}

export function getOverlyingMustBeCleanedStatus(groupedType) {
  if (
    !!this.getRightSideContamindationWDCID()
    && this.getRightSideContamindationWDCID() !== 'WDC-1'
    && ['right', 'both'].includes(groupedType.rightSideEffectDirection)
    && groupedType.nextGroupedTypes.find((x) => x.groupedTypeResult.wdgID === 'WDG-2')
  ) {
    return true
  }
  return false
}

export function getContaminationScaleValue(wdcID) {
  const { workDescriptionCases } = store.getters
  return workDescriptionCases[wdcID].contaminationScaleValue
}

export function getCombinedWDCID({ firstWDCID, secondWDCID }) {
  const {
    workDescriptionCases,
    workDescriptionCasesAsArray,
  } = store.getters

  if (!firstWDCID && !secondWDCID) {
    return 'WDC-1'
  }
  if (!firstWDCID) {
    return secondWDCID
  }
  if (!secondWDCID) {
    return firstWDCID
  }

  const firstContaminationScaleValue = workDescriptionCases[firstWDCID].contaminationScaleValue
  const secondContaminationScaleValue = workDescriptionCases[secondWDCID].contaminationScaleValue

  const combinedContaminationScaleValue = getCombinedContaminationScaleValue([
    firstContaminationScaleValue,
    secondContaminationScaleValue,
  ])

  return workDescriptionCasesAsArray.find(
    (x) => x.contaminationScaleValue === combinedContaminationScaleValue,
  ).id
}

export function getCombinedContaminationScaleValue(valuesArray) {
  return valuesArray.reduce(
    (prev, number) => {
      if (prev === 5) {
        return prev
      }
      if (number === 5) {
        prev = 5
        return prev
      }
      if (number === 4) {
        if (prev === 3) {
          prev = 5
          return prev
        }
        prev = 4
        return prev
      }
      if (number === 3) {
        if (prev === 4) {
          prev = 5
          return prev
        }
        prev = 3
        return prev
      }
      if (number > prev) {
        prev = number
      }
      return prev
    }, 1,
  )
}
