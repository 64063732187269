/* eslint-disable no-use-before-define */
import {
  mixWB,
  mixFormatNumber,
  mixGetListAsText,
} from '@/globals/javascript/_util/mixins'
import { checkToAddPCBToType } from '@/globals/javascript/_util/samples'
import { firebase } from '@/firebase/init'
import store from '@/store/index'
import { sortBy, union } from 'lodash-es'
import Unit from './units/Unit'
import { DB_TYPES, setIDOnCreate } from './_helper'

export default class Type {
  constructor({
    id = null,
    categoryID = null,
    unitIDs = [],
    type = {},
    questionAnswers = {},
    subQuestionAnswers = {},
    coating = {},
    underlining = {},
    amount = {},
    resale = '',
    places = {},
    description = '',
    comment = '',
    samples = {},
    tags = {},
    assessments = {},
    images = [],
    autoTypeBaseID,
    isOwnTypeAsWell,

    // Timings
    createdAt = null,
    lastUpdated = null,

    // Deprecated
    buildings = [],
    colors = [],
    actions = {},
  }) {
    this.id = setIDOnCreate(id, DB_TYPES)
    this.categoryID = categoryID
    this.unitIDs = unitIDs
    this.type = {
      typeID: type.typeID || null,
      isOtherType: type.isOtherType || false,
      otherType: type.otherType || '',
      otherTypeWDGID: type.otherTypeWDGID || null,
    }
    this.questionAnswers = {
      ...questionAnswers,
    }
    this.subQuestionAnswers = {
      ...subQuestionAnswers,
    }
    this.coating = {
      version: coating.version || null,
      mainOptionID: coating.mainOptionID || null,
      colorIDs: coating.colorIDs || [],
      answers: coating.answers || {},
      coatingTypeIDs: coating.coatingTypeIDs || [],
      samples: {
        sampleIDs: coating && coating.samples ? coating.samples.sampleIDs : [],
        items: coating && coating.samples ? coating.samples.items : [],
      },
      tags: coating.tags || {},
    }
    this.underlining = {
      version: underlining.version || null,
      mainOptionID: underlining.mainOptionID || null,
      colorIDs: underlining.colorIDs || [],
      answers: underlining.answers || {},
      underliningTypeIDs: underlining.underliningTypeIDs || [],
      samples: {
        sampleIDs: underlining && underlining.samples ? underlining.samples.sampleIDs : [],
        items: underlining && underlining.samples ? underlining.samples.items : [],
      },
      tags: underlining.tags || {},
    }
    this.amount = {
      isSkipped: amount.isSkipped || false,
      type: amount.type || null,
      number: amount.number || null,
    }
    this.resale = resale
    this.places = {
      placeIDs: places.placeIDs || [],
      otherPlaces: places.otherPlaces || [],
    }
    this.description = description
    this.comment = comment
    this.samples = {
      items: samples.items || [],
      sampleIDs: samples.sampleIDs || [],
    }
    this.tags = tags
    this.assessments = {
      items: assessments.items || [],
      assessmentIDs: assessments.assessmentIDs || [],
    }
    this.images = images
    this.autoTypeBaseID = autoTypeBaseID || null
    this.isOwnTypeAsWell = isOwnTypeAsWell ?? null

    this.createdAt = createdAt || firebase.firestore.FieldValue.serverTimestamp()
    this.lastUpdated = lastUpdated || firebase.firestore.FieldValue.serverTimestamp()

    // Deprecated
    this.buildings = buildings
    this.colors = colors
    this.actions = {
      items: actions.items || [],
      actionIDs: actions.actionIDs || [],
    }
  }

  // Methods
  getTitle(options = {}) {
    const {
      categories,
    } = store.getters

    const category = options.category || false
    const details = options.details || false

    let title = ''

    // Add category
    if (category) {
      const category = categories.find(
        (x) => x.id === this.categoryID,
      )

      title += `${ mixWB(category.wordbook) } - `
    }

    // Add type title
    if (this.type.isOtherType) {
      title += this.type.otherType
    }
    if (this.type.typeID) {
      const screeningType = store.getters.categoryTypes.find(
        (x) => x.id === this.type.typeID,
      )
      title += mixWB(screeningType.wordbook)
    }

    // Add details
    if (details) {
      // - Adhesive and joints
      let hasMultipleDetails = false
      if (this.hasTags({ tags: ['TAG-2'] })) {
        title += ` ${ mixWB('WITH_JOINTS_AND_ADHESIVES') }`
        hasMultipleDetails = true
      }
      if (this.getCoatingText()) {
        title += ` ${
          hasMultipleDetails ? `${ mixWB('AND') } ` : ''
        } ${
          mixWB('WITH_SHORT')
        } ${
          this.getCoatingText().toLowerCase()
        }`
      }
    }

    return title
  }

  getCategoryTitle() {
    const category = store.getters.categories.find(
      (x) => x.id === this.categoryID,
    )

    return mixWB(category.wordbook)
  }

  getUnits(options = {}) {
    // Join places' unit ids to this list.
    const {
      unitsList,
      returnIDsOnly = false,
    } = options
    const ids = this.unitIDs

    if (returnIDsOnly) {
      return ids
    }

    const selectedUnits = unitsList ?? store.getters.selectedUnits

    return selectedUnits.filter(
      (unit) => ids.includes(unit.id),
    )
  }

  getUnitsList(options = {}) {
    return this.getUnits(options).map(
      (unit) => unit.getFullTitle(),
    ).join(', ')
  }

  // Unit IDs to be used when matching for inter connections
  getAllowedUnitIDsAsObject(options = {}) {
    const {
      noParent = false,
    } = options
    const { selectedUnits } = store.getters
    const data = {
      buildingIDs: [],
      floorIDs: [],
      apartmentIDs: [],
    }

    const allUnitIDs = this.getUnits({ returnIDsOnly: true })
    allUnitIDs.forEach((unitID) => {
      const unit = selectedUnits.find((x) => x.id === unitID)
      if (unit.type === 'building') {
        data.buildingIDs = union(data.buildingIDs, [unitID])
      }
      if (unit.type === 'floor') {
        data.floorIDs = union(data.floorIDs, [unitID])
      }
      if (unit.type === 'apartment') {
        data.apartmentIDs = union(data.apartmentIDs, [unitID])
      }

      if (!noParent && unit.data.floorID) {
        data.floorIDs = union(data.floorIDs, [unit.data.floorID])
      }
      if (!noParent && unit.data.buildingID) {
        data.buildingIDs = union(data.buildingIDs, [unit.data.buildingID])
      }
    })

    return data
  }

  getPlacesList() {
    if (!this.places) {
      return false
    }

    let allPlaces = this.places.placeIDs.map(
      (id) => mixWB(store.getters.places[id].wordbook),
    )
    allPlaces = allPlaces.concat(this.places.otherPlaces).sort()
    allPlaces = allPlaces.reduce((prev, place, index) => {
      if (index === 0) {
        prev.push(place)
        return prev
      }
      prev.push(place.toLowerCase())
      return prev
    }, [])

    if (allPlaces.length > 1) {
      const lastItem = allPlaces.pop()
      const secondLast = allPlaces.pop()
      allPlaces.push(`${ secondLast } ${ mixWB('AND') } ${ lastItem }`)
    }

    return allPlaces.join(', ')
  }

  getCoatingText() {
    let coatingText = ''
    let colorsText = ''
    let finalText = ''

    // Deprecated: Check if type has wallpaper
    const wallpaperText = this.actions.actionIDs.includes('AC-6') ? mixWB('WALLPAPER') : ''

    // New coating logic
    if (this.coating.coatingTypeIDs.length) {
      coatingText = mixGetListAsText({
        idList: this.coating.coatingTypeIDs,
        objectWithAllIDs: store.getters.coatingTypes,
        includeAnd: true,
      })

      colorsText = mixGetListAsText({
        idList: this.coating.colorIDs,
        objectWithAllIDs: store.getters.colors,
      })
    }

    // Deprecated coating logic
    else {
      const coatingIDs = ['AC-2', 'AC-3', 'AC-4', 'AC-5']
      const selectedCoatingIDs = this.actions.actionIDs.filter(
        (x) => coatingIDs.includes(x),
      )

      coatingText = mixGetListAsText({
        idList: selectedCoatingIDs,
        objectWithAllIDs: store.getters.actionTypes,
        includeAnd: true,
      })

      colorsText = mixGetListAsText({
        idList: this.colors,
        objectWithAllIDs: store.getters.colors,
      })
    }

    if (wallpaperText) {
      finalText = wallpaperText
    }
    if (coatingText) {
      if (finalText) {
        finalText += ` + ${ coatingText }`
      }
      else {
        finalText = coatingText
      }
    }
    if (colorsText) {
      if (finalText) {
        finalText += ` (${ colorsText })`
      }
      else {
        finalText = colorsText
      }
    }

    return finalText
  }

  getAmountText() {
    if (this.amount.isSkipped) {
      return false
    }

    return `${
      mixFormatNumber(this.amount.number)
    } ${
      mixWB(this.amount.type.toUpperCase()).toLowerCase()
    }`
  }

  getMaterialSampleList() {
    const sampleIDs = JSON.parse(JSON.stringify(this.samples.sampleIDs))
    const addPCBAnalysis = checkToAddPCBToType(this, store.getters.screeningPCBScreenings)

    if (sampleIDs.length && addPCBAnalysis && !sampleIDs.includes('ST-4')) {
      sampleIDs.push('ST-4')
    }

    return mixGetListAsText({
      idList: sampleIDs,
      objectWithAllIDs: store.getters.sampleTypes,
    })
  }

  getCoatingSampleList() {
    const sampleIDs = JSON.parse(JSON.stringify(this.coating.samples.sampleIDs))
    const addPCBAnalysis = checkToAddPCBToType(this, store.getters.screeningPCBScreenings, 'coating')

    if (sampleIDs.length && addPCBAnalysis && !sampleIDs.includes('ST-4')) {
      sampleIDs.push('ST-4')
    }

    return mixGetListAsText({
      idList: sampleIDs,
      objectWithAllIDs: store.getters.sampleTypes,
    })
  }

  getUnderliningSampleList() {
    const sampleIDs = JSON.parse(JSON.stringify(this.underlining.samples.sampleIDs))
    const addPCBAnalysis = checkToAddPCBToType(this, store.getters.screeningPCBScreenings)

    if (sampleIDs.length && addPCBAnalysis && !sampleIDs.includes('ST-4')) {
      sampleIDs.push('ST-4')
    }

    return mixGetListAsText({
      idList: sampleIDs,
      objectWithAllIDs: store.getters.sampleTypes,
    })
  }

  getAllSampleAndAssessmentIDs() {
    const addPCBAnalysis = checkToAddPCBToType(this, store.getters.screeningPCBScreenings)

    let sampleIDs = union(
      this.samples.sampleIDs,
      this.coating.samples.sampleIDs,
      this.assessments.assessmentIDs,
    )

    if (addPCBAnalysis && sampleIDs.length) {
      sampleIDs = union(
        sampleIDs,
        ['ST-4'],
      )
    }
    return sampleIDs
  }

  getAllFloorPlans() {
    const floorPlans = Object.values(this.unitIDs.reduce((prev, unitID) => {
      const currentUnit = Unit.getUnitById(unitID)
      const parents = currentUnit.getParents()
      const children = currentUnit.getChildren()

      children.concat([currentUnit], parents).forEach((unit) => {
        if (!prev[unit.id] && unit.hasFloorPlan()) {
          prev[unit.id] = {
            id: unit.id,
            unit,
            name: unit.getFullTitle(),
            floorPlan: unit.getFloorPlan()[0],
          }
        }
      })

      return prev
    }, {}))

    return sortBy(floorPlans, ['unit.typeSorting', 'unit.data.numericFloorValue', 'name'])
  }

  getAssessmentList() {
    let names = ''

    this.assessments.assessmentIDs.forEach((assessmentIDs, index) => {
      const sample = store.getters.sampleTypesAsArray.find((x) => x.id === assessmentIDs)

      if (index === 0) {
        names = mixWB(sample.wordbook)
        return
      }

      names += `, ${ mixWB(sample.wordbook) }`
    })

    return names
  }

  getCombinedSampleAndAssessmentList() {
    const combinedList = union(this.samples.sampleIDs, this.assessments.assessmentIDs)
    let names = ''

    combinedList.forEach((assessmentIDs, index) => {
      const sample = store.getters.sampleTypesAsArray.find((x) => x.id === assessmentIDs)

      if (index === 0) {
        names = mixWB(sample.wordbook)
        return
      }

      names += `, ${ mixWB(sample.wordbook) }`
    })

    return names
  }

  getWDGID() {
    if (this.type.isOtherType) {
      return this.type.otherTypeWDGID || ''
    }

    const categoryType = store.getters.categoryTypes.find(
      (x) => x.id === this.type.typeID,
    )

    return categoryType && categoryType.wdgID ? categoryType.wdgID : ''
  }

  hasTags({ tags = [] }) {
    return tags.every((tag) => {
      // Check for TAG-2 in deprecated actionIDs
      if (tag === 'TAG-2') {
        if (this.actions.actionIDs.includes('AC-7')) {
          return true
        }
      }

      // Check for 'yes' or 'dont know' for each requested tag
      return [1, 2].includes(this.tags[tag])
    })
  }

  // Resets
  resetCoating() {
    this.coating = {
      version: null,
      mainOptionID: null,
      colorIDs: [],
      answers: {},
      coatingTypeIDs: [],
      samples: {
        sampleIDs: [],
        items: [],
      },
    }
  }

  resetUnderlining() {
    this.underlining = {
      version: null,
      mainOptionID: null,
      answers: {},
      underliningTypeIDs: [],
      samples: {
        sampleIDs: [],
        items: [],
      },
    }
  }
}
