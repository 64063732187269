<template>
  <div class="NavItem">
    <div class="LeftSide">
      <div
        v-if="icon"
        :class="getIconClass()"
        class="IconWrap">
        <component
          v-if="iconToUse"
          :is="iconToUse" />
      </div>
      <span>{{ title }}</span>
    </div>
    <div
      v-if="showArrow"
      class="ArrowWrap">
      <AngleRightIcon />
    </div>
  </div>
</template>

<script>
import AngleRightIcon from '@/assets/svg/angle-right.svg?inline'
import GridIcon from '@/assets/svg/grid.svg?inline'
import PlusIcon from '@/assets/svg/plus.svg?inline'
import PhotosIcon from '@/assets/svg/camera.svg?inline'

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: [String, Boolean],
      required: false,
      default: false,
    },
    showArrow: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    iconToUse() {
      if (this.icon === 'list') return GridIcon
      if (this.icon === 'new-screenings') return PlusIcon
      if (this.icon === 'photos') return PhotosIcon
      return null
    },
  },
  methods: {
    getIconClass() {
      if (this.icon === 'new-screenings') {
        return 'GreenIcon'
      }
      return ''
    },
  },
  components: {
    AngleRightIcon,
  },
}
</script>

<style lang="stylus" scoped>
  .NavItem
    display flex
    align-items center
    justify-content space-between
    min-height 60px
    padding-left 20px
    border-bottom 1px solid $color_grey_lighter

  .LeftSide
    display flex
    align-items center

  .IconWrap
    box(20px)
    margin-right 10px
    &.GreenIcon
      svg
        fill $color_primary
        box(20px)

  .ArrowWrap
    box(14px)
    margin-right 18px
</style>
