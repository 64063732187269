<template>
  <div class="GlobalMenu">
    <!-- Backdrop -->
    <div
      v-if="isGlobalMenuShowing"
      class="BackDrop"
      @click="onBackDropClick" />

    <!-- Menu -->
    <div
      v-if="isGlobalMenuShowing"
      class="MenuWrap"
      :class="menuWrapClasses"
      :style="menuWrapStyling">

      <span
        v-if="globalMenuProps.title"
        class="Title">{{ globalMenuProps.title }}</span>

      <component
        :is="globalMenuComponent"
        v-bind="globalMenuDataToComponent ? {...globalMenuDataToComponent} : {}"
        @close-menu="onCloseMenu"
        @pass-to-parent="onPassToParent" />
    </div>
  </div>
</template>

<script>
import EventBus from '@/EventBus'
import { mapGetters } from 'vuex'
import { getElementDocumentPos } from '@/globals/javascript/_util/util'
import { getClosestByClass } from '@/globals/javascript/_util/find-elements'

export default {
  name: 'GlobalMenu',
  data() {
    return {
      menuTopPos: 0,
      menuRightPos: 0,
      menuLeftPos: 0,
    }
  },
  computed: {
    ...mapGetters([
      'isGlobalMenuShowing',
      'globalMenuComponent',
      'globalMenuProps',
      'globalMenuDataToComponent',
    ]),
    menuWrapClasses() {
      return {
        SizeNormal: this.globalMenuProps.size === 'normal',
        SizeSmall: this.globalMenuProps.size === 'small',
      }
    },
    menuWrapStyling() {
      if (this.menuRightPos) {
        return {
          top: this.menuTopPos, right: this.menuRightPos,
        }
      }

      return {
        top: this.menuTopPos, left: this.menuLeftPos,
      }
    },
  },
  watch: {
    isGlobalMenuShowing(to) {
      if (to) {
        requestAnimationFrame(() => {
          this.placeMenuCorrect()
        })
      }
    },
  },
  methods: {
    onBackDropClick() {
      this.onCloseMenu()
    },
    onCloseMenu() {
      this.$store.dispatch('closeGlobalMenu')
    },
    onPassToParent(event) {
      EventBus.$emit('global-menu-pass-to-parent', {
        event,
        menuID: this.globalMenuProps.menuID,
      })
    },
    placeMenuCorrect() {
      const { menuElement } = this.globalMenuProps
      const menuElementPos = getElementDocumentPos(menuElement)

      // Check for parent scroll
      const parentScrollElement = getClosestByClass('js-scroll-container', menuElement)
      const parentScrollLeft = parentScrollElement ? parentScrollElement.scrollLeft : 0

      this.menuTopPos = `${
        menuElementPos.top
          + ((menuElementPos.bottom - menuElementPos.top) / 2)
      }px`

      const rightPos = window.innerWidth - menuElementPos.right
        + ((menuElementPos.right - menuElementPos.left) / 2)
        + parentScrollLeft

      // Check to open menu the other way
      if (rightPos < window.innerWidth / 2) {
        this.menuRightPos = `${ rightPos }px`
        return
      }

      this.menuRightPos = 0
      this.menuLeftPos = `${
        menuElementPos.left
          + ((menuElementPos.right - menuElementPos.left) / 2)
          - parentScrollLeft
      }px`
    },
  },
}
</script>

<style lang="stylus" scoped>
  .GlobalMenu
    display block

  .BackDrop
    position fixed
    top 0
    left 0
    z-index 1
    width 100vw
    height 100vh
    background-color rgba(255, 255, 255, 0.5)

  .MenuWrap
    position absolute
    background-color #fff
    border 1px solid $color_grey_light
    z-index $dot_menu
    box-shadow $box_shadow_1
    &.SizeNormal
      min-width 200px
    &.SizeSmall
      min-width 100px
    .Title
      display block
      background-color $color_grey_lighter
      border-bottom 1px solid $color_grey_light
      text-transform uppercase
      font-size 0.75rem
      padding 5px
      width 100%
      truncated()
      text-align center
</style>
