import { db } from '@/firebase/init'
import Screening from '@/globals/javascript/models/Screening'
import EventBus from '@/EventBus'
import { DB_SCREENINGS, DB_UNITS } from '@/globals/javascript/models/_helper'

export const units = {
  getScreeningUnits: ({ commit, getters }, screeningID) => {
    if (getters.currentScreeningUnitsLoaded) {
      return
    }

    const unsubscribe = db
      .collection(DB_SCREENINGS)
      .doc(screeningID)
      .collection(DB_UNITS)
      .onSnapshot((querySnapshot) => {
        const units = []
        querySnapshot.forEach((doc) => {
          units.push(doc.data())
        })
        commit('updateCurrentScreeningUnits', { units, unsubscribe })
      })
  },
  saveScreeningUnits: ({ getters, dispatch }, { combinedList, updateSelectedUnits = true }) => {
    // Save units for screening
    combinedList.forEach((item) => {
      db
        .collection(DB_SCREENINGS)
        .doc(getters.currentScreening.id)
        .collection(DB_UNITS)
        .doc(item.id)
        .set({
          ...item,
        })
    })

    // Update screening
    const screening = new Screening({
      ...getters.currentScreeningData,
    })

    screening.hasSavedBBRUnits = true

    if (updateSelectedUnits) {
      const {
        selectedIDs,
        selectedUnits,
      } = combinedList.reduce((prev, unit) => {
        if (!unit.isSelected) {
          return prev
        }

        prev.selectedIDs.push(unit.id)

        prev.selectedUnits.push({ ...unit })

        return prev
      }, {
        selectedIDs: [],
        selectedUnits: [],
      })
      const outsideCategoryIDs = getters.categories.reduce((prev, category) => {
        if (category.place === 'outside') {
          prev.push(category.id)
        }
        return prev
      }, [])
      combinedList.forEach((unit) => {
        const parentIDs = [unit.data.buildingID, unit.data.floorID]

        if (unit.isSelected && selectedIDs.find((id) => parentIDs.includes(id))) {
          // Has parent which are selected, default all outside categories to be skipped.
          screening.skippedCategories[unit.id] = [
            ...new Set([
              ...screening.skippedCategories[unit.id] ?? [],
              ...outsideCategoryIDs,
            ]),
          ]
        }
      })

      screening.selectedUnits.count = selectedIDs.length
      screening.selectedUnits.ids = selectedIDs
      screening.selectedUnits.units = selectedUnits
    }

    dispatch('updateScreening', { screening, setAsNotCompleted: true })
    EventBus.$emit('all-units-saved')
  },
  removeScreeningUnits: ({ getters, dispatch }, {
    removedUnits = [],
    updateScreening = true,
  }) => {
    if (!removedUnits.length) {
      return
    }

    // Remove units
    removedUnits.forEach(
      (unit) => {
        db.collection(DB_SCREENINGS)
          .doc(getters.currentScreening.id)
          .collection(DB_UNITS)
          .doc(unit.id)
          .delete()
      },
    )

    if (updateScreening) {
      dispatch('updateScreening', { setAsNotCompleted: true })
    }
    EventBus.$emit('all-units-saved')
  },
}
