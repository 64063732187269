import * as setup from '@/globals/javascript/models/GroupedTypeResult/setup'
import * as finalResults from '@/globals/javascript/models/GroupedTypeResult/finalResults'

export default class GroupedTypeResult {
  constructor({
    groupedTypeID,
    wdgID,
    leftSideWDCID,
    rightSideWDCID,
    material = {},
    coating = {},
    underlining = {},
    assessCases,
    specialCase,
    specialTags,

    // Booleans
    isMissingUploadedData,
    isResale,
    isAllMetalOrIron,
    isFirstTypeLeveling,
    isFirstTypeTilesWithAdhesives,
    isLastTypeWallpaperWithCoating,
    hasSamples,
    allTypesHasMaterialSample,
    isMultiType,
    isMissingSample,
    isMissingTestResults,
  }) {
    this.groupedTypeID = groupedTypeID || null
    this.wdgID = wdgID || null
    this.leftSideWDCID = leftSideWDCID || null
    this.rightSideWDCID = rightSideWDCID || null
    this.material = {
      wdcID: material.wdcID || null,
      contaminatedGroupIDs: material.contaminatedGroupIDs || [],
      hazardiousGroupIDs: material.hazardiousGroupIDs || [],
    }
    this.coating = {
      wdcID: coating.wdcID || null,
      contaminatedGroupIDs: coating.contaminatedGroupIDs || [],
      hazardiousGroupIDs: coating.hazardiousGroupIDs || [],
      isAssessment: coating.isAssessment || false,
    }
    this.underlining = {
      wdcID: underlining.wdcID || null,
      contaminatedGroupIDs: underlining.contaminatedGroupIDs || [],
      hazardiousGroupIDs: underlining.hazardiousGroupIDs || [],
      isAssessment: underlining.isAssessment || false,
      underliningTypeIDs: underlining.underliningTypeIDs || [],
    }
    this.assessCases = assessCases || []
    this.specialCase = specialCase || null
    this.specialTags = specialTags || []

    //  Booleans
    this.isMissingUploadedData = isMissingUploadedData || false
    this.isResale = isResale || false
    this.isAllMetalOrIron = isAllMetalOrIron || false
    this.isFirstTypeLeveling = isFirstTypeLeveling || false
    this.isFirstTypeTilesWithAdhesives = isFirstTypeTilesWithAdhesives || false
    this.isLastTypeWallpaperWithCoating = isLastTypeWallpaperWithCoating || false
    this.hasSamples = hasSamples || false
    this.allTypesHasMaterialSample = allTypesHasMaterialSample || false
    this.isMultiType = isMultiType || false
    this.isMissingSample = isMissingSample || false
    this.isMissingTestResults = isMissingTestResults || false
  }

  getAllResults({
    groupedType,
    previousGroupedTypes,
    nextGroupedTypes,
    groupedTypeTitle,
    lastTypeTitleWithCoating,
  }) {
    // Get WDC ID's from adjacent types
    let previousWDCID = false
    if (previousGroupedTypes.length === 1) {
      previousWDCID = setup.getPreviousWDCID({
        groupedType,
        previousGroupedTypeResults: previousGroupedTypes[0].groupedTypeResult,
      })
    }
    let nextWDCID = false
    if (nextGroupedTypes.length === 1) {
      nextWDCID = setup.getNextWDCID({
        groupedType,
        nextGroupedTypeResults: nextGroupedTypes[0].groupedTypeResult,
      })
    }

    // Update current WDC IDs
    const materialWDCID = setup.getCorrectMaterialWDCID.call(this, {
      previousWDCID,
      nextWDCID,
    })
    const coatingWDCID = setup.getCorrectCoatingWDCID.call(this, {
      nextWDCID,
    })

    // Get final results
    const results = finalResults.getResults.call(
      this, { materialWDCID, coatingWDCID },
    )
    const explanationTexts = finalResults.getExplanationTexts.call(
      this, {
        materialWDCID,
        previousWDCID,
        nextWDCID,
        coatingWDCID,
        groupedTypeTitle,
        lastTypeTitleWithCoating,
        previousGroupedTypes,
        nextGroupedTypes,
      },
    )
    const workDescriptionTexts = finalResults.getWorkDescriptionTexts.call(
      this, {
        groupedType,
        previousGroupedTypes,
        nextGroupedTypes,
        materialWDCID,
        coatingWDCID,
        previousWDCID,
      },
    )

    return {
      results,
      explanationTexts,
      workDescriptionTexts,
    }
  }

  getRightSideContamindationWDCID() {
    // No test data uploaded
    if (this.isMissingUploadedData) {
      return false
    }
    // Resale
    if (this.isResale) {
      return false
    }
    // No wdgID
    if (!this.wdgID) {
      return false
    }
    // Missing sample
    if (this.isMissingSample) {
      return false
    }
    // Missing test result
    if (this.isMissingTestResults) {
      return false
    }
    // Special cases
    if (this.specialCase) {
      return false
    }

    // Coating
    return this.rightSideWDCID
  }

  getLeftSideContamindationWDCID() {
    // No test data uploaded
    if (this.isMissingUploadedData) {
      return false
    }
    // Resale
    if (this.isResale) {
      return false
    }
    // No wdgID
    if (!this.wdgID) {
      return false
    }
    // Missing sample
    if (this.isMissingSample) {
      return false
    }
    // Missing test result
    if (this.isMissingTestResults) {
      return false
    }
    // Special cases
    if (this.specialCase) {
      return false
    }

    return this.leftSideWDCID
  }
}
