export const testDataToImport = [
  // TODO: min and max values for this
  // {
  //   euroFinsName: 'Arsen (As)',
  //   id: 'As',
  //   type: 'value-based',
  // },
  {
    euroFinsName: 'Bly (Pb)',
    id: 'Pb',
    type: 'value-based',
    unit: 'mg/kg',
    minValue: 40,
    maxValue: 2500,
  },
  {
    euroFinsName: 'Cadmium (Cd)',
    id: 'Cd',
    type: 'value-based',
    unit: 'mg/kg',
    minValue: 0.5,
    maxValue: 1000,
  },
  {
    euroFinsName: 'Chrom (Cr)',
    id: 'Cr',
    type: 'value-based',
    unit: 'mg/kg',
    minValue: 500,
    maxValue: 1000,
  },
  {
    euroFinsName: 'Kobber (Cu)',
    id: 'Cu',
    type: 'value-based',
    unit: 'mg/kg',
    minValue: 500,
    maxValue: 2500,
  },
  {
    euroFinsName: 'Kviksølv (Hg)',
    id: 'Hg',
    type: 'value-based',
    unit: 'mg/kg',
    minValue: 1,
    maxValue: 1000,
  },
  {
    euroFinsName: 'Nikkel (Ni)',
    id: 'Ni',
    type: 'value-based',
    unit: 'mg/kg',
    minValue: 30,
    maxValue: 1000,
  },
  {
    euroFinsName: 'Zink (Zn)',
    id: 'Zn',
    type: 'value-based',
    unit: 'mg/kg',
    minValue: 500,
    maxValue: 2500,
  },
  {
    euroFinsName: 'PAH sum',
    id: 'PAH',
    type: 'value-based',
    unit: 'mg/kg',
    minValue: 4,
    maxValue: 1000,
  },
  {
    euroFinsName: 'Spor af Chlorparaffiner', // There are 2 of these in the excel file
    id: 'cpScreening',
    type: 'prove-based',
  },
  {
    euroFinsName: 'Sum C10-C13 chlorparaffiner inkl. LOQ',
    id: 'cpShort',
    type: 'value-based',
    unit: 'mg/kg',
    minValue: null,
    maxValue: 2500,
  },
  {
    euroFinsName: 'Sum C14-C17 chlorparaffiner inkl. LOQ',
    id: 'cpMedium',
    type: 'value-based',
    unit: 'mg/kg',
    minValue: null,
    maxValue: 2500,
  },
  {
    euroFinsName: 'Asbest i materialeprøver',
    id: 'ASB',
    type: 'prove-based',
  },
  {
    euroFinsName: 'PCB total (sum af 7 PCB x 5)',
    id: 'PCB',
    type: 'value-based',
    unit: 'mg/kg',
    minValue: 0.1,
    maxValue: 50,
  },
]

export const testResultsGroups = [
  {
    id: 'PCB',
    tests: [
      {
        id: 'PCB',
        wordbook: 'SAMPLE_PCB',
      },
    ],
    wordbook: 'SAMPLE_PCB',
  },
  {
    id: '7-metals',
    tests: [
      {
        id: 'Pb',
        wordbook: 'LEAD',
      },
      {
        id: 'Cd',
        wordbook: 'CADMIUM',
      },
      {
        id: 'Cr',
        wordbook: 'CHROME',
      },
      {
        id: 'Cu',
        wordbook: 'COPPER',
      },
      {
        id: 'Hg',
        wordbook: 'MERCURY',
      },
      {
        id: 'Ni',
        wordbook: 'NICKEL',
      },
      {
        id: 'Zn',
        wordbook: 'ZINC',
      },
    ],
    wordbook: 'SAMPLE_7_METALS',
  },
  {
    id: 'PAH',
    tests: [
      {
        id: 'PAH',
        wordbook: 'SAMPLE_9PAH',
      },
    ],
    wordbook: 'SAMPLE_9PAH',
  },
  {
    id: 'ASB',
    tests: [
      {
        id: 'ASB',
        wordbook: 'SAMPLE_ASBEST',
      },
    ],
    wordbook: 'SAMPLE_ASBEST',
  },
  {
    id: 'CP_SCREENING',
    tests: [
      {
        id: 'cpScreening',
        wordbook: 'SAMPLE_KPS',
      },
    ],
    wordbook: 'SAMPLE_KPS',
  },
  {
    id: 'CP_SHORT',
    tests: [
      {
        id: 'cpShort',
        wordbook: 'CPS_SHOT',
      },
    ],
    wordbook: 'CPS_SHOT',
  },
  {
    id: 'CP_MEDIUM',
    tests: [
      {
        id: 'cpMedium',
        wordbook: 'CPS_MEDIUM',
      },
    ],
    wordbook: 'CPS_MEDIUM',
  },
]

export const testResultsForSingleSample = [
  {
    id: 'PCB',
    wordbook: 'SAMPLE_PCB',
  },
  {
    id: 'cpShort',
    wordbook: 'CPS_SHOT',
  },
  {
    id: 'cpMedium',
    wordbook: 'CPS_MEDIUM',
  },
  {
    id: 'Pb',
    wordbook: 'LEAD',
  },
  {
    id: 'Cd',
    wordbook: 'CADMIUM',
  },
  {
    id: 'Cr',
    wordbook: 'CHROME',
  },
  {
    id: 'Cu',
    wordbook: 'COPPER',
  },
  {
    id: 'Hg',
    wordbook: 'MERCURY',
  },
  {
    id: 'Ni',
    wordbook: 'NICKEL',
  },
  {
    id: 'Zn',
    wordbook: 'ZINC',
  },
  {
    id: 'PAH',
    wordbook: 'SAMPLE_9PAH',
  },
  {
    id: 'ASB',
    wordbook: 'SAMPLE_ASBEST',
  },
]
