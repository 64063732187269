import { db } from '@/firebase/init'
import Changelog from '@/globals/javascript/models/Changelog'
import { DB_CHANGELOGS, DB_STATS } from '@/globals/javascript/models/_helper'
import * as Sentry from '@sentry/browser'

export const admin = {
  state: {
    allChangelogs: [],

    // Loaded
    allChangelogsLoaded: false,
  },
  mutations: {
    setAllChangelogsLoadedState: (state, status) => {
      state.allChangelogsLoaded = status
    },
    setChangelogs: (state, changelogs) => {
      state.allChangelogs = changelogs
      state.allChangelogsLoaded = true
    },
  },
  actions: {
    async getChangelogs({ commit }) {
      commit('setAllChangelogsLoadedState', false)

      const snapshot = await db
        .collection(DB_STATS)
        .doc('--stats--')
        .collection(DB_CHANGELOGS)
        .orderBy('version', 'desc')
        .limit(20)
        .get()

      const changelogs = snapshot.docs.map((doc) => new Changelog({
        ...doc.data(),
      }))

      commit('setChangelogs', changelogs)
    },
    clearUploadList: ({ commit }) => {
      commit('clearUploadList')
    },
    saveChangelog: ({ dispatch }, { changelog }) => {
      try {
        db
          .collection(DB_STATS)
          .doc('--stats--')
          .collection(DB_CHANGELOGS)
          .doc(changelog.id)
          .set({
            ...changelog,
          })
      }
      catch (err) {
        Sentry.captureException(err)
        return
      }

      dispatch('getChangelogs')
    },
  },
  getters: {
    // Loaders
    allChangelogsLoaded: (state) => state.allChangelogsLoaded,

    // Other
    allChangelogs: (state) => state.allChangelogs,
  },
}
