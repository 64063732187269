import store from '@/store/index'
import { firebase, db } from '@/firebase/init'
import { mixWB } from '@/globals/javascript/_util/mixins'
import { DB_SCREENINGS, DB_UNITS } from '../_helper'

export default class Unit {
  constructor({
    id = null,
    createdAt = null,
    isSelected = false,
    bbrID = null,
    bbrData = null,
    type,
    isFloorPlanSkipped = false,
    isProfileImageSkipped = false,
  }) {
    if (id) {
      this.id = id
    }
    else {
      const docRef = db
        .collection(DB_SCREENINGS)
        .doc(store.getters.currentScreening.id)
        .collection(DB_UNITS)
        .doc()

      this.id = docRef.id
    }

    this.createdAt = createdAt || firebase.firestore.FieldValue.serverTimestamp()
    this.isSelected = isSelected
    this.bbrID = bbrID
    this.bbrData = bbrData
    this.type = type
    this.typeSorting = 999
    this.isFloorPlanSkipped = isFloorPlanSkipped
    this.isProfileImageSkipped = isProfileImageSkipped

    // Set type sorting
    if (type === 'building') {
      this.typeSorting = 1
    }
    if (type === 'floor') {
      this.typeSorting = 2
    }
    if (type === 'apartment') {
      this.typeSorting = 3
    }
  }

  getTypeText() {
    if (this.type === 'building') {
      return mixWB('BUILDING')
    }
    if (this.type === 'floor') {
      return mixWB('FLOOR')
    }
    if (this.type === 'apartment') {
      return mixWB('APARTMENT')
    }
    return ''
  }

  hasFloorPlanSkipped() {
    return this.isFloorPlanSkipped && this.canHaveFloorPlanSkipped()
  }

  hasProfileImageSkipped() {
    return this.isProfileImageSkipped && this.canHaveProfileImageSkipped()
  }

  canHaveFloorPlanSkipped() {
    // Only way a units floor plan can be skipped is if:
    // - Does not have floor plan already
    //   AND
    //   - On building if it has ANY children
    //   - OR apartment/floor, has any parent floor plan
    return !this.hasFloorPlan() && (
      (this.type === 'building' && this.hasChildren())
      || this.hasParentFloorPlan()
    )
  }

  canHaveProfileImageSkipped() {
    return this.hasParent()
  }

  getParents() {
    const { selectedUnits } = store.getters

    if (!this.data.buildingID && !this.data.floorID) {
      return []
    }

    const referencingIDs = [this.data.buildingID, this.data.floorID]

    return selectedUnits.filter(
      (unit) => referencingIDs.includes(unit.id),
    )
  }

  getChildren() {
    const { selectedUnits } = store.getters

    return selectedUnits.filter(
      (unit) => unit.data.buildingID === this.id || unit.data.floorID === this.id,
    )
  }

  hasChildren() {
    return this.getChildren().length > 0
  }

  hasParent() {
    return this.getParents().length > 0
  }

  hasFloorPlan() {
    return this.getFloorPlan().length >= 1
  }

  getTopParent() {
    const { selectedUnits } = store.getters
    const building = this.data.buildingID && selectedUnits.find(
      (building) => building.id === this.data.buildingID,
    )

    if (building) {
      return building
    }

    const floor = this.data.floorID && selectedUnits.find(
      (floor) => floor.id === this.data.floorID,
    )

    return floor
  }

  getFloorPlan() {
    const { addressImages } = store.getters

    return addressImages?.units?.[this.id]?.floorPlans || []
  }

  parentsWithFloorPlan() {
    const { selectedUnits } = store.getters
    const building = this.data.buildingID && selectedUnits.find(
      (building) => building.id === this.data.buildingID,
    )
    const floor = this.data.floorID && selectedUnits.find(
      (floor) => floor.id === this.data.floorID,
    )

    const ids = []

    if (building?.hasFloorPlan()) {
      ids.push(building.id)
    }

    if (floor?.hasFloorPlan()) {
      ids.push(floor.id)
    }

    return ids
  }

  hasParentFloorPlan() {
    return this.parentsWithFloorPlan().length > 0
  }

  static getUnitById(unitID) {
    // Check current project
    const unit = store.getters.combinedListOfUnits.find(
      (unit) => unit.id === unitID,
    )

    if (unit) {
      return unit
    }

    // Check related projects
    const { screeningRelatedScreenings } = store.getters
    let relatedUnit = null
    screeningRelatedScreenings.forEach((relatedScreening) => {
      if (relatedUnit) {
        return
      }
      relatedUnit = relatedScreening.units.find((unit) => unit.id === unitID)
    })
    return relatedUnit
  }

  static getUnitsByIds(unitIDs) {
    return store.getters.combinedListOfUnits.filter(
      (unit) => unitIDs.includes(unit.id),
    )
  }
}
