/* eslint-disable no-restricted-properties */
/* eslint-disable no-use-before-define */
import Unit from '@/globals/javascript/models/units/Unit'
import { mixWB } from '@/globals/javascript/_util/mixins'

export class Apartment extends Unit {
  constructor({
    // Super
    id,
    createdAt,
    isSelected,
    bbrID,
    bbrData,
    isFloorPlanSkipped,
    isProfileImageSkipped,

    // Apartment
    data = {},
  }) {
    super({
      id,
      createdAt,
      isSelected,
      bbrID,
      bbrData,
      isFloorPlanSkipped,
      isProfileImageSkipped,
      type: 'apartment',
    })

    // Other generated data
    this.data = {
      floorID: data.floorID || null,
      buildingID: data.buildingID || null,
      bbrAccessAddressID: data.bbrAccessAddressID || null,
      title: data.title || '',
      numericFloorValue: data.numericFloorValue !== null && data.numericFloorValue !== undefined
        ? data.numericFloorValue
        : null,
    }
  }

  getFullTitle() {
    const floorText = getShortFloorText(this.data.numericFloorValue)
    const building = Apartment.getUnitById(this.data.buildingID)
    const buildingText = building.getShortTitle()

    if (this.bbrID) {
      if (!this.bbrData.dør) {
        return `${ buildingText } - ${ floorText } - ${ this.bbrData.etage.toUpperCase() }`
      }
      if (window.isNaN(this.bbrData.dør)) {
        return `${ buildingText } - ${ floorText } ${ this.bbrData.dør }`
      }

      return `${ buildingText } - ${ getFloorText(this.data.numericFloorValue) } - ${ mixWB('DOOR_X', [this.bbrData.dør]) }`
    }

    return `${ buildingText } - ${ floorText }`
  }

  getTitle() {
    const floorText = getFloorText(this.data.numericFloorValue)

    if (this.bbrID) {
      if (!this.bbrData.dør) {
        return `${ floorText } - ${ this.bbrData.etage.toUpperCase() }`
      }
      if (window.isNaN(this.bbrData.dør)) {
        return `${ floorText } - ${ this.bbrData.dør }`
      }

      return `${ floorText } - ${ mixWB('DOOR_X', [this.bbrData.dør]) }`
    }

    return floorText
  }

  getStatus() {
    if (this.bbrID) {
      if (this.bbrData.darstatus === 2) {
        return mixWB('PRELIMINARY')
      }
      if (this.bbrData.darstatus === 3) {
        return mixWB('APPLICABLE')
      }
      if (this.bbrData.darstatus === 4) {
        return mixWB('DISCONTINUED')
      }
      if (this.bbrData.darstatus === 5) {
        return mixWB('ABANDONED')
      }
      return mixWB('NO_STATUS_FOUND')
    }
    return mixWB('NO_STATUS_FOUND')
  }
}

// Private functions
const getFloorText = (numericFloorValue) => {
  if (numericFloorValue > 0) {
    return mixWB('X_FLOOR', [numericFloorValue])
  }
  if (numericFloorValue < 0) {
    return mixWB('BASEMENT_X_SHORT', [numericFloorValue])
  }
  return mixWB('GROUND_FLOOR')
}

const getShortFloorText = (numericFloorValue) => {
  if (numericFloorValue > 0) {
    return `${ numericFloorValue }.`
  }
  if (numericFloorValue < 0) {
    return mixWB('BASEMENT_X_SHORT', [numericFloorValue])
  }
  return mixWB('GROUND_FLOOR_SHORT')
}
