import { DB_SAMPLES, setIDOnCreate } from '@/globals/javascript/models/_helper'
import { firebase } from '@/firebase/init'
import store from '@/store/index'

export default class Sample {
  constructor({
    id = null,
    otherScreeningID = '',
    typeID = null,
    kindOfSample,
    status = 'not-done',
    images = {},

    // Booleans
    isAutoAssessment,

    // Numbers
    assessNumber = null,
    equivalentNumber = null,
    equivalentSampleID = null,
    equivalentSampleNumber = null,
    sampleNumber = null,

    // Floor plan marker
    floorPlanUnit = null,
    floorPlanPosition = { x: null, y: null },

    // Timings
    createdAt = null,
    lastUpdated = null,
  }) {
    this.id = setIDOnCreate(id, DB_SAMPLES)
    this.otherScreeningID = otherScreeningID
    this.typeID = typeID
    this.kindOfSample = kindOfSample || 'material' // 'material' | 'coating' | 'underlining'
    this.status = status
    this.images = {
      close: images.close || null,
      away: images.away || null,
    }

    // Booleans
    this.isAutoAssessment = isAutoAssessment || false

    // Numbers
    this.assessNumber = assessNumber
    this.equivalentNumber = equivalentNumber
    this.equivalentSampleID = equivalentSampleID
    this.equivalentSampleNumber = equivalentSampleNumber
    this.sampleNumber = sampleNumber

    this.floorPlanUnit = floorPlanUnit
    this.floorPlanPosition = {
      x: floorPlanPosition?.x ?? null,
      y: floorPlanPosition?.y ?? null,
    }

    // Timings
    this.createdAt = createdAt || firebase.firestore.FieldValue.serverTimestamp()
    this.lastUpdated = lastUpdated || firebase.firestore.FieldValue.serverTimestamp()
  }

  reset(status = 'not-done') {
    this.status = status
    this.images = {
      close: null,
      away: null,
    }

    this.otherScreeningID = ''
    this.assessNumber = null
    this.equivalentNumber = null
    this.equivalentSampleID = null
    this.equivalentSampleNumber = null
    this.sampleNumber = null
    this.resetFloorPlan()
  }

  resetFloorPlan() {
    this.floorPlanPosition = { x: null, y: null }
    this.floorPlanUnit = null
  }

  getNumber() {
    if (this.sampleNumber) {
      return `P${ this.sampleNumber }`
    }

    if (this.assessNumber) {
      return `V${ this.assessNumber }`
    }

    if (this.equivalentNumber) {
      return `T${ this.equivalentNumber } (P${ this.equivalentSampleNumber })`
    }

    return false
  }

  getFullTitle() {
    const { screeningTypes } = store.getters
    let type = null
    if (this.typeID) {
      type = screeningTypes.find((x) => x.id === this.typeID)
    }
    // Pre 1.14.0
    else {
      type = screeningTypes.find((x) => x.id === this.id)
    }

    let title = ''

    title += `${
      type.getTitle({ category: true, details: true })
    }${
      type.description ? ` - ${ type.description }` : ''
    }`

    return title
  }

  getRelatedScreeningCaseNumber() {
    const {
      screeningRelatedScreenings,
    } = store.getters

    const relatedScreening = screeningRelatedScreenings.find(
      (x) => x.data.id === this.otherScreeningID,
    )

    return relatedScreening?.data?.caseNumber() || '-'
  }
}
