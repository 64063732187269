const specialPCBLogic = {
  categoriesToInclude: ['C-8', 'C-9', 'C-10', 'C-11', 'C-12'],
  typesToExclude: ['CT-42', 'CT-43', 'CT-45', 'CT-46', 'CT-62', 'CT-63', 'CT-77'],
}

export const checkToAddPCBToType = (type, screeningPCBScreenings, kindOfSample = '') => {
  // Check for other type
  if (type.type.isOtherType) {
    if (kindOfSample !== 'coating') {
      return false
    }
  }

  // Check if PCB is already in the list
  if (kindOfSample === 'material' && type.samples.sampleIDs.includes('ST-4')) {
    return false
  }
  if (kindOfSample === 'coating' && type.coating.samples.sampleIDs.includes('ST-4')) {
    return false
  }
  if (kindOfSample === 'underlining' && type.underlining.samples.sampleIDs.includes('ST-4')) {
    return false
  }

  // Only add PCB to these indoor categories
  if (!specialPCBLogic.categoriesToInclude.includes(type.categoryID)) {
    return false
  }

  // Don't include for these types
  if (specialPCBLogic.typesToExclude.includes(type.type.typeID)) {
    return false
  }

  // Check if type units requires PCB testing - Units
  let needsPCBTesting = false
  if (screeningPCBScreenings) {
    type.getUnits().forEach((unit) => {
      if (needsPCBTesting) {
        return
      }
      const topUnitID = unit.hasParent() ? unit.getTopParent().id : unit.id
      const pcbScreening = screeningPCBScreenings.find((x) => x.id === topUnitID)
      if (pcbScreening && pcbScreening.needsTesting) {
        needsPCBTesting = true
      }
    })
  }

  // Check if type units requires PCB testing - Buildings
  if (type.buildings) {
    type.buildings.forEach((buildingID) => {
      if (!screeningPCBScreenings) {
        return
      }

      if (needsPCBTesting) {
        return
      }

      const pcbScreening = screeningPCBScreenings.find((x) => x.id === buildingID)
      if (pcbScreening && pcbScreening.needsTesting) {
        needsPCBTesting = true
      }
    })
  }

  if (!needsPCBTesting) {
    return false
  }

  return true
}
