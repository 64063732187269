/* eslint-disable no-use-before-define */
import store from '@/store'
import { storage } from '@/firebase/init'
import moment from 'moment-with-locales-es6'

export const mixins = {
  methods: {
    mixWB(key, params = []) {
      const {
        translations,
      } = store.getters

      if (!key) {
        return ''
      }

      const translation = translations[key] || false

      let textToUse

      if (!translation) {
        textToUse = key.replace('W: ', '')
      }
      else {
        textToUse = translation
      }

      params.forEach((param) => {
        textToUse = textToUse.replace('%s', param)
      })

      return textToUse
    },
    mixGetDownloadURL({ path }) {
      return new Promise((resolve, reject) => {
        storage.ref().child(path).getDownloadURL()
          .then((url) => {
            resolve(url)
          })
          .catch(() => {
            reject()
          })
      })
    },
    mixGetDate(dateObject, formatting = 'lll') {
      if (!dateObject) {
        return ''
      }
      const theDate = dateObject.toDate
        ? moment(dateObject.toDate())
        : moment(dateObject)
      theDate.locale('da-DK')
      const formattedDate = theDate.format(formatting)
      return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1)
    },
    mixGetImageSizeItem({ imageObject, size }) {
      return imageObject.sizes
        ? imageObject.sizes.find((x) => x.name === size)
        : null
    },
    mixGetSizeWordbook(size) {
      if (size === 'small') {
        return mixWB('SMALL')
      }
      return mixWB('BASE')
    },
    mixGetSampleStatusWordbook(status) {
      if (status === 'skip-asbest') {
        return mixWB('ASBESTOS_ETERNIT')
      }
      if (status === 'skip-hazardous-waste') {
        return mixWB('HAZARDOUS_WASTE_SMALL_AMOUNT')
      }
      if (status === 'auto-assess-asbestos-perforated-ceiling-tiles') {
        return mixWB('ASBESTOS_PERFORATED_CEILING_TILES')
      }
      return mixWB('OTHER')
    },
    mixCompareVersionsNewerOnly(versionA, versionB) {
      if (!versionA || !versionB) {
        return false
      }

      const versionAArray = versionA.split('.')
      const versionBArray = versionB.split('.')

      let isVersionANewer = null
      versionBArray.forEach((num, index) => {
        if (isVersionANewer !== null) {
          return
        }
        if (Number(num) === Number(versionAArray[index])) {
          return
        }
        isVersionANewer = Number(num) < Number(versionAArray[index])
      })

      if (isVersionANewer === null) {
        return false
      }

      return isVersionANewer
    },
    mixCompareVersionsSameOrNewer({
      versionA,
      versionB,
    }) {
      if (versionA === versionB) {
        return true
      }

      const versionAArray = versionA.split('.')
      const versionBArray = versionB.split('.')

      let isSameOrNewer = true
      let stopLoop = false
      versionAArray.forEach((num, index) => {
        if (stopLoop) {
          return
        }
        if (Number(num) > Number(versionBArray[index])) {
          stopLoop = true
        }
        if (Number(num) < Number(versionBArray[index])) {
          isSameOrNewer = false
          stopLoop = true
        }
      })

      return isSameOrNewer
    },
    mixFormatNumber(number, decimals = false) {
      if (Number.isNaN(Number(number))) {
        return number
      }

      const userLang = store.getters.selectedLanguage.locale
        || navigator.language
        || navigator.userLanguage

      // Find decimals
      let decimalsToUse = decimals
      if (decimals === false) {
        const numberArray = number.toString().split('.')
        if (numberArray.length > 1) {
          decimalsToUse = numberArray[1].length
        }
        else {
          decimalsToUse = 0
        }
      }

      return parseFloat(parseFloat(number).toFixed(decimalsToUse)).toLocaleString(
        userLang,
        {
          minimumFractionDigits: decimals,
        },
      )
    },
    mixFormatPhoneNumber(number) {
      if (number.toString().length === 8) {
        const array = number.split('')
        return array.reduce((prev, char, index) => {
          prev += char

          if (index !== array.length - 1 && index % 2 !== 0) {
            prev += ' '
          }
          return prev
        }, '')
      }
      return number
    },
    mixGetReportTitle() {
      const { currentScreeningData } = store.getters
      const projectAddress = currentScreeningData.address

      return `Milva miljørapport - ${
        projectAddress.address
      }, ${
        projectAddress.postalCode
      } ${
        projectAddress.city
      } - ${
        store.getters.caseNumber
      }`
    },
    mixGetTenderTitle() {
      const { currentScreeningData } = store.getters
      const projectAddress = currentScreeningData.address

      return `UDBUD-${
        currentScreeningData.screeningNumber
      } - ${
        projectAddress.address
      }, ${
        projectAddress.postalCode
      } ${
        projectAddress.city
      }`
    },
    mixGetListAsText({ idList, objectWithAllIDs, includeAnd = false }) {
      return idList.reduce((prev, id, index) => {
        if (index === 0) {
          prev += objectWithAllIDs[id].title
            ? objectWithAllIDs[id].title
            : mixWB(objectWithAllIDs[id].wordbook)
          return prev
        }

        if (includeAnd && index === idList.length - 1) {
          prev = `${
            prev
          } ${
            mixWB('AND')
          } ${
            objectWithAllIDs[id].title
              ? objectWithAllIDs[id].title
              : mixWB(objectWithAllIDs[id].wordbook).toLowerCase()
          }`
          return prev
        }

        prev = `${ prev }, ${ objectWithAllIDs[id].title
          ? objectWithAllIDs[id].title
          : mixWB(objectWithAllIDs[id].wordbook).toLowerCase() }`

        return prev
      }, '')
    },
    mixGetUnitTitlesAsList({ unitIDs, listOfUnits }) {
      return unitIDs.map((unitID) => {
        const unit = listOfUnits.find((x) => x.id === unitID)

        return unit.getFullTitle()
      })
    },
  },
}

export const { mixWB } = mixins.methods
export const { mixFormatNumber } = mixins.methods
export const { mixGetSizeWordbook } = mixins.methods
export const { mixGetListAsText } = mixins.methods
export const { mixGetUnitTitlesAsList } = mixins.methods
export const { mixCompareVersionsNewerOnly } = mixins.methods
export const { mixCompareVersionsSameOrNewer } = mixins.methods
