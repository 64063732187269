<template>
  <div
    :class="[rootClasses, currentPageName]"
    class="App">
    <SplashScreen v-if="!isAppInitialized" />
    <UploadStatusList v-if="isAppInitialized && isLoggedIn && showUploadStatusList"/>
    <ToolBar v-if="isAppInitialized && isLoggedIn && $route.name !== 'ChangePassword'"/>
    <div
      v-if="overridenAccountID && currentAccount"
      class="OnBehalfOfMessage">
      {{ mixWB('USING_MILVA_BEHALF_ON_ACCOUNT') }}
      <span>{{ currentAccount.name }}</span>
      <button @click="resetAccount">{{ mixWB('RESET_ACCOUNT') }}</button>
    </div>
    <div
      class="PageBody">
      <LoadingPage :show="isPageLoading" />
      <router-view
        v-if="isAppInitialized" />
    </div>
    <BackToTop v-if="showBackToTop" />
    <Changelog />
    <GlobalMenu />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { auth } from '@/firebase/init'
import EventBus from '@/EventBus'
import ToolBar from '@/components/ToolBar.vue'
import UploadStatusList from '@/components/UploadStatusList.vue'
import SplashScreen from '@/components/SplashScreen.vue'
import LoadingPage from '@/components/LoadingPage.vue'
import BackToTop from '@/components/BackToTop.vue'
import Changelog from '@/components/Changelog/Changelog.vue'
import GlobalMenu from '@/components/DotMenu/GlobalMenu.vue'

export default {
  computed: {
    ...mapGetters([
      'isAppInitialized',
      'isPageLoading',
      'isLoggedIn',
      'showUploadStatusList',
      'packageVersion',
      'firestoreAppVersion',
      'currentRoute',
      'overridenAccountID',
      'currentAccount',
    ]),
    rootClasses() {
      return {
        IsLoading: this.isPageLoading,
      }
    },
    currentPageName() {
      return `Page${ this.$route.name }`.replaceAll('.', '-')
    },
    showBackToTop() {
      if ([
        'ScreeningsUpdateCategoryAddType',
        'ScreeningsUpdateCategoryEditType',
      ].includes(this.currentRoute.name)) {
        return false
      }
      return true
    },
  },
  watch: {
    $route(newRoute) {
      this.$store.dispatch('updateCurrentRoute', newRoute)
      this.$store.dispatch('checkAndUpdatePageScroll', newRoute)
    },
  },
  methods: {
    onFirebaseError(type) {
      if (type === 'bad-browsers') {
        alert(this.mixWB('OFFLINE_ERROR_BAD_BROWSER'))
      }
    },
    resetAccount() {
      this.$store.dispatch('resetAccount')
    },
  },
  components: {
    UploadStatusList,
    SplashScreen,
    ToolBar,
    LoadingPage,
    BackToTop,
    Changelog,
    GlobalMenu,
  },
  created() {
    EventBus.$on('firebase-error-multiple-tabs', this.onFirebaseError.bind(this, 'multiple-tabs'))
    EventBus.$on('firebase-error-bad-browsers', this.onFirebaseError.bind(this, 'bad-browsers'))
  },
  mounted() {
    auth.onAuthStateChanged((user) => {
      // Set app as initialized
      if (!this.isAppInitialized) this.$store.dispatch('updateAppInitialized', true)

      // Update login state
      this.$store.dispatch('updateUserLoginState', user)
      this.$store.dispatch('getAllStats')

      // Emit login or refresh
      if (user) {
        this.$store.dispatch('getCurrentUser', user)
        this.$store.dispatch('setupUploadStatusListOnLoad')
        EventBus.$emit('user-logged-in')
      }
    })
  },
}
</script>

<style lang="stylus">
  .App
    width 100%
    min-height 100vh
    padding-bottom 400px
    &.IsLoading
      max-height 100vh
      overflow hidden
      padding-bottom 0
  .OnBehalfOfMessage
    background $color_yellow
    font-size 0.875rem
    padding 10px
    text-align center
    span
      display inline
      font-weight bold
    button
      display inline-block
      width auto
      margin-left 5px
      padding 2px 4px
      background $color_yellow_darkest
      color $color_grey_dark
  .PageBody
    position relative
    .IsLoading &
      height calc(100vh - 52px)

  @media print
    .App
      min-height 0
      padding-bottom 0
</style>
