import { db } from '@/firebase/init'
import Account from '@/globals/javascript/models/Account'
import { DB_ACCOUNTS } from '@/globals/javascript/models/_helper'
import { keys } from '@/globals/javascript/_util/keys'

export const account = {
  state: {
    account: null,
    allAccounts: [],

    // Snapshot listeners
    unsubscribeAccount: null,
    unsubscribeAccounts: null,
  },
  mutations: {
    updateAccount: (state, { account, unsubscribe }) => {
      state.account = account
      if (!state.unsubscribeAccount) {
        state.unsubscribeAccount = unsubscribe
      }
    },
    updateAccounts: (state, { accounts, unsubscribe }) => {
      state.allAccounts = accounts
      if (!state.unsubscribeAccounts) {
        state.unsubscribeAccounts = unsubscribe
      }
    },
    resetAccount: (state) => {
      state.account = null
      if (state.unsubscribeAccount) {
        state.unsubscribeAccount()
        state.unsubscribeAccount = null
      }
    },
    resetAccounts: (state) => {
      state.allAccounts = []
      window.localStorage.removeItem(keys.LS_OVERRIDDEN_ACCOUNT_ID)
      if (state.unsubscribeAccounts) {
        state.unsubscribeAccounts()
        state.unsubscribeAccounts = null
      }
    },
  },
  actions: {
    getAccount: ({ commit, getters }) => {
      if (getters.accountLoaded) {
        return
      }

      const accountID = getters.currentUserAccountId
      const unsubscribe = db
        .collection(DB_ACCOUNTS)
        .doc(accountID)
        .onSnapshot((doc) => {
          commit('updateAccount', {
            unsubscribe,
            account: new Account({
              ...doc.data(),
              id: doc.id,
            }),
          })
        })
    },
    getAllAccounts: ({ commit }) => {
      const unsubscribe = db
        .collection(DB_ACCOUNTS)
        .onSnapshot((querySnapshot) => {
          const accounts = []
          querySnapshot.forEach((doc) => {
            accounts.push(new Account({
              ...doc.data(),
              id: doc.id,
            }))
          })
          commit('updateAccounts', { accounts, unsubscribe })
        })
    },
    updateAccount: (store, { data, id }) => db
      .collection(DB_ACCOUNTS)
      .doc(id)
      .set({
        ...data,
      }, { merge: true }),
    accountChange: ({ getters }, { accountID }) => {
      if (getters.currentUser.accountID === accountID) {
        window.localStorage.removeItem(keys.LS_OVERRIDDEN_ACCOUNT_ID)
      }
      else {
        window.localStorage.setItem(keys.LS_OVERRIDDEN_ACCOUNT_ID, accountID)
      }
      window.location = window.location.origin
    },
    resetAccount: () => {
      window.localStorage.removeItem(keys.LS_OVERRIDDEN_ACCOUNT_ID)
      window.location = window.location.origin
    },
  },
  getters: {
    accountLoaded: (state) => !!state.unsubscribeAccount,
    currentAccount: (state) => state.account,
    nextScreeningNumber: (state) => {
      if (!state.account || !state.account.nextScreeningNumber) {
        return null
      }
      return state.account.nextScreeningNumber
    },
    allAccounts: (state) => state.allAccounts,
  },
}
