<template>
  <div class="BurgerMenuIcon">
    <span />
  </div>
</template>

<script>
export default {

}
</script>

<style lang="stylus" scoped>
.BurgerMenuIcon
  height 20px
  span
  &:before
  &:after
    position relative
    display block
    box(28px, 2px)
    background-color #fff
    margin-bottom 6px
  &:before
  &:after
    content ''
  &:after
    width 24px
    margin-bottom 2px
</style>
