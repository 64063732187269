import Unit from '@/globals/javascript/models/units/Unit'
import { mixWB } from '@/globals/javascript/_util/mixins'

export class Floor extends Unit {
  constructor({
    // Super
    id,
    createdAt,
    isSelected,
    bbrID,
    bbrData,
    isFloorPlanSkipped,
    isProfileImageSkipped,

    // Floor
    data = {},
    apartmentIDs = [],
  }) {
    super({
      id,
      createdAt,
      isSelected,
      bbrID,
      bbrData,
      isFloorPlanSkipped,
      isProfileImageSkipped,
      type: 'floor',
    })

    // Other generated data
    this.data = {
      buildingID: data.buildingID || null,
      bbrBuildingID: data.bbrBuildingID || null,
      numericFloorValue: data.numericFloorValue !== null && data.numericFloorValue !== undefined
        ? data.numericFloorValue
        : null,
    }
    this.apartmentIDs = apartmentIDs
  }

  getTitle() {
    if (this.data.numericFloorValue > 0) {
      return mixWB('X_FLOOR', [this.data.numericFloorValue])
    }
    if (this.data.numericFloorValue === -1) {
      return mixWB('BASEMENT')
    }
    if (this.data.numericFloorValue < 0) {
      return mixWB('BASEMENT_X_SHORT', [Math.abs(this.data.numericFloorValue)])
    }
    return mixWB('GROUND_FLOOR')
  }

  getFullTitle() {
    const floorText = this.getTitle(this.data.numericFloorValue)
    const building = Floor.getUnitById(this.data.buildingID)

    return `${ building.getShortTitle() } - ${ floorText }`
  }
}
