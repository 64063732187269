export default class GroupedTypeResultItem {
  constructor({
    kindOfResult,
    className,
    resultText,
  }) {
    this.kindOfResult = kindOfResult || null // 'null' | 'material' | 'coating'
    this.className = className || '' // 'Neutral' | 'Clean' | 'Contaminated' | 'Hazardous'
    this.resultText = resultText || ''
  }
}
