/* eslint-disable no-unused-vars */
import firebase from 'firebase/app'
import 'firebase/firebase-analytics'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/functions'
import { customLog } from '@/globals/javascript/_util/custom-log'
import EventBus from '@/EventBus'

// Your web app's Firebase configuration
// - Dev
const firebaseConfigDev = {
  apiKey: 'AIzaSyA-qi-BZqQzfojByqjQgV3ko8lbP1aVfUg',
  authDomain: 'milva-pro-dev.firebaseapp.com',
  databaseURL: 'https://milva-pro-dev.firebaseio.com',
  projectId: 'milva-pro-dev',
  storageBucket: 'milva-pro-dev.appspot.com',
  messagingSenderId: '686133547589',
  appId: '1:686133547589:web:2b04e3922192fbb301492c',
  measurementId: 'G-EQSH9VZ3GR',
}

// - Prod
const firebaseConfigProd = {
  apiKey: 'AIzaSyCkkxPMWVLgTVEF7io9MsgtollFjUvpMto',
  authDomain: 'milva-pro-prod.firebaseapp.com',
  databaseURL: 'https://milva-pro-prod.firebaseio.com',
  projectId: 'milva-pro-prod',
  storageBucket: 'milva-pro-prod.appspot.com',
  messagingSenderId: '559861270282',
  appId: '1:559861270282:web:08e8c3f2d3b7c38aca9cf3',
  measurementId: 'G-9MQ9NWPM6M',
}

// - Old
const firebaseConfigOld = {
  apiKey: 'AIzaSyDVbR3QBG4jShrVZQVQThg9BW8nJRJW78M',
  authDomain: 'vue-milva-app-100.firebaseapp.com',
  databaseURL: 'https://vue-milva-app-100.firebaseio.com',
  projectId: 'vue-milva-app-100',
  storageBucket: 'vue-milva-app-100.appspot.com',
  messagingSenderId: '395025872064',
  appId: '1:395025872064:web:83df67d770794e4af8e925',
  measurementId: 'G-EK9TTCK2GM',
}

// Init
// const firebaseApp = firebase.initializeApp(firebaseConfigOld)
// const firebaseApp = firebase.initializeApp(firebaseConfigProd)
const firebaseApp = firebase.initializeApp(process.env.NODE_ENV === 'development' ? firebaseConfigDev : firebaseConfigProd)
const db = firebaseApp.firestore()
const auth = firebaseApp.auth()
const storage = firebaseApp.storage()
const functions = firebaseApp.functions('europe-west3')

if (process.env.VUE_APP_FUNCTIONS_EMULATOR || process.env.VUE_APP_EMULATORS) {
  functions.useEmulator('localhost', 5001)
}

if (process.env.VUE_APP_DATABASE_EMULATOR || process.env.VUE_APP_EMULATORS) {
  db.useEmulator('localhost', 5000)
}

// Analytics
firebaseApp.analytics()

// Activate offline persistance
firebase.firestore().enablePersistence()
  .then(() => {
    customLog('firebase', 'Offline support: ACTIVE')
  })
  .catch((err) => {
    customLog('firebase', 'Offline support: FAILED')
    if (err.code === 'failed-precondition') {
      EventBus.$emit('firebase-error-multiple-tabs')
    }
    else if (err.code === 'unimplemented') {
      EventBus.$emit('firebase-error-bad-browsers')
    }
  })

// Logs
customLog('firebase', 'Project ID:', firebaseApp.options.projectId)

// Export
export {
  firebaseApp,
  firebase,
  db,
  auth,
  storage,
  functions,
}
