import EventBus from '@/EventBus'

export const statuses = {
  state: {},
  mutations: {},
  actions: {},
  getters: {
    samplesStatus: (state, getters) => {
      const {
        typesWithSamples,
        screeningSamples,
        currentScreeningSelectedFilterUnitID,
      } = getters

      const results = typesWithSamples.reduce((prev, type) => {
        if (
          currentScreeningSelectedFilterUnitID
          && !type.unitIDs.includes(currentScreeningSelectedFilterUnitID)
        ) {
          return prev
        }

        // Material auto assessed samples - we just assume the sample is there as it is
        // auto-created upon type creation.
        if (type.assessments.assessmentIDs.length) {
          prev.total += 1
          prev.autoAssessed += 1
        }

        // Material samples
        else if (type.samples.sampleIDs.length) {
          prev.total += 1
          const existingMaterialSample = screeningSamples.find(
            (x) => x.typeID === type.id && x.kindOfSample === 'material',
          )
          if (existingMaterialSample && existingMaterialSample.status === 'done') {
            prev.filled += 1
          }
          if (existingMaterialSample && existingMaterialSample.status === 'equivalent') {
            prev.equivalent += 1
          }
          if (existingMaterialSample && existingMaterialSample.status.includes('skip-')) {
            prev.skipped += 1
          }
        }

        // Coating samples
        if (type.coating.samples.sampleIDs.length) {
          prev.total += 1
          const existingCoatingsample = screeningSamples.find(
            (x) => x.typeID === type.id && x.kindOfSample === 'coating',
          )
          if (existingCoatingsample && existingCoatingsample.status === 'done') {
            prev.filled += 1
          }
          if (existingCoatingsample && existingCoatingsample.status === 'equivalent') {
            prev.equivalent += 1
          }
          if (existingCoatingsample && existingCoatingsample.status.includes('skip-')) {
            prev.skipped += 1
          }
        }

        // Underlining samples
        if (type.underlining.samples.sampleIDs.length) {
          prev.total += 1
          const existingUnderliningsample = screeningSamples.find(
            (x) => x.typeID === type.id && x.kindOfSample === 'underlining',
          )
          if (existingUnderliningsample && existingUnderliningsample.status === 'done') {
            prev.filled += 1
          }
          if (existingUnderliningsample && existingUnderliningsample.status === 'equivalent') {
            prev.equivalent += 1
          }
          if (existingUnderliningsample && existingUnderliningsample.status.includes('skip-')) {
            prev.skipped += 1
          }
        }

        return prev
      }, {
        total: 0,
        skipped: 0,
        equivalent: 0,
        filled: 0,
        autoAssessed: 0,
      })

      const done = results.skipped + results.equivalent + results.filled + results.autoAssessed

      return {
        ...results,
        done,
        isCompleted: results.total === done,
        isActiveStep: getters.currentRoute.name === 'ScreeningsUpdateSamples',
      }
    },
    profileImageStatus: (state, getters) => {
      const addressImages = getters.addressImages || {}
      const {
        selectedUnits,
        noOfSelectedUnits,
        currentRoute,
        currentScreeningSelectedFilterUnitID,
      } = getters

      const status = {
        total: 1 + noOfSelectedUnits || 0,
        done: 0,
        isCompleted: false,
        isActiveStep: currentRoute.name === 'ScreeningsUpdateProfileImages',
      }

      if (currentScreeningSelectedFilterUnitID) {
        status.total = 1
      }

      // Check overview image
      if (
        !currentScreeningSelectedFilterUnitID
        && addressImages.overview?.profile?.length >= 1
      ) {
        status.done += 1
      }

      // Check unit images
      selectedUnits.forEach((unit) => {
        if (
          currentScreeningSelectedFilterUnitID
           && unit.id !== currentScreeningSelectedFilterUnitID
        ) {
          return
        }

        if (unit.hasProfileImageSkipped()) {
          status.done += 1
          return
        }

        if (addressImages.units?.[unit.id]?.profile?.length >= 1) {
          status.done += 1
        }
      })

      status.isCompleted = status.done === status.total

      return status
    },
    floorPlanStatus: (state, getters) => {
      const {
        selectedUnits,
        noOfSelectedUnits,
        currentRoute,
        currentScreeningSelectedFilterUnitID,
      } = getters

      const status = {
        total: noOfSelectedUnits || 0,
        done: 0,
        isCompleted: false,
        isActiveStep: currentRoute.name === 'ScreeningsUpdateFloorPlans',
      }

      if (currentScreeningSelectedFilterUnitID) {
        status.total = 1
      }

      // Check units images
      selectedUnits.forEach((unit) => {
        if (
          currentScreeningSelectedFilterUnitID
           && unit.id !== currentScreeningSelectedFilterUnitID
        ) {
          return
        }

        if (unit.hasFloorPlanSkipped()) {
          status.done += 1
          return
        }

        if (unit.hasFloorPlan()) {
          status.done += 1
        }
      })

      status.isCompleted = status.done === status.total

      return status
    },
    pcbScreeningStatus: (state, getters) => {
      const pcbScreenings = getters.screeningPCBScreenings || []
      const { selectedUnits, currentRoute, currentScreeningSelectedFilterUnitID } = getters
      const topUnits = selectedUnits.filter((unit) => !unit.hasParent())
      const topUnitIDs = topUnits.map((unit) => unit.id)
      const status = {
        total: topUnits.length ?? 0,
        done: 0,
        isCompleted: false,
        isActiveStep: currentRoute.name === 'ScreeningsUpdatePSCScreeningSchema',
      }

      if (currentScreeningSelectedFilterUnitID) {
        // Only check for PCBs when filtering on the top most parent unit.
        const unit = selectedUnits.find((unit) => unit.id === currentScreeningSelectedFilterUnitID)
        status.total = 0
        if (!unit.hasParent()) {
          status.total = 1

          const topParentPCBUnit = unit.hasParent() ? unit.getTopParent() : unit

          const pcbScreening = pcbScreenings.find(
            (screening) => screening.id === topParentPCBUnit.id,
          )
          if (pcbScreening && pcbScreening.needsTesting !== null) {
            status.done += 1
          }
        }
      }
      else {
        // Only calculate for top most units, in case the a new top have been added.
        pcbScreenings.forEach((screening) => {
          if (topUnitIDs.includes(screening.id) && screening.needsTesting !== null) {
            status.done += 1
          }
        })
      }

      status.isCompleted = status.done === status.total

      return status
    },
    categoryStatus: () => EventBus.nextCategory,
    screeningResaleStatus: (state, getters) => {
      const status = {
        yes: 0,
        potential: 0,
      }

      if (!getters.currentScreening.types) {
        return status
      }

      getters.currentScreening.types.forEach((type) => {
        if (!type.resale) {
          return
        }

        if (type.resale === 'yes') {
          status.yes += 1
        }

        if (type.resale === 'potential') {
          status.potential += 1
        }
      })

      return status
    },
  },
}
