<template>
  <div
    class="Dropdown"
    :class="rootClasses">
    <div class="SelectWrap">
      <span
        v-if="placeholder && !value"
        class="Placeholder">{{ placeholder }}</span>
      <select
        v-model="selectedValue"
        @change="onChange">
        <option
          v-for="option in options"
          :key="option.value"
          :value="option.value">{{ option.name }}</option>
      </select>
      <div class="IconWrap">
        <AngleRightIcon />
      </div>
    </div>
  </div>
</template>

<script>
import AngleRightIcon from '@/assets/svg/angle-right.svg?inline'

export default {
  name: 'Dropdown',
  props: {
    name: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: String,
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    backgroundColor: {
      type: String,
      required: false,
      default: 'grey', // 'white' | 'grey'
    },
  },
  data() {
    return {
      selectedValue: '',
    }
  },
  computed: {
    rootClasses() {
      return {
        BackgroundColorGrey: this.backgroundColor === 'grey',
        BackgroundColorWhite: this.backgroundColor === 'white',
      }
    },
  },
  watch: {
    value() {
      this.selectedValue = this.value
    },
  },
  methods: {
    onChange() {
      requestAnimationFrame(() => {
        this.$emit('on-update', { name: this.name, value: this.selectedValue })
      })
    },
  },
  components: {
    AngleRightIcon,
  },
  mounted() {
    this.selectedValue = this.value
  },
}
</script>

<style lang="stylus" scoped>
  .Dropdown
    margin-bottom 10px
    position relative
    .Placeholder
      position absolute
      padding-top 13px
      padding-left 11px
      color $color_grey
      pointer-events none
    select
      display block
      height 40px
      padding-left 11px
      font-size 1.125rem
      width 100%
      border 1px solid $color_grey_lighter
      background-color #fff
      appearance none
      border-radius 0
      outline none
      cursor pointer
    .IconWrap
      box($width = 16px)
      flex-center-children()
      position absolute
      top 50%
      margin-top -($width / 2)
      right 16px
      transform rotateZ(90deg)
      pointer-events none
  &.BackgroundColorGrey
    select
      background-color $color_grey_lightest
  &.BackgroundColorWhite
    select
      background-color #fff
</style>
