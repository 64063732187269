import Type from '@/globals/javascript/models/Type'
import InterConnection from '@/globals/javascript/models/InterConnection'
import { firebase, db } from '@/firebase/init'
import {
  DB_INTER_CONNECTIONS, DB_REPORTS, DB_SCREENINGS, DB_TYPES, setIDOnCreate,
} from '@/globals/javascript/models/_helper'

export const types = {
  getScreeningTypes: ({ commit, getters }, screeningID) => {
    if (getters.currentScreeningTypesLoaded) {
      return
    }

    const unsubscribe = db.collection(DB_SCREENINGS)
      .doc(screeningID).collection(DB_TYPES)
      .orderBy('createdAt', 'asc')
      .onSnapshot((querySnapshot) => {
        const types = []
        querySnapshot.forEach((doc) => {
          types.push(new Type({
            ...doc.data(),
            id: doc.id,
          }))
        })

        commit('updateCurrentScreeningTypes', { types, unsubscribe })
      })
  },
  setScreeningType: ({ getters, dispatch }, { type, isNew = false }) => {
    dispatch('updateScreening', { setAsStarted: true, setAsNotCompleted: true })

    db
      .collection(DB_SCREENINGS)
      .doc(getters.currentScreening.id)
      .collection(DB_TYPES)
      .doc(type.id)
      .set({
        ...type,
      })

    // Report other type if any
    if (isNew && type.type.isOtherType) {
      db.collection(DB_REPORTS).doc('--general-reports--').update({
        OtherTypes: firebase.firestore.FieldValue.arrayUnion({
          name: type.type.otherType,
          sampleIDs: type.samples.sampleIDs,
          categoryID: type.categoryID,
          typeID: type.id,
          screeningID: getters.currentScreening.id,
          wdgID: type.type.otherTypeWDGID,
        }),
      })
    }

    // Report other places
    if (isNew && type.places.otherPlaces.length) {
      type.places.otherPlaces.forEach((place) => {
        db.collection(DB_REPORTS).doc('--general-reports--').update({
          OtherPlaces: firebase.firestore.FieldValue.arrayUnion({
            name: place,
            categoryID: type.categoryID,
            typeID: type.id,
            screeningID: getters.currentScreening.id,
          }),
        })
      })
    }
  },
  deleteAddedType: ({ getters, dispatch }, typeID) => {
    dispatch('updateScreening', { setAsStarted: true, setAsNotCompleted: true })

    const {
      screeningInterConnections,
      screeningSamples,
    } = getters

    // Delete type inter connections and reassign group ID if a group is split in two
    // 1. Find all inter connections used by type to be deleted
    const interConnections = screeningInterConnections.filter(
      (x) => x.innerTypeID === typeID || x.outerTypeID === typeID,
    )

    // 2. Give relevant outer groups a new ID
    interConnections.forEach((interConnection) => {
      if (interConnection.innerTypeID !== typeID) {
        return
      }

      const innerInterConnections = InterConnection.getGroupInterConnectionsFromTypeID({
        typeID,
        interConnectionGroupID: interConnection.groupID,
        side: 'inner',
      })
      const outerInterConnections = InterConnection.getGroupInterConnectionsFromTypeID({
        typeID: interConnection.outerTypeID,
        interConnectionGroupID: interConnection.groupID,
        side: 'outer',
      })

      if (innerInterConnections.length > 1 && outerInterConnections.length) {
        const newGroupID = setIDOnCreate(null, DB_INTER_CONNECTIONS)
        outerInterConnections.forEach((outerInterConnection) => {
          const updatedInterConnection = new InterConnection({
            ...outerInterConnection,
            groupID: newGroupID,
          })
          dispatch('setScreeningInterConnection', updatedInterConnection)
        })
      }
    })

    // 3. Delete inter connections
    interConnections.forEach((interConnection) => {
      dispatch('deleteInterConnection', interConnection.id)
    })

    // Delete type samples
    const samples = screeningSamples.filter((x) => x.typeID === typeID)
    samples.forEach((sample) => {
      dispatch('deleteScreeningSample', sample)
    })

    // Delete type
    db.collection(DB_SCREENINGS)
      .doc(getters.currentScreening.id)
      .collection(DB_TYPES)
      .doc(typeID)
      .delete()
  },
  handleAutoTypes: ({ dispatch }, { mainType, autoTypes }) => {
    autoTypes.forEach((autoType) => {
      // Add/update auto type
      if (autoType.answer === 'yes') {
        // Set type data
        autoType.type.unitIDs = mainType.unitIDs
        autoType.type.categoryID = mainType.categoryID
        autoType.type.places = mainType.places
        autoType.type.amount = mainType.amount

        dispatch('setScreeningType', {
          type: autoType.type,
          isNew: false,
        })
      }

      // Delete auto type
      if (autoType.answer === 'no' && autoType.type.id) {
        dispatch('deleteAddedType', autoType.type.id)
      }
    })
  },
}
