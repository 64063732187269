import { sortBy } from 'lodash-es'
import { Building } from '@/globals/javascript/models/units/Building'
import { Apartment } from '@/globals/javascript/models/units/Apartment'
import { Floor } from '@/globals/javascript/models/units/Floor'
import { mixWB } from '@/globals/javascript/_util/mixins'
import Sample from '@/globals/javascript/models/Sample'

export const getters = {
  // Loaded getters
  allScreeningsLoaded: (state) => !!state.unsubscribeAllScreenings,
  addressImagesLoaded: (state) => !!state.unsubscribeAddressImages,
  currentScreeningLoaded: (state) => !!state.unsubscribeScreening,
  currentScreeningMetaDataLoaded: (state) => !!state.unsubscribeMetaData,
  currentScreeningSamplesLoaded: (state) => !!state.unsubscribeSamples,
  currentScreeningBuildingsLoaded: (state) => !!state.unsubscribeBuildings,
  currentScreeningUnitsLoaded: (state) => !!state.unsubscribeUnits,
  currentScreeningPCBScreeningLoaded: (state) => !!state.unsubscribePCBScreenings,
  currentScreeningTypesLoaded: (state) => !!state.unsubscribeTypes,
  currentScreeningInterConnectionsLoaded: (state) => !!state.unsubscribeInterConnections,
  currentScreeningTestResultsLoaded: (state) => !!state.unsubscribeTestResults,
  currentScreeningWasteItemsLoaded: (state) => !!state.unsubscribeWasteItems,
  relatedScreeningsLoaded: (state, getters) => {
    if (!getters.currentScreeningLoaded) {
      return false
    }
    if (!getters.currentScreeningData.enterpriseID) {
      return true
    }
    return !!state.unsubscribeRelatedScreenings
  },

  // Other getters
  currentScreening: (state) => state.currentScreening,
  currentScreeningData: (state) => state.currentScreening.data,
  currentScreeningTenderDetails: (state) => {
    if (!state.currentScreening.data || !state.currentScreening.data.tenderDetails) {
      return false
    }
    return state.currentScreening.data.tenderDetails
  },
  skippedCategoryIDs: (state) => {
    if (!state.currentScreening || !state.currentScreening.data) {
      return []
    }
    return state.currentScreening.data.skippedCategoryIDs || []
  },
  skippedCategories: (state, getters) => {
    const {
      currentScreeningData,
      selectedUnits,
    } = getters

    const general = currentScreeningData.skippedCategories
      && currentScreeningData.skippedCategories.general
      ? currentScreeningData.skippedCategories.general
      : []

    return selectedUnits.reduce((prev, unit) => {
      prev[unit.id] = currentScreeningData.skippedCategories
      && currentScreeningData.skippedCategories[unit.id]
        ? currentScreeningData.skippedCategories[unit.id]
        : []
      return prev
    }, {
      general,
    })
  },
  allScreenings: (state) => state.allScreenings,
  metaData: (state) => state.currentScreening.metaData || {},
  projectType: (state) => {
    if (state.currentScreening.data) {
      return state.currentScreening.data.projectType
    }
    return null
  },
  buildings: (state) => {
    if (!state.currentScreening.buildings) {
      return []
    }
    return state.currentScreening.buildings
  },
  noOfBuildings: (state) => {
    const { data } = state.currentScreening
    if (data && data.selectedBuildings && data.selectedBuildings.count) {
      return data.selectedBuildings.count
    }
    return null
  },
  selectedBuildingIDs: (state) => {
    const { data } = state.currentScreening

    if (data && data.selectedBuildings && data.selectedBuildings.ids) {
      return data.selectedBuildings.ids
    }

    return []
  },
  selectedBuildings: (state, getters) => sortBy(getters.buildings.filter(
    (x) => getters.selectedBuildingIDs.includes(x.buildingID),
  ), ['data.Bygningsnr']),
  combinedListOfUnits: (state, getters) => getters.screeningUnits.buildings.concat(
    getters.screeningUnits.floors,
    getters.screeningUnits.apartments,
  ),
  noOfSelectedUnits: (state) => {
    if (!state.currentScreening.data.selectedUnits) {
      return 0
    }
    return state.currentScreening.data.selectedUnits.count
  },
  selectedUnitIDs: (state) => {
    const { data } = state.currentScreening
    if (data.selectedUnits && data.selectedUnits.ids) {
      return data.selectedUnits.ids
    }
    return []
  },
  selectedUnits: (state, getters) => {
    const list = getters.combinedListOfUnits.filter(
      (x) => getters.selectedUnitIDs.includes(x.id),
    )

    return sortBy(list, ['typeSorting', 'data.buildingID', 'data.numericFloorValue'])
  },
  screeningUnits: (state) => {
    if (!state.currentScreening.units) {
      return { buildings: [], floors: [], apartments: [] }
    }
    const units = state.currentScreening.units.reduce((prev, unit) => {
      if (unit.type === 'building') {
        const building = new Building({
          ...unit,
        })
        prev.buildings.push(building)
      }
      if (unit.type === 'apartment') {
        const apartment = new Apartment({
          ...unit,
        })
        prev.apartments.push(apartment)
      }
      if (unit.type === 'floor') {
        const floor = new Floor({
          ...unit,
        })
        prev.floors.push(floor)
      }
      return prev
    }, { buildings: [], floors: [], apartments: [] })

    units.buildings = sortBy(units.buildings, ['data.sortingNumber'])
    units.floors = sortBy(units.floors, ['data.numericFloorValue'])
    units.apartments = sortBy(units.apartments, ['data.numericFloorValue', 'bbrData.dør'])

    return units
  },
  currentScreeningSelectedFilterUnitID: (state) => state.currentScreeningSelectedFilterUnitID,
  builderFullName: (state) => {
    if (state.currentScreening.data) return state.currentScreening.data.builderFullName
    return null
  },
  screeningTypes: (state) => state.currentScreening.types,
  typesWithSamples: (state, getters) => {
    const { screeningTypes } = getters

    if (!screeningTypes || !screeningTypes.length) {
      return []
    }

    return screeningTypes.filter((type) => {
      if (type.resale === 'yes') {
        return false
      }
      if (
        !type.samples.sampleIDs.length
        && !type.coating.samples.sampleIDs.length
        && !type.underlining.samples.sampleIDs.length
        && !type.assessments.assessmentIDs.length
      ) {
        return false
      }

      return type
    })
  },
  screeningInterConnections: (state) => state.currentScreening.interConnections || [],
  addressImages: (state) => state.currentScreening.addressImages,
  screeningSamples: (state) => {
    if (!state.currentScreening.samples) {
      return []
    }
    return state.currentScreening.samples.map((sample) => new Sample({
      ...sample,
    }))
  },
  screeningSamplesAsObject: (state, getters) => {
    if (!getters.screeningSamples) {
      return {}
    }

    return getters.screeningSamples.reduce((prev, sample) => {
      prev[sample.id] = sample
      return prev
    }, {})
  },
  screeningPCBScreenings: (state) => state.currentScreening.pcbScreenings,
  screeningTestResults: (state) => {
    if (
      !state.currentScreening.testResults
      || !state.currentScreening.testResults.testResults
    ) {
      return null
    }

    return state.currentScreening.testResults.testResults
  },
  screeningWasteItems: (state) => state.currentScreening.wasteItems,
  screeningRelatedScreenings: (state) => state.currentScreening.relatedScreenings,
  isFirstStepDone: (state, getters) => {
    if (!getters.noOfBuildings && !getters.noOfSelectedUnits) {
      return false
    }

    if (!getters.projectType) {
      return false
    }

    return true
  },
  caseName: (state, getters) => {
    const { currentScreeningData } = getters

    if (
      !currentScreeningData
      || !currentScreeningData.address
      || !currentScreeningData.address.address
    ) {
      return ''
    }

    return `${
      currentScreeningData.address.address
    }, ${
      currentScreeningData.address.postalCode
    } ${
      currentScreeningData.address.city
    }`
  },
  caseNumber: (state, getters) => {
    if (
      !state.currentScreening.data
      || !state.currentScreening.data.screeningNumber
    ) {
      return false
    }

    return `${
      getters.currentAccount.companyInitials
    }-${
      state.currentScreening.data.screeningYear || 2020
    }-${
      state.currentScreening.data.screeningNumber
    }`
  },
  personalSamplingID: (state, getters) => {
    if (
      !state.currentScreening.data
      || !state.currentScreening.data.samplingID
    ) {
      return false
    }

    const user = getters.allUsers.find((x) => x.id === state.currentScreening.data.userID)
    return `${
      getters.currentAccount.companyInitials
    }-${
      user.initials
    }-${
      state.currentScreening.data.samplingID
    }`
  },
  isTestDataUploaded: (state) => {
    if (
      !state.currentScreening.data
    ) {
      return false
    }

    return !!state.currentScreening.data.isTestDataUploaded
  },
  imageUploadFolder: (state) => {
    if (
      !state.currentScreening
      || !state.currentScreening.data
      || !state.currentScreening.id
    ) {
      return false
    }

    if (
      state.currentScreening.data.screeningYear === 2021
       && state.currentScreening.data.screeningMonth < 7
    ) {
      return `Screenings-${
        state.currentScreening.data.screeningYear
      }-${
        state.currentScreening.data.screeningMonth
      }/${
        state.currentScreening.id
      }/`
    }

    return `AccountScreenings/${
      state.currentScreening.data.accountID
    }/${
      state.currentScreening.data.screeningYear
    }-${
      state.currentScreening.data.screeningMonth
    }/${
      state.currentScreening.id
    }/`
  },
  currentCategory: (state, getters) => {
    const { categories, currentRoute } = getters

    if (!currentRoute.params || !currentRoute.params.categoryID) {
      return false
    }

    if (!categories) {
      return false
    }

    return categories.find((x) => x.id === currentRoute.params.categoryID)
  },
  currentCategoryTitle: (state, getters) => {
    const { currentCategory } = getters

    if (!currentCategory) {
      return ''
    }
    return mixWB(currentCategory.wordbook)
  },
  addedTypesForCategory: (state, getters) => {
    const {
      screeningTypes,
      currentRoute,
      currentScreeningSelectedFilterUnitID,
    } = getters

    if (!currentRoute.params || !currentRoute.params.categoryID) {
      return []
    }

    if (!screeningTypes || !screeningTypes.length) {
      return []
    }

    if (currentScreeningSelectedFilterUnitID) {
      return screeningTypes.filter((x) => x.categoryID === currentRoute.params.categoryID
      && x.unitIDs.includes(currentScreeningSelectedFilterUnitID))
    }
    return screeningTypes.filter((x) => x.categoryID === currentRoute.params.categoryID)
  },
  nextAssessNumber: (state, getters) => {
    const { screeningSamples } = getters

    const assessNumbers = screeningSamples.reduce((prev, sample) => {
      if (sample.assessNumber) {
        prev.push(sample.assessNumber)
      }
      return prev
    }, []).sort((a, b) => a - b)

    if (!assessNumbers.length) {
      return 1
    }

    let nextNumber = null
    assessNumbers.forEach((number, index) => {
      if (nextNumber) {
        return
      }
      if (number !== index + 1) {
        nextNumber = index + 1
      }
    })

    return nextNumber || assessNumbers[assessNumbers.length - 1] + 1
  },
  currentScreeningTestUploadStatus: (state, getters) => {
    const { screeningSamples, screeningTestResults } = getters

    const data = {
      samples: 0,
      done: 0,
    }

    const samples = screeningSamples.filter((x) => !!x.sampleNumber)
    data.samples = samples.length
    data.done = samples.reduce((prev, sample) => {
      if (!screeningTestResults) {
        return prev
      }

      const test = screeningTestResults.find((x) => x.sampleNumber === sample.sampleNumber)

      if (test) {
        prev += 1
      }

      return prev
    }, 0)

    return data
  },
  currentEnterpriseID: (state) => state.currentScreening.currentEnterpriseID,
}
