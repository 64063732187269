import { mixWB } from '@/globals/javascript/_util/mixins'

export const nextStep = {
  state: {},
  mutations: {},
  actions: {},
  getters: {
    nextStep(state, getters) {
      const profileStatus = getters.profileImageStatus

      const pcbStatus = getters.pcbScreeningStatus

      const {
        currentScreening,
        categoryStatus,
        samplesStatus,
        floorPlanStatus,
        currentScreeningSelectedFilterUnitID,
        currentRoute,
      } = getters

      const nextStep = {
        text: '',
        action: '',
        path: '',
      }

      // Check data is loaded
      if (!currentScreening || !currentScreening.data) {
        return nextStep
      }

      // Completed screening
      if (currentScreening.data.isCompleted) {
        nextStep.text = mixWB('GO_TO_COMPLETED_SCREENINGS')
        nextStep.action = 'go-to-completed'
        nextStep.path = { name: 'AllScreenings', query: { status: 'completed' } }
        return nextStep
      }

      // Floor plans
      if (!floorPlanStatus.isActiveStep && !floorPlanStatus.isCompleted) {
        nextStep.text = `${
          mixWB('FLOOR_PLANS') } (${ floorPlanStatus.done }/${ floorPlanStatus.total
        })`
        nextStep.action = 'floor-plans'
        nextStep.path = {
          name: 'ScreeningsUpdateFloorPlans',
          params: {
            screeningID: currentScreening.id,
          },
        }
        return nextStep
      }

      // Profile images
      if (!profileStatus.isActiveStep && !profileStatus.isCompleted) {
        nextStep.text = `${
          mixWB('PROFILE_IMAGES') } (${ profileStatus.done }/${ profileStatus.total
        })`
        nextStep.action = 'profile-images'
        nextStep.path = {
          name: 'ScreeningsUpdateProfileImages',
          params: {
            screeningID: currentScreening.id,
          },
        }
        return nextStep
      }

      // Categories
      if (!categoryStatus.isActiveStep && !categoryStatus.isCompleted) {
        const nextCategoryItem = getters.categories.find(
          (x) => x.id === categoryStatus.nextCategoryID,
        )

        nextStep.text = mixWB(nextCategoryItem.wordbook)
        nextStep.action = 'category'
        nextStep.path = categoryStatus.path
        return nextStep
      }

      // PCB screenings
      if (!pcbStatus.isActiveStep && !pcbStatus.isCompleted) {
        nextStep.text = `${
          mixWB('PCB_SCREENINGS') } (${ pcbStatus.done }/${ pcbStatus.total
        })`
        nextStep.action = 'pcb-screenings'
        nextStep.path = {
          name: 'ScreeningsUpdatePSCScreeningSchema',
          params: {
            screeningID: currentScreening.id,
          },
        }
        return nextStep
      }

      // Samples
      if (!samplesStatus.isActiveStep && !samplesStatus.isCompleted) {
        nextStep.text = `${
          mixWB('SAMPLING') } (${ samplesStatus.done }/${ samplesStatus.total
        })`
        nextStep.action = 'samples'
        nextStep.path = {
          name: 'ScreeningsUpdateSamples',
          params: {
            screeningID: currentScreening.id,
          },
        }
        return nextStep
      }

      // Complete single unit
      if (currentScreeningSelectedFilterUnitID) {
        nextStep.text = mixWB('COMPLETE_UNIT')
        nextStep.action = 'complete-unit'
        nextStep.path = { name: 'ScreeningsUpdate' }
        return nextStep
      }

      // Go back to main page
      if (currentRoute.name !== 'ScreeningsUpdate') {
        nextStep.text = mixWB('COMPLETE')
        nextStep.action = ''
        nextStep.path = { name: 'ScreeningsUpdate' }
        return nextStep
      }

      // Complete screening
      nextStep.text = mixWB('COMPLETE_SCREENING')
      nextStep.action = 'complete-screening'
      nextStep.path = { name: 'AllScreenings', query: { status: 'completed' } }
      return nextStep
    },
  },
}
