<template>
  <div class="UploadStatusList">
    <!-- Header -->
    <div
      class="Header"
      @click="onToggleBody">
      <div
        :class="{ 'Open': isOpen }"
        class="Arrow">
        <AngleRightIcon />
      </div>
      <span class="Title">{{ mixWB('UPLOADS') }}</span>
      <div class="Numbers">
        <div class="Uploading">
          <UploadIcon />
          <span>{{ uploadNumbers.uploading }}</span>
        </div>
        <div class="Success">
          <CheckIcon />
          <span>{{ uploadNumbers.success }}</span>
        </div>
        <div class="Failed">
          <WarningIcon />
          <span>{{ uploadNumbers.failed }}</span>
        </div>
      </div>
      <div
        class="Close"
        @click.stop="onCloseClick">
        <CloseIcon />
      </div>
    </div>

    <!-- Body -->
    <div
      ref="body"
      :class="bodyClasses"
      class="Body"
      :style="{
        height: bodyHeight !== null ? bodyHeight + 'px' : '',
      }">
      <div
        v-if="!imageUploadList.length"
        class="NoItems">
        <span>{{ mixWB('NO_CURRENT_UPLOADS') }}</span>
      </div>
      <div v-else>
        <!-- Buttons -->
        <div class="ClearWrap">
          <span @click="onClearList">{{ mixWB('CLEAR_LIST') }}</span>
        </div>
        <!-- Item list -->
        <div class="ItemList">
          <div
            class="ItemWrap"
            v-for="item in imageUploadList"
            :key="item.path">
            <UploadStatusListItem :item="item" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UploadStatusListItem from '@/components/UploadStatusListItem'
import AngleRightIcon from '@/assets/svg/angle-right.svg?inline'
import UploadIcon from '@/assets/svg/upload.svg?inline'
import CheckIcon from '@/assets/svg/check.svg?inline'
import WarningIcon from '@/assets/svg/warning.svg?inline'
import CloseIcon from '@/assets/svg/close.svg?inline'
import { mapGetters } from 'vuex'
import EventBus from '@/EventBus'

export default {
  name: 'UploadStatusList',
  data() {
    return {
      isOpen: false,
      bodyHeight: 0,
    }
  },
  computed: {
    ...mapGetters([
      'imageUploadList',
    ]),
    bodyClasses() {
      return {
        Open: this.isOpen,
        PaddingStandard: this.padding === 'standard',
        PaddingNone: this.padding === 'none',
      }
    },
    uploadNumbers() {
      const data = {
        uploading: 0,
        success: 0,
        failed: 0,
      }
      if (!this.imageUploadList) {
        return data
      }

      // eslint-disable-next-line no-unused-vars
      const states = this.imageUploadList.forEach((item) => {
        if (item.status === 'initial') data.uploading += 1
        if (item.status === 'uploading') data.uploading += 1
        if (item.status === 'success') data.success += 1
        if (item.status === 'failed') data.failed += 1
      })

      return data
    },
  },
  methods: {
    onClearList() {
      this.$store.dispatch('clearUploadList')
    },
    onToggleBody() {
      if (this.isOpen) {
        this.hideBody()
        return
      }

      this.openBody()
    },
    hideBody() {
      if (!this.$refs.body) {
        return
      }

      // Get height
      const { height } = this.$refs.body.getBoundingClientRect()

      // Set start height
      this.bodyHeight = height

      // Set end height
      requestAnimationFrame(() => {
        this.bodyHeight = 0
      })

      this.isOpen = !this.isOpen
    },
    openBody() {
      if (this.isOpen) {
        return
      }

      // Get height
      this.bodyHeight = null
      requestAnimationFrame(() => {
        const height = 300

        // Set start height
        this.bodyHeight = 0

        requestAnimationFrame(() => {
          // Set end height
          this.bodyHeight = height
        })
      })

      this.isOpen = !this.isOpen
    },
    onCloseClick() {
      this.$store.dispatch('toggleShowUploadStatusList')
    },
  },
  components: {
    AngleRightIcon,
    UploadIcon,
    CheckIcon,
    WarningIcon,
    CloseIcon,
    UploadStatusListItem,
  },
  created() {
    EventBus.$on('expand-upload-list', this.openBody)
  },
  destroyed() {
    EventBus.$off('expand-upload-list', this.openBody)
  },
}
</script>

<style lang="stylus" scoped>
  .UploadStatusList
    position fixed
    bottom -1px
    left 0
    width 100%
    box-shadow 0px 0px 4px rgba(0,0,0,0.6)
    background-color $color_purple_lightestest
    z-index $z_upload_list

  .Header
    display flex
    align-items center
    height 44px
    background-color $color_purple_lightest
    .Title
      color $color_purple_darkest
      font-size 1.125rem
      flex-grow 2
    .Arrow
      box(44px)
      padding 13px
      svg
        transform rotate(-90deg)
        fill $color_purple_darkest
      &.Open
        svg
          transform rotate(90deg)
    .Numbers
      flex-shrink 0
      display flex
      align-items center
      margin-right 10px
      color $color_purple_darkest
      font-size 1.125rem
      div
        display flex
        align-items center
        margin-right 10px
        &:last-child
          margin-right 0
        svg
          box(20px)
          margin-right 3px
    .Close
      box(40px)
      flex-center-children()
      svg
        box(15px)
  .Body
    overflow auto
    transition all .2s ease
    opacity 0
    border-top 1px solid $color_purple_light
    &.Open
      opacity 1
    .NoItems
      padding 15px
      font-style italic
    .ClearWrap
      display flex
      align-items center
      justify-content flex-end
      span
        padding 12px 15px
        font-size 0.875rem
        color $color_purple_darkest
        text-decoration underline
    .ItemWrap
      border-top 1px solid $color_purple_lightest

  @media print
    .UploadStatusList
      display none
</style>
