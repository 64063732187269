<template>
  <div>
    <!-- Backdrop -->
    <BackDrop
      @back-drop-click="onBackdropClick"
      :is-showing="isShowing" />

    <!-- Sidepane -->
    <transition name="fade">
      <div
        v-if="isShowing"
        class="DialogWrap"
        @click="onBackdropClick">
        <div
          ref="Dialog"
          class="Dialog"
          :class="dialogClasses"
          @click.stop>
          <components
            :is="useComponent"
            v-bind="{ ...componentProps }"
            @check-height="onCheckHeight"
            @close-dialog="onCloseDialog"
            @pass-to-parent="onPassToParent" />

          <!-- Close icon -->
          <div
            class="CloseWrap"
            @click="onCloseDialog">
            <CloseIcon />
          </div>
        </div>
      </div>
    </transition>

  </div>
</template>

<script>
import BackDrop from '@/components/BackDrop.vue'
import CloseIcon from '@/assets/svg/close.svg?inline'
import { enableBodyScroll, preventBodyScroll } from '@/globals/javascript/_util/util'

export default {
  name: 'Dialog',
  props: {
    useComponent: {
      type: Object,
      required: true,
    },
    componentProps: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    isShowing: {
      type: Boolean,
      required: true,
    },
    size: {
      type: String,
      required: false,
      default: 'small', // 'small' | 'medium' | 'fullScreen'
    },
    easyClose: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      isVeryTall: false,
    }
  },
  computed: {
    dialogClasses() {
      return {
        SizeSmall: this.size === 'small',
        SizeMedium: this.size === 'medium',
        FullScreen: this.size === 'fullScreen',
        IsVeryTall: this.isVeryTall,
      }
    },
  },
  watch: {
    isShowing() {
      if (this.isShowing) {
        preventBodyScroll(true)
        requestAnimationFrame(() => {
          this.onCheckHeight()
        })
      }
      else {
        enableBodyScroll()
      }
    },
  },
  methods: {
    onBackdropClick() {
      if (!this.easyClose) {
        return
      }

      this.onCloseDialog()
    },
    onCloseDialog() {
      this.$emit('close')
    },
    onPassToParent(event) {
      this.$emit(event.name, event.value)
    },
    onKeyDown(event) {
      if (event.keyCode === 27) {
        this.onCloseDialog()
      }
    },
    onCheckHeight() {
      this.isVeryTall = false
      const { height } = this.$refs.Dialog.getBoundingClientRect()

      if (this.size === 'fullScreen') {
        return
      }

      if (height > window.innerHeight - window.innerHeight * 0.3) {
        this.isVeryTall = true
      }
    },
  },
  components: {
    BackDrop,
    CloseIcon,
  },
  created() {
    window.addEventListener('keydown', this.onKeyDown)
  },
  destroyed() {
    window.removeEventListener('keydown', this.onKeyDown)
    enableBodyScroll()
  },
}
</script>

<style lang="stylus" scoped>
  .DialogWrap
    position fixed
    top 0
    left 0
    box(100%)
    z-index $z_modal
    overflow auto
    smooth-scroll()
    overflow-y scroll

  .Dialog
    position relative
    width 100%
    max-width 90%
    margin 15vh auto
    padding 30px 30px 20px 30px
    background-color #fff
    box-shadow 0px 0px 20px rgba(0, 0, 0, 0.3);
    transition margin 0.15s ease-out
    +below($phablet)
      padding 20px 15px
    &.SizeSmall
      width 400px
    &.SizeMedium
      width 600px
    &.FullScreen
      max-height 100%
      max-width 100%
      height 100%
      width 100%
      margin 0
      padding 0
    &.IsVeryTall
      margin-top 5vh

  .CloseWrap
    position absolute
    top 0
    right 0
    z-index 1
    box(40px)
    padding 10px 10px 15px 15px
    cursor pointer
    svg
      fill $color_grey_light
</style>
