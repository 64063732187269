import Vue from 'vue'
import Vuex from 'vuex'
import { db } from '@/firebase/init'
import * as Sentry from '@sentry/browser'
import { mixCompareVersionsNewerOnly } from '@/globals/javascript/_util/mixins'
import { DB_STATS } from '@/globals/javascript/models/_helper'
import { screenings } from './screenings/index'
import { materialData } from './materialData'
import { basicDataStore } from './basicDataStore'
import { ImageUpload } from './ImageUpload'
import { statuses } from './statuses'
import { nextStep } from './nextStep'
import { testDataUpload } from './testDataUpload'
import { admin } from './admin'
import { account } from './account'
import { user } from './user'
import { globalMenu } from './globalMenu'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    packageVersion: process.env.PACKAGE_VERSION || '0',
    isAppInitialized: false,
    isPageLoading: true,
    isLoggedIn: false,
    stats: {},
    currentRoute: {},
    prevPages: [],

    // Snapshot listeners
    unsubscribeAllStats: null,
  },
  mutations: {
    setPageLoad: (state, newState) => {
      state.isPageLoading = newState
    },
    resetAllStats: (state) => {
      state.stats = {}
      if (state.unsubscribeAllStats) {
        state.unsubscribeAllStats()
        state.unsubscribeAllStats = null
      }
    },
    updateAppInitialized: (state, status) => {
      state.isAppInitialized = status
    },
    updateStats: (state, { data, unsubscribe }) => {
      state.stats = data.versions['milva-pro-v1']
      state.unsubscribeAllStats = unsubscribe
    },
    updateUserLoginState: (state, user) => {
      state.isLoggedIn = !!user
    },
    updateCurrentRoute: (state, newRoute) => {
      state.currentRoute = newRoute

      // Sentry
      Sentry.setTag('page-name', newRoute.name || null)
    },
    checkAndUpdatePageScroll: (state, newRoute) => {
      const oldPage = state.prevPages[1]

      // Check to scroll
      if (
        oldPage
        && oldPage.path === newRoute.path
        && oldPage.scrollPos !== null
        && !oldPage.path.includes('/add-type') // To avoid scrolling down when adding a new type
      ) {
        requestAnimationFrame(() => {
          window.scrollTo(0, oldPage.scrollPos)
        })
      }
      else if (state.prevPages.length) {
        requestAnimationFrame(() => {
          window.scrollTo(0, 0)
        })
      }

      // Set new prevPage
      const newPage = {
        path: newRoute.path,
        scrollPos: null,
      }

      state.prevPages.unshift(newPage)
      state.prevPages.splice(2)
    },
    updateScrollBeforeLeave: (state) => {
      if (!state.prevPages.length) {
        return
      }

      state.prevPages[0].scrollPos = window.pageYOffset
    },
  },
  actions: {
    setPageLoad: ({ commit }, newState) => {
      commit('setPageLoad', newState)
    },
    resetOnLogOut: ({ commit, dispatch }) => {
      commit('resetCurrentUser')
      commit('resetAllStats')
      commit('resetAllUsers')
      commit('resetAccount')
      commit('resetAccounts')
      dispatch('resetAllScreenings')
    },
    getAllStats: ({ state, commit }) => {
      if (state.unsubscribeAllStats) {
        return
      }

      const unsubscribe = db
        .collection(DB_STATS)
        .doc('--stats--')
        .onSnapshot((doc) => {
          commit('updateStats', { data: doc.data(), unsubscribe })
        })
    },
    updateAppInitialized: ({ commit }, status) => {
      commit('updateAppInitialized', status)
    },
    updateUserLoginState: ({ commit }, user) => {
      commit('updateUserLoginState', user)
    },
    updateCurrentRoute: ({ commit }, newRoute) => {
      commit('updateCurrentRoute', newRoute)
    },
    checkAndUpdatePageScroll: ({ commit }, newRoute) => {
      commit('checkAndUpdatePageScroll', newRoute)
    },
    updateScrollBeforeLeave: ({ commit }) => {
      commit('updateScrollBeforeLeave')
    },
    checkForNewAppVersion: ({ getters }) => new Promise((resolve) => {
      const waitForLoad = setInterval(() => {
        const { packageVersion } = getters
        const { firestoreAppVersion } = getters

        if (!packageVersion || !firestoreAppVersion) {
          return
        }

        clearInterval(waitForLoad)

        const isNewVersionAvailable = mixCompareVersionsNewerOnly(
          firestoreAppVersion,
          packageVersion,
        )

        resolve(isNewVersionAvailable)

        if (!isNewVersionAvailable) {
          return
        }

        // Get latest version
        // We need to put this in a timeout to ensure the page will not keep reloading
        setTimeout(() => {
          const locationArray = window.location.href.split('?')
          window.location.href = `${
            locationArray[0]
          }?version=${
            firestoreAppVersion
          }-${
            new Date().getTime()
          }`
        }, 500)
      }, 100)
    }),
  },
  getters: {
    // Loaders
    allStatsLoaded: (state) => !!state.unsubscribeAllStats,

    // Other
    packageVersion: (state) => state.packageVersion,
    firestoreAppVersion: (state) => {
      if (!state.stats || !state.stats.site) {
        return null
      }
      return state.stats.site
    },
    latestChangelogVersion: (state) => {
      if (!state.stats || !state.stats.changelog) {
        return null
      }
      return state.stats.changelog
    },
    isAppInitialized: (state) => state.isAppInitialized,
    isPageLoading: (state) => state.isPageLoading,
    isLoggedIn: (state) => state.isLoggedIn,
    currentRoute: (state) => state.currentRoute,
  },
  modules: {
    screenings,
    materialData,
    basicDataStore,
    ImageUpload,
    statuses,
    nextStep,
    testDataUpload,
    admin,
    account,
    user,
    globalMenu,
  },
})
