import { db } from '@/firebase/init'
import { DB_LANGUAGES, DB_TRANSLATIONS } from '@/globals/javascript/models/_helper'
import { keys } from '@/globals/javascript/_util/keys'

export const basicDataStore = {
  state: {
    selectedLanguage: null,
    languages: [],
    translations: {},
  },
  mutations: {
    updateLanguages: (state, languages) => {
      state.languages = languages

      // Set selected language
      const savedLocale = window.localStorage.getItem(keys.LS_SAVED_LOCALE)
      const language = languages.find((x) => x.locale === savedLocale)
      state.selectedLanguage = language || languages.find((x) => x.locale === 'da-DK')
      window.localStorage.setItem(keys.LS_SAVED_LOCALE, state.selectedLanguage.locale)
    },
    updateTranslations: (state, translations) => {
      state.translations = translations
    },
  },
  actions: {
    async getLanguages({ commit }) {
      const snapshot = await db.collection(DB_LANGUAGES).get()
      const languages = snapshot.docs.map((doc) => ({
        ...doc.data(),
      }))
      commit('updateLanguages', languages)
    },
    async getTranslations({ getters, commit }) {
      const snapshot = await db
        .collection(DB_TRANSLATIONS)
        .doc(getters.selectedLanguage.locale)
        .get()
      commit('updateTranslations', snapshot.data())
    },
  },
  getters: {
    // Loaders
    languagesLoaded: (state) => !!state.languages.length,
    translationsLoaded: (state) => !!Object.keys(state.translations).length,

    // Other
    selectedLanguage: (state) => state.selectedLanguage,
    languages: (state) => state.languages,
    translations: (state) => state.translations,
  },
}
