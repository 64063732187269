const ignoreList = [
  // 'backup',
]

export const customLog = (tag, text, ...props) => {
  if (process.env.NODE_ENV !== 'development') {
    return
  }

  if (ignoreList.includes(tag)) {
    return
  }

  window.console.log(`[${ tag }] ${ text }`, ...props)
}
