import * as setup from '@/globals/javascript/models/GroupedTypeResult/setup'
import store from '@/store'

export default class GroupedType {
  constructor({
    id,
    categoryID,
    groupID,
    index,
    categoryIndex,
    duplicateTypeIDs,
    previousGroupedTypes,
    nextGroupedTypes,
    types,
    groupedTypeResult,
    firstTypeID,
    lastTypeID,
    leftSideEffectDirection,
    rightSideEffectDirection,

  }) {
    this.id = id
    this.categoryID = categoryID
    this.groupID = groupID || null
    this.index = index ?? null
    this.categoryIndex = categoryIndex || null
    this.duplicateTypeIDs = duplicateTypeIDs || [] // Types that are used before
    this.previousGroupedTypes = previousGroupedTypes || []
    this.nextGroupedTypes = nextGroupedTypes || []
    this.types = types || []
    this.groupedTypeResult = groupedTypeResult || null
    this.firstTypeID = firstTypeID || null
    this.lastTypeID = lastTypeID || null
    this.leftSideEffectDirection = leftSideEffectDirection || ''
    this.rightSideEffectDirection = rightSideEffectDirection || ''
  }

  getContaminatedByAdjecentTypesStatus() {
    if (!this.previousGroupedTypes.length && !this.nextGroupedTypes.length) {
      return false
    }

    // Get WDC ID's from adjacent types
    let previousWDCID = false
    if (this.previousGroupedTypes.length === 1) {
      previousWDCID = setup.getPreviousWDCID({
        groupedType: this,
        previousGroupedTypeResults: this.previousGroupedTypes[0].groupedTypeResult,
      })
    }
    let nextWDCID = false
    if (this.nextGroupedTypes.length === 1) {
      nextWDCID = setup.getNextWDCID({
        groupedType: this,
        nextGroupedTypeResults: this.nextGroupedTypes[0].groupedTypeResult,
      })
    }

    if ((!previousWDCID || previousWDCID === 'WDC-1') && (!nextWDCID || nextWDCID === 'WDC-1')) {
      return false
    }

    // Update current WDC IDs
    const materialWDCID = setup.getCorrectMaterialWDCID.call(this.groupedTypeResult, {
      previousWDCID,
      nextWDCID,
    })
    const coatingWDCID = setup.getCorrectCoatingWDCID.call(this.groupedTypeResult, {
      nextWDCID,
    })

    // Get coating props
    const hasCoating = !!this.groupedTypeResult.coating.wdcID
    const needToBeCleaned = setup.getNeedToBeCleanedStatus.call(
      this.groupedTypeResult, { materialWDCID, coatingWDCID },
    )

    // Get scale values
    const groupedTypeFinalWDCID = setup.getGroupedTypeFinalWDCID.call(
      this.groupedTypeResult,
      { includeCoating: hasCoating && !needToBeCleaned },
    )
    const groupedTypeContaminationScaleValue = setup.getContaminationScaleValue(
      groupedTypeFinalWDCID,
    )
    const prevTypeContaminationScaleValue = setup.getContaminationScaleValue(
      previousWDCID || 'WDC-1',
    )
    const nextTypeContaminationScaleValue = setup.getContaminationScaleValue(
      nextWDCID || 'WDC-1',
    )
    const combinedWithPrevContaminationScaleValue = setup.getCombinedContaminationScaleValue([
      groupedTypeContaminationScaleValue,
      prevTypeContaminationScaleValue,
    ])
    const combinedWithNextContaminationScaleValue = setup.getCombinedContaminationScaleValue([
      groupedTypeContaminationScaleValue,
      nextTypeContaminationScaleValue,
    ])

    return combinedWithPrevContaminationScaleValue > groupedTypeContaminationScaleValue
      || combinedWithNextContaminationScaleValue > groupedTypeContaminationScaleValue
  }

  getGroupedTypeTitle(options = {}) {
    const {
      withDetails = false,
      withIndex = false,
      lastTypeOnly = false,
    } = options

    let title = ''
    this.types.forEach((type, index) => {
      if (lastTypeOnly && index !== this.types.length - 1) {
        return
      }

      if (title) {
        title += ' + '
      }

      if (!title) {
        title += type.getTitle({ details: withDetails })
      }
      else {
        title += type.getTitle({ details: withDetails }).toLowerCase()
      }
    })

    if (withIndex) {
      title = `${ this.categoryIndex }.${ this.index } ${ title }`
    }

    return title
  }

  getAmount() {
    const firstTypeWith = this.types.find(
      (x) => !x.amount.isSkipped && !this.duplicateTypeIDs.includes(x.id),
    )

    if (!firstTypeWith) {
      return false
    }

    return firstTypeWith.getAmountText()
  }

  getSamples() {
    const {
      screeningSamples,
    } = store.getters

    let samples = []
    this.types.forEach((type) => {
      const typeSamples = []
      screeningSamples.forEach((sample) => {
        if (sample.typeID === type.id) {
          if (sample.kindOfSample === 'material') {
            typeSamples.unshift(sample)
          }
          if (sample.kindOfSample === 'coating') {
            typeSamples.push(sample)
          }
          if (sample.kindOfSample === 'underlining') {
            typeSamples.push(sample)
          }
        }
      })
      samples = samples.concat(typeSamples)
    })
    return samples
  }
}
