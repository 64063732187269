import { DB_INTER_CONNECTIONS, setIDOnCreate } from '@/globals/javascript/models/_helper'
import store from '@/store'

export default class InterConnection {
  constructor({
    id = null,
    groupID = null,
    innerTypeID = null,
    outerTypeID = null,
    splitStatus = null, // 'stuck', 'splitable'
    effectDirection = null, // 'left' | 'right' | 'both' | 'none'
    isSplitStatusLocked = false,
    isCleanableConnection = false,
  }) {
    this.id = setIDOnCreate(id, DB_INTER_CONNECTIONS)
    this.groupID = setIDOnCreate(groupID, DB_INTER_CONNECTIONS)
    this.innerTypeID = innerTypeID
    this.outerTypeID = outerTypeID
    this.splitStatus = splitStatus
    this.effectDirection = effectDirection
    this.isSplitStatusLocked = isSplitStatusLocked
    this.isCleanableConnection = isCleanableConnection
  }

  static checkInterConnectionUnits(innerType, outerType) {
    const { selectedUnits } = store.getters
    const innerTypeAllowedUnitIDsAsObject = innerType.getAllowedUnitIDsAsObject(
      { noParent: true },
    )
    const outerTypeAllowedUnitIDsAsObject = outerType.getAllowedUnitIDsAsObject()
    let matchFound = false

    // Check apartments
    outerTypeAllowedUnitIDsAsObject.apartmentIDs.forEach((apartmentID) => {
      if (matchFound) {
        return
      }

      // Has same
      if (innerTypeAllowedUnitIDsAsObject.apartmentIDs.includes(apartmentID)) {
        matchFound = true
        return
      }

      const apartment = selectedUnits.find((x) => x.id === apartmentID)
      // Has floor
      if (innerTypeAllowedUnitIDsAsObject.floorIDs.includes(apartment.data.floorID)) {
        matchFound = true
        return
      }

      // Has building
      if (innerTypeAllowedUnitIDsAsObject.buildingIDs.includes(apartment.data.buildingID)) {
        matchFound = true
        return
      }
    })

    // Check floors
    outerTypeAllowedUnitIDsAsObject.floorIDs.forEach((floorID) => {
      if (matchFound) {
        return
      }
      // Has same
      if (innerTypeAllowedUnitIDsAsObject.floorIDs.includes(floorID)) {
        matchFound = true
        return
      }

      // Has building
      const floor = selectedUnits.find((x) => x.id === floorID)
      if (innerTypeAllowedUnitIDsAsObject.buildingIDs.includes(floor.data.buildingID)) {
        matchFound = true
        return
      }
    })

    // Check buildings
    outerTypeAllowedUnitIDsAsObject.buildingIDs.forEach((buildingID) => {
      if (matchFound) {
        return
      }

      // Has same
      if (innerTypeAllowedUnitIDsAsObject.buildingIDs.includes(buildingID)) {
        matchFound = true
        return
      }
    })

    return matchFound
  }

  static getGroupInterConnectionsFromTypeID({
    typeID,
    interConnectionGroupID,
    side,
    interConnections = false,
  }) {
    const allInterConnections = interConnections || store.getters.screeningInterConnections
    const interConnectionGroup = []

    if (side === 'inner') {
      let nextInterConnection = allInterConnections.find(
        (x) => x.outerTypeID === typeID
        && x.groupID === interConnectionGroupID,
      )
      while (nextInterConnection) {
        interConnectionGroup.push(nextInterConnection)
        nextInterConnection = allInterConnections.find(
          // eslint-disable-next-line no-loop-func
          (x) => x.outerTypeID === nextInterConnection.innerTypeID
          && x.groupID === interConnectionGroupID,
        )
      }
    }

    if (side === 'outer') {
      let nextInterConnection = allInterConnections.find(
        (x) => x.innerTypeID === typeID
        && x.groupID === interConnectionGroupID,
      )
      while (nextInterConnection) {
        interConnectionGroup.push(nextInterConnection)
        nextInterConnection = allInterConnections.find(
          // eslint-disable-next-line no-loop-func
          (x) => x.innerTypeID === nextInterConnection.outerTypeID
          && x.groupID === interConnectionGroupID,
        )
      }
    }
    return interConnectionGroup
  }
}
