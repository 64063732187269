<template>
  <div class="UploadStatusListItem">
    <span class="Title">{{ item.text }}</span>
    <div class="Status">
      <div
        v-if="item.status === 'initial' || item.status === 'uploading'"
        class="Uploading">
        {{ item.uploadedPercentage }}%
      </div>
      <div
        v-if="item.status === 'success'"
        class="Success">
        <CheckIcon />
      </div>
      <div
        v-if="item.status === 'failed'"
        class="Failed">
        <WarningIcon />
      </div>
    </div>
  </div>
</template>

<script>
import CheckIcon from '@/assets/svg/check.svg?inline'
import WarningIcon from '@/assets/svg/warning.svg?inline'

export default {
  name: 'UploadStatusListItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  components: {
    CheckIcon,
    WarningIcon,
  },
}
</script>

<style lang="stylus" scoped>
  .UploadStatusListItem
    display flex
    align-items center
    padding 10px 15px
    min-height 40px

  .Title
    truncated()
    flex-grow 2
    font-size 0.875rem

  .Status
    flex-shrink 0
    display flex
    justify-content flex-end
    padding-left 10px
    min-width 100px
    .Success
      box(20px)
      svg
        fill $color_primary
    .Failed
      box(20px)
      svg
        fill $color_salmon_dark

</style>
