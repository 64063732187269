import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'

import { screeningRoutes } from '@/router/screening'
import { resultRoutes } from '@/router/result'
import { accountRoutes } from '@/router/account'
import {
  waitForInitialized,
  checkAllIsLoaded,
  checkAllIsLoadedPromise,
  waitForCurrentUser,
  waitForLanguages,
} from '@/router/helper'
import moment from 'moment-with-locales-es6'
import EventBus from '@/EventBus'
import { accountChecklist, allScreeningsChecklist, singleScreeningChecklist } from './_checklists'

Vue.use(VueRouter)

const routes = [
  ...screeningRoutes,
  ...resultRoutes,
  ...accountRoutes,

  // General
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/login',
    name: 'SignIn',
    meta: {
      requiresGuest: true,
    },
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    meta: {
      requiresAuth: true,
      checklist: [
        ...accountChecklist,
      ],
    },
    component: () => import('../views/ChangePassword.vue'),
  },
  {
    path: '/log-out',
    name: 'LogOut',
    meta: {
      requiresAuth: true,
    },
    component: () => import('../views/LogOut.vue'),
  },

  // Requisition
  {
    path: '/requisition/:screeningID',
    name: 'Requisition',
    meta: {
      requiresAuth: true,
      requiresScreeningID: true,
      checklist: [
        ...singleScreeningChecklist,
      ],
    },
    component: () => import('../views/Requisition/Requisition.vue'),
  },

  // Report
  {
    path: '/report/:screeningID',
    name: 'Report',
    meta: {
      siteArea: 'report',
      requiresAuth: true,
      requiresScreeningID: true,
      checklist: [
        ...singleScreeningChecklist,
      ],
    },
    component: () => import('../views/Report/Report.vue'),
  },
  {
    path: '/report-pre-1.14.0/:screeningID',
    name: 'ReportPre1.14.0',
    meta: {
      requiresAuth: true,
      requiresScreeningID: true,
      checklist: [
        ...singleScreeningChecklist,
      ],
    },
    component: () => import('../views/Report/ReportPre1.14.0.vue'),
  },

  // Tender
  {
    path: '/tender/:screeningID',
    name: 'Tender',
    meta: {
      siteArea: 'tender',
      requiresAuth: true,
      requiresScreeningID: true,
      checklist: [
        ...singleScreeningChecklist,
      ],
    },
    component: () => import('../views/Tender/Tender.vue'),
  },
  {
    path: '/tender-pre-1.14.0/:screeningID',
    name: 'TenderPre1.14.0',
    meta: {
      siteArea: 'tender',
      requiresAuth: true,
      requiresScreeningID: true,
      checklist: [
        ...singleScreeningChecklist,
      ],
    },
    component: () => import('../views/Tender/TenderPre1.14.0.vue'),
  },

  // Admin
  {
    path: '/admin/changelog-overview',
    name: 'ChangelogOverview',
    meta: {
      requiresAuth: true,
      checklist: [
        ...allScreeningsChecklist,
      ],
    },
    component: () => import('../views/Changelog/ChangelogOverview.vue'),
  },
]

const router = new VueRouter({
  routes,
  mode: 'history',
  base: '',
})

// Checks and sets
router.beforeEach(async (to, from, next) => {
  // Check for newer version
  const isNewVersionAvailable = await store.dispatch('checkForNewAppVersion')

  if (!isNewVersionAvailable) {
    next()
  }
})

// Check logged in or not
router.beforeEach((to, from, next) => {
  const waitForLoad = setInterval(() => {
    const { isAppInitialized } = store.getters

    if (!isAppInitialized) return

    clearInterval(waitForLoad)
    const { isLoggedIn } = store.getters

    // Make sure user is logged in
    if (!isLoggedIn && to.meta.requiresAuth) {
      router.push({ name: 'SignIn' })
      return
    }

    // Make sure user is not logged in
    if (isLoggedIn && to.meta.requiresGuest) {
      router.push({ name: 'AllScreenings' })
      return
    }

    // Set scroll position
    store.dispatch('updateScrollBeforeLeave')

    next()
  }, 50)
})

// Check for requirements
router.beforeEach((to, from, next) => {
  // Makes sure that screening ID is set
  if (to.meta.requiresScreeningID) {
    // Check for ID in URL
    if (!to.params.screeningID) {
      router.push({ name: 'AllScreenings' })
      return
    }
  }

  // Make sure that category ID is set
  if (to.meta.requiresCategoryID) {
    // Check for ID in URL
    if (!to.params.categoryID) {
      router.push({ name: 'ScreeningsUpdate', params: { screeningID: to.params.screeningID } })
      return
    }
  }

  next()
})

// Do checklist
router.beforeEach(async (to, from, next) => {
  await waitForInitialized(store)

  const checklist = to.meta.checklist || []

  // Add check that are always required
  checklist.push('languagesLoaded', 'translationsLoaded')

  if (checklist.length) {
    if (checklist.includes('languagesLoaded')) {
      store.dispatch('getLanguages')
    }
    if (checklist.includes('translationsLoaded')) {
      await waitForLanguages(store)
      store.dispatch('getTranslations')
    }
    if (checklist.includes('accountLoaded')) {
      await waitForCurrentUser(store)
      store.dispatch('getAccount')
    }
    if (checklist.includes('allUsersLoaded')) {
      store.dispatch('getAllUsers')
    }
    if (checklist.includes('allScreeningsLoaded')) {
      store.dispatch('getAllScreenings')
    }
    if (checklist.includes('currentScreeningLoaded')) {
      store.dispatch('getCurrentScreening', to.params.screeningID)
    }
    if (checklist.includes('currentScreeningMetaDataLoaded')) {
      store.dispatch('getScreeningMetaData', to.params.screeningID)
    }
    if (checklist.includes('currentScreeningTypesLoaded')) {
      store.dispatch('getScreeningTypes', to.params.screeningID)
    }
    if (checklist.includes('currentScreeningInterConnectionsLoaded')) {
      store.dispatch('getScreeningInterconnections', to.params.screeningID)
    }
    if (checklist.includes('currentScreeningSamplesLoaded')) {
      store.dispatch('getScreeningSamples', to.params.screeningID)
    }
    if (checklist.includes('currentScreeningBuildingsLoaded')) {
      store.dispatch('getScreeningBuildings', to.params.screeningID)
    }
    if (checklist.includes('currentScreeningUnitsLoaded')) {
      store.dispatch('getScreeningUnits', to.params.screeningID)
    }
    if (checklist.includes('currentScreeningPCBScreeningLoaded')) {
      store.dispatch('getScreeningPCBScreenings', to.params.screeningID)
    }
    if (checklist.includes('addressImagesLoaded')) {
      store.dispatch('getScreeningAddressImages', to.params.screeningID)
    }
    if (checklist.includes('currentScreeningTestResultsLoaded')) {
      store.dispatch('getCurrentScreeningTestResults', to.params.screeningID)
    }
    if (checklist.includes('currentScreeningWasteItemsLoaded')) {
      store.dispatch('getCurrentScreeningWasteItems', to.params.screeningID)
    }
  }

  const answer = checkAllIsLoaded({ store, checklist })
  if (answer) {
    store.dispatch('setPageLoad', false)
    next()
    return
  }

  // Wait until all requirements are loaded
  store.dispatch('setPageLoad', true)
  await checkAllIsLoadedPromise({ store, checklist })

  if (to.meta.requiresActiveAccount) {
    const { isActive } = store.getters.currentAccount
    if (!isActive) {
      router.push({ name: 'AllScreenings' })
      return
    }
  }

  if (to.meta.requiresScreeningID) {
    // Make sure we are allowed to view this screening
    const accountID = store.getters.currentAccount.id
    const screeningAccountId = store.getters.currentScreening.data.accountID

    if (screeningAccountId && screeningAccountId !== accountID) {
      router.push({ name: 'AllScreenings' })
      return
    }
  }

  next()
})

router.beforeEach((to, from, next) => {
  const user = store.getters.currentUser
  if (to.meta.requiresSuperUser) {
    if (!user.isSuperUser && !user.isAdmin) {
      router.push({ name: 'AllScreenings' })
      return
    }
  }

  if (from.name !== 'ChangePassword' && to.name !== 'ChangePassword' && user?.mustUpdatePassword) {
    router.push({ name: 'ChangePassword' })
    return
  }

  next()
})

router.afterEach((to) => {
  store.dispatch('setPageLoad', false)

  // Check to show changelog
  if (to.meta.requiresAuth && !['LogOut', 'ChangePassword'].includes(to.name)) {
    requestAnimationFrame(() => {
      EventBus.$emit('check-to-show-changelog')
    })
  }

  // Update user last seen timestamp
  const user = store.getters.currentUser
  const thirtyMintuesAgo = moment().subtract(30, 'minutes')
  if (user && (!user.lastSeen || moment(user.lastSeen.toDate()) < thirtyMintuesAgo)) {
    store.dispatch('updateUserLastSeen')
  }
})

export default router
