/* eslint-disable no-restricted-properties */
import Unit from '@/globals/javascript/models/units/Unit'
import { mixWB } from '@/globals/javascript/_util/mixins'
import ownerAssociations from '@/globals/json/owner-associations.json'
import buildingTypeCodes from '@/globals/json/building-type-codes.json'

export class Building extends Unit {
  constructor({
    // Super
    id,
    createdAt,
    isSelected,
    bbrID,
    bbrData,
    isFloorPlanSkipped,
    isProfileImageSkipped,

    // Building
    bbrFloors = [],
    data = {},
    floorIDs = [],
    apartmentIDs = [],

    // Manual data
    manualData = {
      title: '',
      cadastralNumber: '',
      landOwnerAssosiationName: '',
      yearBuilt: '',
      remodelingYear: '',
      builtArea: '',
      totalLivingArea: '',
      totalBusinessArea: '',
      basementArea: '',
      utilizedAtticArea: '',
    },

  }) {
    super({
      id,
      createdAt,
      isSelected,
      bbrID,
      bbrData,
      isFloorPlanSkipped,
      isProfileImageSkipped,
      type: 'building',
    })

    // Data from BBR
    this.bbrFloors = bbrFloors

    // Manual data
    this.manualData = {
      ...manualData,
    }

    // Other generated data
    this.data = {
      hasEntranceOrHallway: data.hasEntranceOrHallway || false,
      isMainBuilding: data.isMainBuilding || false,
      sortingNumber: 9999,
    }
    this.floorIDs = floorIDs
    this.apartmentIDs = apartmentIDs

    // Set sorting number
    if (bbrData && bbrData.Bygningsnr && !window.isNaN(bbrData.Bygningsnr)) {
      this.data.sortingNumber = Number(bbrData.Bygningsnr)
    }
  }

  getTitle() {
    if (this.bbrID) {
      return `${ mixWB('BUILDING') } ${ this.bbrData.Bygningsnr }`
    }
    if (this.manualData.title) {
      return this.manualData.title
    }
    return 'Ingen titel på denne bygning'
  }

  getFullTitle() {
    return this.getTitle()
  }

  getShortTitle() {
    if (this.bbrID) {
      return `B${ this.bbrData.Bygningsnr }`
    }
    if (this.manualData.title) {
      return this.manualData.title
    }
    return 'N/A'
  }

  getCadastralNumber() {
    if (this.bbrID) {
      return this.bbrData.MatrNr
    }
    if (this.manualData.cadastralNumber) {
      return this.manualData.cadastralNumber
    }
    return null
  }

  getLandOwnerAssosiationName() {
    if (this.bbrID) {
      return ownerAssociations[this.bbrData.Landsejerlavkode]
    }
    if (this.manualData.landOwnerAssosiationName) {
      return this.manualData.landOwnerAssosiationName
    }
    return null
  }

  getBuildingTypeCode() {
    if (this.bbrID) {
      return this.bbrData.BYG_ANVEND_KODE
    }
    if (this.manualData.buildingTypeCode) {
      return this.manualData.buildingTypeCode
    }
    return null
  }

  getBuildingTypeCodeText() {
    if (this.bbrID) {
      return buildingTypeCodes[this.bbrData.BYG_ANVEND_KODE]
    }
    if (this.manualData.buildingTypeCode) {
      return buildingTypeCodes[this.manualData.buildingTypeCode] || null
    }
    return null
  }

  getYearBuilt() {
    if (this.bbrID) {
      return this.bbrData.OPFOERELSE_AAR
    }
    if (this.manualData.yearBuilt) {
      return this.manualData.yearBuilt
    }
    return null
  }

  getRemodelingYear() {
    if (this.bbrID) {
      return this.bbrData.OMBYG_AAR
    }
    if (this.manualData.remodelingYear) {
      return this.manualData.remodelingYear
    }
    return null
  }

  getBuiltArea() {
    if (this.bbrID) {
      return `${ this.bbrData.BYG_BEBYG_ARL } ${ mixWB('M2') }`
    }
    if (this.manualData.builtArea) {
      return `${ this.manualData.builtArea } ${ mixWB('M2') }`
    }
    return null
  }

  getTotalLivingArea() {
    if (this.bbrID && this.bbrData.BYG_BOLIG_ARL_SAML) {
      return `${ this.bbrData.BYG_BOLIG_ARL_SAML } ${ mixWB('M2') }`
    }
    if (this.manualData.totalLivingArea) {
      return `${ this.manualData.totalLivingArea } ${ mixWB('M2') }`
    }
    return null
  }

  getTotalBusinessArea() {
    if (this.bbrID) {
      return `${ this.bbrData.ERHV_ARL_SAML } ${ mixWB('M2') }`
    }
    if (this.manualData.totalBusinessArea) {
      return `${ this.manualData.totalBusinessArea } ${ mixWB('M2') }`
    }
    return null
  }

  getBasementArea() {
    if (this.bbrID && this.bbrFloors.length) {
      const basement = this.bbrFloors.find((x) => x.Etagebetegn === 'KL')

      if (!basement || !basement.SamletAreal) {
        return null
      }

      return `${ basement.SamletAreal } ${ mixWB('M2') }`
    }
    if (this.manualData.basementArea) {
      return `${ this.manualData.basementArea } ${ mixWB('M2') }`
    }
    return null
  }

  getUtilizedAtticArea() {
    if (this.bbrID && this.bbrFloors.length) {
      const attic = this.bbrFloors.find((x) => x.TAGETAGE_ARL_UDNYT > 0)

      if (!attic || !attic.TAGETAGE_ARL_UDNYT) {
        return null
      }

      return `${ attic.TAGETAGE_ARL_UDNYT } ${ mixWB('M2') }`
    }
    if (this.manualData.utilizedAtticArea) {
      return `${ this.manualData.utilizedAtticArea } ${ mixWB('M2') }`
    }
    return null
  }

  getStatusNumericValue() {
    if (this.bbrID) {
      return this.bbrData.ObjStatus
    }
    return 0
  }

  getStatus() {
    if (this.bbrID) {
      if (this.bbrData.ObjStatus === 2) {
        return mixWB('EXTENSION_OR_CONVERSION')
      }
      if (this.bbrData.ObjStatus === 3) {
        return mixWB('DEMOLITION_COMPLETE_OR_PARTIAL')
      }
      if (this.bbrData.ObjStatus === 4) {
        return mixWB('COMPLETED_CONSTRUCTION_CASE')
      }
    }
    return this.mixWB('NO_STATUS_FOUND')
  }

  // eslint-disable-next-line class-methods-use-this
  hasParentFloorPlan() {
    return false
  }
}
