<template>
  <div
    v-if="showButton"
    class="BackToTop"
    @click="onButtonClick">
    <AngleRightIcon />
  </div>
</template>

<script>
import AngleRightIcon from '@/assets/svg/angle-right.svg?inline'
import { scrollTo } from '@/globals/javascript/_util/util'

export default {
  name: 'BackToTop',
  data() {
    return {
      showButton: false,
    }
  },
  methods: {
    onButtonClick() {
      scrollTo(0)
    },
    onScroll() {
      if (window.scrollY > 400) {
        this.showButton = true
        return
      }

      this.showButton = false
    },
  },
  components: {
    AngleRightIcon,
  },
  mounted() {
    document.addEventListener('scroll', this.onScroll.bind(this))
    this.onScroll()
  },
}
</script>

<style lang="stylus" scoped>
  .BackToTop
    position fixed
    bottom 20px
    z-index $back_to_top
    right 20px
    circle(50px)
    background-color $color_salmon
    padding 10px 12px 14px
    box-shadow 0px 3px 5px -1px rgba(0,0,0,0.2),
               0px 6px 10px 0px rgba(0,0,0,0.14),
               0px 1px 18px 0px rgba(0,0,0,0.12)
    cursor pointer
    transition background-color 0.15s ease-out
    svg
      transform rotate(-90deg)
      fill #fff
    &:hover
      background-color $color_salmon_dark
    @media print
      display none
</style>
