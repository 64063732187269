<template>
  <div class="ChangelogModal">
    <span class="Title">{{ mixWB('LATEST_UPDATES') }}</span>

    <!-- Loading -->
    <div
      v-if="!allChangelogsLoaded"
      class="Loading">
      <Circular size="medium" />
    </div>

    <!-- Content -->
    <div
      v-else
      class="Content">
      <div
        class="Changelog"
        v-for="changelog in filteredChangelogs"
        :key="changelog.id">
        <span class="Version">{{ mixWB('VERSION_X', [changelog.version]) }}</span>
        <div
          v-if="changelog.features.length"
          class="Section">
          <span class="SectionTitle">{{ mixWB('NEW_FEATURES') }}</span>
          <span
            class="SectionItem"
            v-for="line in changelog.features.split('\n')"
            :key="line">{{ line }}</span>
        </div>
        <div
          v-if="changelog.bugFixes.length"
          class="Section">
          <span class="SectionTitle">{{ mixWB('BUG_FIXES') }}</span>
          <span
            class="SectionItem"
            v-for="line in changelog.bugFixes.split('\n')"
            :key="line">{{ line }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Circular from '../Progress/Circular.vue'

export default {
  name: 'ChangelogModal',
  computed: {
    ...mapGetters([
      'allChangelogsLoaded',
      'allChangelogs',
      'latestChangelogVersion',
    ]),
    filteredChangelogs() {
      return this.allChangelogs.filter((changelog) => {
        const isChangelogTooNew = this.mixCompareVersionsNewerOnly(
          changelog.version,
          this.latestChangelogVersion,
        )

        return !isChangelogTooNew
      })
    },
  },
  watch: {
    filteredChangelogs() {
      if (this.filteredChangelogs.length) {
        requestAnimationFrame(() => {
          this.$emit('check-height')
        })
      }
    },
  },
  methods: {
    getItemsOnLoad() {
      this.$store.dispatch('getChangelogs')
    },
  },
  components: {
    Circular,
  },
  created() {
    this.getItemsOnLoad()
  },
}
</script>

<style lang="stylus" scoped>
  .ChangelogModal
    display block
    min-height 300px
    span
      display block

  .Title
    modalTitle()

  .Version
    border-bottom 1px solid $color_grey_lighter
    padding-bottom 2px
    margin-bottom 7px
    font-weight bold
    font-size 0.875rem

  .Changelog
    margin-bottom 15px

  .Section
    margin-bottom 10px
    span
      font-size 0.875rem
    .SectionTitle
      padding-bottom 3px
      text-transform uppercase
      font-size 0.75rem
    .SectionItem
      position relative
      padding-left 10px
      &:before
        content '-'
        position absolute
        top 0
        left 1px
</style>
