import store from '@/store'
import { mixWB } from '@/globals/javascript/_util/mixins.js'
import GroupedTypeResultItem from '@/globals/javascript/models/GroupedTypeResultItem'
import * as setup from './setup'

export function getResults({
  materialWDCID,
  coatingWDCID,
}) {
  const {
    workDescriptionCases,
    workDescriptionCasesAsArray,
  } = store.getters

  const hasCoating = !!this.coating.wdcID
  const needToBeCleaned = setup.getNeedToBeCleanedStatus.call(
    this, { materialWDCID, coatingWDCID },
  )
  const results = []

  // No test data uploaded
  if (this.isMissingUploadedData) {
    results.push(new GroupedTypeResultItem({
      className: 'Neutral',
      resultText: '?',
    }))
    return results
  }
  // Resale
  if (this.isResale) {
    results.push(new GroupedTypeResultItem({
      className: 'Neutral',
      resultText: mixWB('RESALE'),
    }))
    return results
  }
  // No wdgID
  if (!this.wdgID) {
    results.push(new GroupedTypeResultItem({
      className: 'Neutral',
      resultText: '?',
    }))
    return results
  }
  // Missing sample
  if (this.isMissingSample) {
    results.push(new GroupedTypeResultItem({
      className: 'Neutral',
      resultText: '?',
    }))
    return results
  }
  // Missing test result
  if (this.isMissingTestResults) {
    results.push(new GroupedTypeResultItem({
      className: 'Neutral',
      resultText: '?',
    }))
    return results
  }

  // Special cases
  if (this.specialCase) {
    const wdcItem = workDescriptionCases[this.material.wdcID]

    // Electrical installations
    if (this.specialCase.caseID === 'special-case-1') {
      results.push(new GroupedTypeResultItem({
        className: wdcItem.className,
        resultText: mixWB(wdcItem.wordbook),
      }))
    }

    // Isolation CT-66, CT-67
    if (this.specialCase.caseID === 'special-case-2') {
      results.push(new GroupedTypeResultItem({
        className: 'Neutral',
        resultText: mixWB('CLEAN_HAZARDOUS'),
      }))
    }

    // Chimneys
    if (this.specialCase.caseID === 'special-case-3') {
      results.push(new GroupedTypeResultItem({
        className: wdcItem.className,
        resultText: mixWB(wdcItem.wordbook),
      }))
    }

    return results
  }

  // Clean with no coating
  if (materialWDCID === 'WDC-1' && (!hasCoating || (hasCoating && needToBeCleaned))) {
    if (this.isAllMetalOrIron) {
      results.push(new GroupedTypeResultItem({
        className: 'Clean',
        resultText: mixWB('RECYCLABLE'),
      }))
    }
    else {
      results.push(new GroupedTypeResultItem({
        className: 'Clean',
        resultText: mixWB(workDescriptionCases['WDC-1'].wordbook),
      }))
    }
  }

  // Not clean with no coating or coating that needs to be cleaned
  if (materialWDCID !== 'WDC-1' && (!hasCoating || (hasCoating && needToBeCleaned))) {
    results.push(new GroupedTypeResultItem({
      className: mixWB(workDescriptionCases[materialWDCID].className),
      resultText: mixWB(workDescriptionCases[materialWDCID].wordbook),
    }))
  }

  // Coating that should not be cleaned
  if (hasCoating && !needToBeCleaned) {
    const materialContaminationScaleValue = setup.getContaminationScaleValue(
      materialWDCID,
    )
    const coatingContaminationScaleValue = setup.getContaminationScaleValue(
      coatingWDCID,
    )
    const combinedContaminationScaleValue = setup.getCombinedContaminationScaleValue([
      materialContaminationScaleValue,
      coatingContaminationScaleValue,
    ])

    const finalWDCItem = workDescriptionCasesAsArray.find(
      (x) => x.contaminationScaleValue === combinedContaminationScaleValue,
    )
    results.push(new GroupedTypeResultItem({
      className: mixWB(finalWDCItem.className),
      resultText: mixWB(finalWDCItem.wordbook),
    }))
    return results
  }

  // Coating that needs to be cleaned
  if (hasCoating && needToBeCleaned) {
    // Coating
    results.push(new GroupedTypeResultItem({
      className: mixWB(workDescriptionCases[coatingWDCID].className),
      resultText: mixWB(workDescriptionCases[coatingWDCID].wordbook),
      kindOfResult: 'coating',
    }))
  }

  return results
}

// Explanations
export function getExplanationTexts({
  materialWDCID,
  previousWDCID,
  nextWDCID,
  coatingWDCID,
  groupedTypeTitle,
  lastTypeTitleWithCoating,
  previousGroupedTypes,
  nextGroupedTypes,
}) {
  const { underliningTypes } = store.getters
  const hasCoating = !!this.coating.wdcID
  const shouldBeCleaned = this.wdgID === 'WDG-2'
  const needToBeCleaned = setup.getNeedToBeCleanedStatus.call(
    this, { materialWDCID, coatingWDCID },
  )
  const texts = []

  // No test data uploaded
  if (this.isMissingUploadedData) {
    texts.push(mixWB('NO_TEST_RESULTS_UPLOADED_YET'))
    return texts
  }
  // Resale
  if (this.isResale) {
    texts.push(mixWB('SUITABLE_FOR_RECYCLING'))
    return texts
  }
  // No wdgID
  if (!this.wdgID) {
    return texts
  }
  // Missing sample
  if (this.isMissingSample) {
    texts.push(mixWB('NO_SAMPLE_FOR_TYPE_TEXT'))
    return texts
  }
  // Missing test result
  if (this.isMissingTestResults) {
    texts.push(mixWB('TEST_RESULTS_MISSING_TEXT'))
    return texts
  }
  // Special cases
  if (this.specialCase) {
    // Chimneys
    if (this.specialCase.caseID === 'special-case-3') {
      texts.push(mixWB('NO_RISK_EXPLANATION_CHIMNEY'))
    }
    return texts
  }
  // Special tags
  if (this.specialTags.length) {
    // Tiles - No asbestos sample
    if (this.specialTags.includes('no-asbestos-tiles')) {
      texts.push(mixWB('NO_SAMPLE_FOR_ASBESTOS_TILES_TEXT'))
    }
    // General - No asbestos sample
    if (this.specialTags.includes('no-asbestos-sample-required')) {
      texts.push(mixWB('NO_SAMPLE_FOR_ASBESTOS_TEXT'))
    }
    // Oil stains
    if (this.specialTags.includes('has-oil-stains')) {
      texts.push(mixWB('SEPARATE_HANDLING_OF_OIL_STAINS_REQUIRED'))
    }
  }

  // Other tags
  // Metals above threshold - material
  if (this.material.hazardiousGroupIDs.includes('7-metals-above-threshold')) {
    texts.push(mixWB('METALS_ABOVE_THRESHOLD_MATERIAL_TEXT'))
  }
  // Metals above threshold - coating
  if (this.coating.hazardiousGroupIDs.includes('7-metals-above-threshold')) {
    texts.push(mixWB('METALS_ABOVE_THRESHOLD_COATING_TEXT'))
  }

  // Some types did not need a sample
  if (this.isLastTypeWallpaperWithCoating) {
    texts.push(mixWB('X_HAS_RISK_TEXT', [lastTypeTitleWithCoating]))
  }
  if (!this.allTypesHasMaterialSample) {
    if (!this.isMultiType) {
      if (this.isAllMetalOrIron) {
        texts.push(mixWB('THE_MATERIAL_X_NO_RISK_EXPLANATION_TEXT_METAL', [groupedTypeTitle.toLowerCase()]))
      }
      else {
        texts.push(mixWB('THE_MATERIAL_X_NO_RISK_EXPLANATION_TEXT', [groupedTypeTitle.toLowerCase()]))
      }
    }
    else {
      texts.push(mixWB('THE_MATERIALS_X_NO_RISK_EXPLANATION_TEXT_MULTI_TYPE', [groupedTypeTitle.toLowerCase()]))
    }
  }

  // Note for coating needing sample
  if (hasCoating && !this.isLastTypeWallpaperWithCoating) {
    texts.push(mixWB('COATING_HAS_RISK_TEXT'))
  }

  // Underlining
  if (this.underlining.underliningTypeIDs.length) {
    const underliningText = mixWB(underliningTypes[
      this.underlining.underliningTypeIDs[0]
    ].wordbook).toLowerCase()
    texts.push(mixWB('THE_ROOF_IS_UNDERLINED_WITH_X', [underliningText]))
  }

  // Has coating but no need to clean it
  if (hasCoating && !needToBeCleaned) {
    if (coatingWDCID === 'WDC-1') {
      texts.push(mixWB('NO_CLEANING_OF_COATING_TEXT_1'))
    }
    else if (shouldBeCleaned) {
      texts.push(mixWB('NO_CLEANING_OF_COATING_TEXT_2'))
    }
    else {
      texts.push(mixWB('NO_CLEANING_OF_COATING_TEXT_3'))
    }
  }

  // Some samples are assessed
  this.assessCases.forEach((item) => {
    if (item.isAutoAssessment) {
      if (item.status === 'auto-assess-asbestos-perforated-ceiling-tiles') {
        texts.push(mixWB('PERFORATED_CEILING_TILES_ASBESTOS_EXPLANATION'))
      }
    }
    else if (item.wdcID === 'WDC-3') {
      texts.push(mixWB('WDC_3_ASSESSMENT_TEXT_FOR_X', [item.sampleTitle]))
    }
    else if (item.wdcID === 'WDC-4') {
      texts.push(mixWB('WDC_4_ASSESSMENT_TEXT_FOR_X', [item.sampleTitle]))
    }
    else if (item.wdcID === 'WDC-5') {
      texts.push(mixWB('WDC_5_ASSESSMENT_TEXT_FOR_X', [item.sampleTitle]))
    }
  })

  // Contaminated by adjacent type
  if (previousWDCID || nextWDCID) {
    const groupedTypeFinalWDCID = setup.getGroupedTypeFinalWDCID.call(
      this,
      { includeCoating: hasCoating && !needToBeCleaned },
    )
    const groupedTypeContaminationScaleValue = setup.getContaminationScaleValue(
      groupedTypeFinalWDCID,
    )
    const prevTypeContaminationScaleValue = setup.getContaminationScaleValue(
      previousWDCID || 'WDC-1',
    )
    const nextTypeContaminationScaleValue = setup.getContaminationScaleValue(
      nextWDCID || 'WDC-1',
    )
    const combinedWithPrevContaminationScaleValue = setup.getCombinedContaminationScaleValue([
      groupedTypeContaminationScaleValue,
      prevTypeContaminationScaleValue,
    ])
    const combinedWithNextContaminationScaleValue = setup.getCombinedContaminationScaleValue([
      groupedTypeContaminationScaleValue,
      nextTypeContaminationScaleValue,
    ])

    // Inner type contamination
    if (combinedWithPrevContaminationScaleValue > groupedTypeContaminationScaleValue) {
      if (groupedTypeContaminationScaleValue === 1) {
        texts.push(mixWB(
          'THE_ADJACENT_UNDERLYING_TYPE_X_HAS_CONTAMINATED_THIS_TYPE',
          [previousGroupedTypes[0].getGroupedTypeTitle({ withDetails: true, withIndex: true })],
        ))
      }
      else {
        texts.push(mixWB(
          'THE_ADJACENT_UNDERLYING_TYPE_X_HAS_FURTHER_CONTAMINATED_THIS_TYPE',
          [previousGroupedTypes[0].getGroupedTypeTitle({ withDetails: true, withIndex: true })],
        ))
      }
    }

    // Outer type contamination
    if (combinedWithNextContaminationScaleValue > groupedTypeContaminationScaleValue) {
      if (groupedTypeContaminationScaleValue === 1) {
        if (hasCoating) {
          texts.push(mixWB(
            'THE_ADJACENT_OVERLYING_TYPE_X_HAS_CONTAMINATED_THE_COATING_OF_THIS_TYPE',
            [nextGroupedTypes[0].getGroupedTypeTitle({ withDetails: true, withIndex: true })],
          ))
        }
        else {
          texts.push(mixWB(
            'THE_ADJACENT_OVERLYING_TYPE_X_HAS_CONTAMINATED_THIS_TYPE',
            [nextGroupedTypes[0].getGroupedTypeTitle({ withDetails: true, withIndex: true })],
          ))
        }
      }
      else if (hasCoating) {
        texts.push(mixWB(
          'THE_ADJACENT_OVERLYING_TYPE_X_HAS_FURTHER_CONTAMINATED_THE_COATING_OF_THIS_TYPE',
          [nextGroupedTypes[0].getGroupedTypeTitle({ withDetails: true, withIndex: true })],
        ))
      }
      else {
        texts.push(mixWB(
          'THE_ADJACENT_OVERLYING_TYPE_X_HAS_FURTHER_CONTAMINATED_THIS_TYPE',
          [nextGroupedTypes[0].getGroupedTypeTitle({ withDetails: true, withIndex: true })],
        ))
      }
    }
  }

  return texts
}

// Work descriptions
export function getWorkDescriptionTexts({
  groupedType,
  materialWDCID,
  coatingWDCID,
  previousWDCID,
}) {
  const {
    workDescriptionGroups,
    workDescriptionCasesAsArray,
  } = store.getters
  const texts = []

  // No test data uploaded
  if (this.isMissingUploadedData) {
    return texts
  }
  // Resale
  if (this.isResale) {
    return texts
  }
  // No wdgID
  if (!this.wdgID) {
    texts.push(mixWB('DOES_NOT_HAVE_A_WORK_DESCRIPTION_GROUP'))
    return texts
  }
  // Missing sample
  if (this.isMissingSample) {
    return texts
  }
  // Missing test result
  if (this.isMissingTestResults) {
    return texts
  }

  // Special cases
  if (this.specialCase) {
    // Isolation
    if (this.specialCase.caseID === 'special-case-2') {
      texts.push(mixWB('WORK_DESCRIPTION_STATIC_ISOLATION_TEXT'))
    }

    // Chimney
    if (this.specialCase.caseID === 'special-case-3') {
      texts.push(mixWB('WORK_DESCRIPTION_STATIC_CHIMNEY_TEXT'))
    }
  }

  const hasCoating = !!this.coating.wdcID
  const needToBeCleaned = setup.getNeedToBeCleanedStatus.call(
    this, { materialWDCID, coatingWDCID },
  )
  const materialContaminationScaleValue = setup.getContaminationScaleValue(
    materialWDCID,
  )
  const coatingContaminationScaleValue = setup.getContaminationScaleValue(
    coatingWDCID,
  )
  const combinedContaminationScaleValue = setup.getCombinedContaminationScaleValue([
    materialContaminationScaleValue,
    coatingContaminationScaleValue,
  ])
  const underlyingMustBeCleaned = setup.getUnderlyingMustBeCleanedStatus.call(
    this, groupedType,
  )
  const overlyingMustBeCleaned = setup.getOverlyingMustBeCleanedStatus.call(
    this, groupedType,
  )

  const group = workDescriptionGroups[this.wdgID]
  let materialGroupCase = group.cases[materialWDCID]

  // No material group case found
  if (!materialGroupCase) {
    this.texts.push(`Ingen tekster for ${ this.wdgID } -> ${ materialWDCID }`)
    return texts
  }

  // Leveling with asbestos cleaning text
  if (this.isFirstTypeLeveling && ['WDC-4', 'WDC-5'].includes(materialWDCID)) {
    texts.push(mixWB('MUST_BE_CLEANED_TO_CLEAN_UNDERLYING_MATERIAL'))
  }

  // First type is tiles with asbestos
  if (this.isFirstTypeTilesWithAdhesives && ['WDC-4', 'WDC-5'].includes(this.material.wdcID)) {
    texts.push('-tile-adhesive-header-')
    texts.push(mixWB('MUST_BE_CLEANED_TO_CLEAN_UNDERLYING_MATERIAL'))

    // Check to upgrade to WDC-5 by looking at previous type
    if (['WDC-3', 'WDC-5'].includes(previousWDCID)) {
      texts.push(mixWB('WDG_2_WDC_5_TEXT'))
    }
    // Keep it at WDC-4
    else {
      texts.push(mixWB('WDG_2_WDC_4_TEXT'))
    }

    if (this.isMultiType) {
      texts.push('-material-header-')
    }
    else {
      texts.push('-tiles-and-joints-header')
    }
  }

  // No coating
  if (!hasCoating) {
    // Add text to clean underlying material
    if (underlyingMustBeCleaned) {
      texts.push(mixWB('MUST_BE_CLEANED_TO_CLEAN_UNDERLYING_MATERIAL'))
    }

    // Add text to clean overlying material
    if (overlyingMustBeCleaned) {
      texts.push(mixWB('MUST_BE_CLEANED_TO_CLEAN_OVERLYING_MATERIAL'))
    }

    // Add main text
    texts.push(mixWB(materialGroupCase.wordbook))

    if (this.isMultiType && ['WDC-4', 'WDC-5'].includes(materialWDCID)) {
      texts.push('-multi-type-asbestos-obs-text-')
    }

    if (materialWDCID !== 'WDC-1') {
      texts.push(mixWB('WORK_DESCRIPTION_HANDLING_DAGEROUS_MATERIALS_TEXT'))
    }

    return texts
  }

  // Has coating but no need to clean it
  if (hasCoating && !needToBeCleaned) {
    const finalMaterialWDCItem = workDescriptionCasesAsArray.find(
      (x) => x.contaminationScaleValue === combinedContaminationScaleValue,
    )
    materialGroupCase = group.cases[finalMaterialWDCItem.id]

    // Add text to clean underlying material
    if (underlyingMustBeCleaned) {
      texts.push(mixWB('MUST_BE_CLEANED_TO_CLEAN_UNDERLYING_MATERIAL'))
    }

    // Add text to clean overlying material
    if (overlyingMustBeCleaned) {
      texts.push(mixWB('MUST_BE_CLEANED_TO_CLEAN_OVERLYING_MATERIAL'))
    }

    // Add main text
    texts.push(mixWB(materialGroupCase.wordbook))

    if (this.isMultiType && ['WDC-4', 'WDC-5'].includes(materialWDCID)) {
      texts.push('-multi-type-asbestos-obs-text-')
    }

    if (finalMaterialWDCItem.id !== 'WDC-1') {
      texts.push(mixWB('WORK_DESCRIPTION_HANDLING_DAGEROUS_MATERIALS_TEXT'))
    }

    return texts
  }

  // Has coating and should be cleaned
  if (hasCoating && needToBeCleaned) {
    const finalCoatingWDCItem = workDescriptionCasesAsArray.find(
      (x) => x.contaminationScaleValue === combinedContaminationScaleValue,
    )
    const group = workDescriptionGroups[this.wdgID]
    const coatingGroupCase = group.cases[finalCoatingWDCItem.id]

    // Coating
    texts.push('-coating-header-')
    // Add text to clean overlying material
    if (overlyingMustBeCleaned) {
      texts.push(mixWB('-need-to-be-cleaned-overlying-'))
    }
    texts.push(mixWB('-need-to-be-cleaned-underlying-'))
    texts.push(mixWB(coatingGroupCase.wordbook))

    if (this.isMultiType && ['WDC-4', 'WDC-5'].includes(materialWDCID)) {
      texts.push('-multi-type-asbestos-obs-text-')
    }

    // - Check for cleaning PCB adjacent material
    if (
      group.pcbCleanAdjacentMaterial
      && (
        this.coating.contaminatedGroupIDs.includes('PCB')
        || this.coating.hazardiousGroupIDs.includes('PCB')
      )
    ) {
      const assessmentText = this.coating.isAssessment
        ? mixWB('HANDLE_PCB_WORD_ASSESSED')
        : mixWB('HANDLE_PCB_WORD_PROVED')
      const pcbLevel = this.coating.contaminatedGroupIDs.includes('PCB')
        ? mixWB('CONTAMINATED')
        : mixWB('HAZARDOUS')
      texts.push(mixWB('HANDLE_PCB_ADJACENT_MATERIAL_TEXT_1', [assessmentText, pcbLevel]))
    }

    if (finalCoatingWDCItem.id !== 'WDC-1') {
      texts.push(mixWB('WORK_DESCRIPTION_HANDLING_DAGEROUS_CLEANING_TEXT'))
    }

    // Material
    texts.push('-material-header-')
    texts.push(mixWB(materialGroupCase.wordbook))

    // Add text to clean underlying material
    if (underlyingMustBeCleaned) {
      texts.push(mixWB('MUST_BE_CLEANED_TO_CLEAN_UNDERLYING_MATERIAL'))
    }

    if (materialWDCID !== 'WDC-1') {
      texts.push(mixWB('WORK_DESCRIPTION_HANDLING_DAGEROUS_MATERIALS_TEXT'))
    }

    return texts
  }

  return texts
}
