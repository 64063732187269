import { singleScreeningChecklist } from './_checklists'

export const resultRoutes = [
  {
    path: '/result/:screeningID',
    name: 'ResultOverview',
    meta: {
      requiresAuth: true,
      requiresScreeningID: true,
      checklist: [
        ...singleScreeningChecklist,
      ],
    },
    component: () => import('../views/Result/Overview.vue'),
  },
  {
    path: '/result/:screeningID/samples',
    name: 'ResultSamples',
    meta: {
      requiresAuth: true,
      requiresScreeningID: true,
      checklist: [
        ...singleScreeningChecklist,
      ],
    },
    component: () => import('../views/Result/Samples.vue'),
  },
  {
    path: '/result/:screeningID/waste-distribution',
    name: 'WasteDistribution',
    meta: {
      siteArea: 'waste-distribution',
      requiresAuth: true,
      requiresScreeningID: true,
      checklist: [
        ...singleScreeningChecklist,
      ],
    },
    component: () => import('../views/Result/WasteDistribution.vue'),
  },
]
