import { db } from '@/firebase/init'

export const setIDOnCreate = (id, collection) => {
  if (id) {
    return id
  }
  const docRef = db
    .collection(collection)
    .doc()
  return docRef.id
}

const DB_ACCOUNTS = 'Accounts'
const DB_ADDRESS_IMAGES = 'AddressImages'
const DB_BUILDINGS = 'Buildings'
const DB_CHANGELOGS = 'Changelogs'
const DB_INTER_CONNECTIONS = 'InterConnections'
const DB_META_DATA = 'MetaData'
const DB_PCB_SCREENINGS = 'PCBScreenings'
const DB_REPORTS = 'Reports'
const DB_SAMPLES = 'Samples'
const DB_SCREENINGS = 'Screenings'
const DB_STATS = 'Stats'
const DB_TEST_RESULTS = 'TestResults'
const DB_TYPES = 'Types'
const DB_UNITS = 'Units'
const DB_USERS = 'Users'
const DB_WASTE_ITEMS = 'WasteItems'
const DB_LANGUAGES = 'Languages'
const DB_TRANSLATIONS = 'Translations'

export {
  DB_ACCOUNTS,
  DB_ADDRESS_IMAGES,
  DB_BUILDINGS,
  DB_CHANGELOGS,
  DB_INTER_CONNECTIONS,
  DB_META_DATA,
  DB_PCB_SCREENINGS,
  DB_REPORTS,
  DB_SAMPLES,
  DB_SCREENINGS,
  DB_STATS,
  DB_TEST_RESULTS,
  DB_TYPES,
  DB_UNITS,
  DB_USERS,
  DB_WASTE_ITEMS,
  DB_LANGUAGES,
  DB_TRANSLATIONS,
}
