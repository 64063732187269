import { firebase, db } from '@/firebase/init'
import { DB_SAMPLES, DB_SCREENINGS } from '@/globals/javascript/models/_helper'

export const samples = {
  getScreeningSamples: ({ commit, getters }, screeningID) => {
    if (getters.currentScreeningSamplesLoaded) {
      return
    }
    const unsubscribe = db.collection(DB_SCREENINGS)
      .doc(screeningID).collection(DB_SAMPLES)
      .onSnapshot((querySnapshot) => {
        const samples = []
        querySnapshot.forEach((doc) => {
          samples.push({
            ...doc.data(),
            id: doc.id,
          })
        })

        commit('updateCurrentScreeningSamples', { samples, unsubscribe })
      })
  },
  setScreeningSample: ({ getters, dispatch }, { sample, isNew }) => {
    dispatch('updateScreening', { setAsStarted: true, setAsNotCompleted: true })

    db
      .collection(DB_SCREENINGS)
      .doc(getters.currentScreening.id)
      .collection(DB_SAMPLES)
      .doc(sample.id)
      .set({
        ...sample,
        lastUpdated: isNew ? sample.lastUpdated : firebase.firestore.FieldValue.serverTimestamp(),
      })
  },
  deleteScreeningSample: ({ getters, dispatch }, sample) => {
    dispatch('updateScreening', { setAsStarted: true, setAsNotCompleted: true })

    db.collection(DB_SCREENINGS)
      .doc(getters.currentScreening.id)
      .collection(DB_SAMPLES)
      .doc(sample.id)
      .delete()
  },
}
