import GroupedType from '@/globals/javascript/models/GroupedType'
import { mixCompareVersionsSameOrNewer } from '@/globals/javascript/_util/mixins'
import { getWorkDescriptionForGroupedType } from '@/globals/javascript/_util/work-descriptions'
import { union } from 'lodash-es'

export const groupedTypes = {
  groupedTypes: (state, getters) => {
    const {
      currentScreeningData,
      screeningTypes,
      screeningInterConnections,
      categories,
      currentRoute,
    } = getters

    if (
      ![
        'tender',
        'report',
        'waste-distribution',
      ].includes(currentRoute.meta.siteArea)
    ) {
      return []
    }

    if (!screeningTypes.length) {
      return []
    }

    let usedTypeIDs = []
    const usedInterConnectionGroupIDs = []
    const interConnectionGroups = []
    const isPre1190 = !mixCompareVersionsSameOrNewer(
      {
        versionA: currentScreeningData.appVersion,
        versionB: '1.19.0',
      },
    )

    // 1. Find inter connection groups
    screeningInterConnections.forEach((interConnection) => {
      if (!isPre1190 && usedInterConnectionGroupIDs.includes(interConnection.groupID)) {
        return
      }
      usedInterConnectionGroupIDs.push(interConnection.groupID)

      const firstType = screeningTypes.find((x) => x.id === interConnection.innerTypeID)

      // Pre 1.19.0 rule: As inter connections does not have groupIDs a type is only used in
      // one connection
      if (isPre1190 && usedTypeIDs.includes(firstType.id)) {
        return
      }

      usedTypeIDs = union(usedTypeIDs, [firstType.id])

      const groupData = {
        groupID: interConnection.groupID,
        typeItems: [{
          type: firstType,
          innerInterConnection: null,
          outerInterConnection: null,
        }],
      }

      // Find all inner connections
      let nextInnerItem = screeningInterConnections.find(
        (x) => x.outerTypeID === firstType.id
          && (isPre1190 || x.groupID === groupData.groupID),
      )
      while (nextInnerItem) {
        groupData.typeItems[0].innerInterConnection = nextInnerItem
        // eslint-disable-next-line no-loop-func
        const type = screeningTypes.find((x) => x.id === nextInnerItem.innerTypeID)
        usedTypeIDs = union(usedTypeIDs, [type.id])
        groupData.typeItems.unshift({
          type,
          innerInterConnection: null,
          outerInterConnection: nextInnerItem,
        })
        nextInnerItem = screeningInterConnections.find(
          // eslint-disable-next-line no-loop-func
          (x) => x.outerTypeID === nextInnerItem.innerTypeID
            && (isPre1190 || x.groupID === groupData.groupID),
        )
      }

      // Find all outer connections
      let nextOuterItem = screeningInterConnections.find(
        (x) => x.innerTypeID === firstType.id
          && (isPre1190 || x.groupID === groupData.groupID),
      )
      while (nextOuterItem) {
        const lastItem = groupData.typeItems[groupData.typeItems.length - 1]
        lastItem.outerInterConnection = nextOuterItem
        // eslint-disable-next-line no-loop-func
        const type = screeningTypes.find((x) => x.id === nextOuterItem.outerTypeID)
        usedTypeIDs = union(usedTypeIDs, [type.id])
        groupData.typeItems.push({
          type,
          innerInterConnection: nextOuterItem,
          outerInterConnection: null,
        })
        nextOuterItem = screeningInterConnections.find(
          // eslint-disable-next-line no-loop-func
          (x) => x.innerTypeID === nextOuterItem.outerTypeID
            && (isPre1190 || x.groupID === groupData.groupID),
        )
      }

      interConnectionGroups.push(groupData)
    })

    // 2. Find types in groups that are also by them selves
    const singleTypes = []
    usedTypeIDs.forEach((typeID) => {
      const type = screeningTypes.find((x) => x.id === typeID)
      if (type.isOwnTypeAsWell) {
        singleTypes.push(type)
      }
    })

    // 3. Find rest of types
    screeningTypes.forEach((type) => {
      if (usedTypeIDs.includes(type.id)) {
        return
      }
      usedTypeIDs = union(usedTypeIDs, [type.id])
      singleTypes.push(type)
    })

    // 4. Make final groups
    const groupedTypes = []

    // 4.1 Add from groups
    const duplicateCleanableGroupedTypesObject = {}
    interConnectionGroups.forEach((group) => {
      let isFirstInGroup = true
      group.typeItems.forEach((typeItem, index) => {
        const { type } = typeItem
        const category = categories.find((x) => x.id === type.categoryID)
        let groupedType = null
        if (isFirstInGroup) {
          groupedType = new GroupedType({
            id: `group-${ group.groupID }-${ type.id }`,
            categoryID: category.id,
            groupID: group.groupID,
            firstTypeID: type.id,
          })
          groupedTypes.push(groupedType)

          // Set left side effect direction
          if (typeItem.innerInterConnection) {
            groupedType.leftSideEffectDirection = typeItem.innerInterConnection.effectDirection
          }

          // Connect groups
          const lastGroupedType = groupedTypes[groupedTypes.length - 2]
          if (lastGroupedType?.groupID === group.groupID) {
            lastGroupedType.nextGroupedTypes.push(groupedType)
            groupedType.previousGroupedTypes.push(lastGroupedType)
          }
        }
        else {
          groupedType = groupedTypes[groupedTypes.length - 1]
          groupedType.id += `-${ type.id }`
        }

        groupedType.lastTypeID = type.id
        groupedType.types.push(type)

        if (
          typeItem.outerInterConnection?.splitStatus === 'splitable'
          || index === group.typeItems.length - 1
        ) {
          // Check for duplicate groupedTypes
          if (isFirstInGroup) {
            const existingDuplicateGroupedType = duplicateCleanableGroupedTypesObject[type.id]
            if (existingDuplicateGroupedType) {
              existingDuplicateGroupedType.push(groupedType)
            }
            else {
              duplicateCleanableGroupedTypesObject[type.id] = [groupedType]
            }
          }

          // Set left side effect direction
          if (typeItem.outerInterConnection) {
            groupedType.rightSideEffectDirection = typeItem.outerInterConnection.effectDirection
          }

          isFirstInGroup = true
        }
        else {
          isFirstInGroup = false
        }
      })
    })

    // 4.2 Get grouped type results
    groupedTypes.forEach((groupedType) => {
      groupedType.groupedTypeResult = getWorkDescriptionForGroupedType({ groupedType })
    })

    // 4.3 Add from single types
    singleTypes.forEach((type) => {
      const category = categories.find((x) => x.id === type.categoryID)
      const groupedType = new GroupedType({
        id: type.id,
        categoryID: category.id,
        types: [type],
        firstTypeID: type.id,
        lastTypeID: type.id,
      })
      groupedType.groupedTypeResult = getWorkDescriptionForGroupedType({ groupedType })
      groupedTypes.push(groupedType)

      if (duplicateCleanableGroupedTypesObject[type.id]) {
        duplicateCleanableGroupedTypesObject[type.id].push(groupedType)
      }
    })

    // 4.4 Unite duplicates that is not contaminated by adjacent types
    Object.keys(duplicateCleanableGroupedTypesObject).forEach((key) => {
      const arrayOfDublicates = duplicateCleanableGroupedTypesObject[key]

      let mainGroupedType = null
      arrayOfDublicates.forEach((currentGroupedType) => {
        const isContaminatedByAdjacentType = currentGroupedType
          .getContaminatedByAdjecentTypesStatus()

        if (isContaminatedByAdjacentType) {
          return
        }

        if (!mainGroupedType) {
          mainGroupedType = currentGroupedType
          return
        }

        // 1. Prev - Add previous connections of duplicate to mainGroupedType
        currentGroupedType.previousGroupedTypes.forEach((prevGroupedType) => {
          if (!mainGroupedType.previousGroupedTypes.find((x) => x.id === prevGroupedType.id)) {
            mainGroupedType.previousGroupedTypes.push(prevGroupedType)
          }

          if (!prevGroupedType.nextGroupedTypes.find((x) => x.id === mainGroupedType.id)) {
            prevGroupedType.nextGroupedTypes.push(mainGroupedType)
          }

          const removeIndex = prevGroupedType.nextGroupedTypes.findIndex(
            (x) => x.id === currentGroupedType.id,
          )
          prevGroupedType.nextGroupedTypes.splice(removeIndex, 1)
        })

        // 2. Next - Add next connections of duplicate to mainGroupedType
        currentGroupedType.nextGroupedTypes.forEach((nextGroupedType) => {
          if (!mainGroupedType.nextGroupedTypes.find((x) => x.id === nextGroupedType.id)) {
            mainGroupedType.nextGroupedTypes.push(nextGroupedType)
          }

          if (!nextGroupedType.previousGroupedTypes.find((x) => x.id === mainGroupedType.id)) {
            nextGroupedType.previousGroupedTypes.push(mainGroupedType)
          }

          const removeIndex = nextGroupedType.previousGroupedTypes.findIndex(
            (x) => x.id === currentGroupedType.id,
          )
          nextGroupedType.previousGroupedTypes.splice(removeIndex, 1)
        })

        // 3. Remove duplicate
        const currentIndex = groupedTypes.findIndex((x) => x.id === currentGroupedType.id)
        groupedTypes.splice(currentIndex, 1)
      })
    })

    // 4.5 Mark types as duplicates as to hide meta data in the report
    const foundTypeIDs = []
    groupedTypes.forEach((groupedType) => {
      groupedType.types.forEach((type) => {
        if (foundTypeIDs.includes(type.id)) {
          groupedType.duplicateTypeIDs.push(type.id)
          return
        }
        foundTypeIDs.push(type.id)
      })
    })

    return groupedTypes
  },
  categoriesWithGroupedTypes: (state, getters) => {
    const { groupedTypes, categories } = getters

    // Split grouped types into categories
    const categoryList = []
    categories.forEach((category, index) => {
      const categoryItem = {
        category,
        index: index + 1,
        groupedTypes: [],
      }

      categoryItem.groupedTypes = groupedTypes.filter((x) => x.categoryID === category.id)

      categoryItem.groupedTypes.forEach((groupedType, childIndex) => {
        groupedType.index = childIndex + 1
        groupedType.categoryIndex = index + 1
      })

      categoryList.push(categoryItem)
    })

    return categoryList
  },
}
