/* eslint-disable no-use-before-define */
import EventBus from '@/EventBus'
import { storage } from '@/firebase/init'
import { keys } from '@/globals/javascript/_util/keys'
import { mixGetSizeWordbook } from '@/globals/javascript/_util/mixins'
import { getLocalStorageValue, setLocalStorageValue } from '@/globals/javascript/_util/util'
import { clone } from 'lodash-es'
import Pica from 'pica'
import * as Sentry from '@sentry/browser'

export const ImageUpload = {
  state: {
    imageUploadStatuses: {
      INITIAL: 'initial',
      UPLOADING: 'uploading',
      SUCCESS: 'success',
      FAILED: 'failed',
    },
    imageUploadList: [],
    showUploadStatusList: false,
  },
  mutations: {
    setPendingUploadListOnLoad: (state) => {
      let pendingUploadList = getLocalStorageValue({
        key: keys.LS_PENDING_IMAGES_FOR_UPLOAD,
        defaultValue: [],
      })

      // Remove all unimportant items
      pendingUploadList = pendingUploadList.filter((x) => !['success', 'failed'].includes(x.status))

      // Set rest to failed
      pendingUploadList = pendingUploadList.map((item) => {
        item.status = state.imageUploadStatuses.FAILED
        return item
      })

      // Set store
      state.imageUploadList = pendingUploadList

      // Set local storage
      setLocalStorageValue({
        key: keys.LS_PENDING_IMAGES_FOR_UPLOAD,
        value: JSON.stringify(state.imageUploadList),
      })

      // Show list if any important items
      if (state.imageUploadList.length) {
        state.showUploadStatusList = true
        setLocalStorageValue({
          key: keys.LS_SHOW_UPLOADING_STATUS_LIST,
          value: state.showUploadStatusList,
        })
        requestAnimationFrame(() => {
          EventBus.$emit('expand-upload-list')
        })
      }
    },
    setShowUploadStatusList: (state, answer) => {
      state.showUploadStatusList = answer
      setLocalStorageValue({ key: keys.LS_SHOW_UPLOADING_STATUS_LIST, value: answer })
    },
    addToPendingUploadList: (state, { imageObject, place }) => {
      const newItems = []
      const rawUploadItem = {
        text: '',
        path: '',
        status: '',
        uploadedPercentage: 0,
      }

      // Add base size
      const baseItem = clone(rawUploadItem)
      baseItem.text = `${ place } - ${ mixGetSizeWordbook('base') }`
      baseItem.path = imageObject.base.path
      baseItem.status = state.imageUploadStatuses.INITIAL
      newItems.unshift(baseItem)

      // Add other sizes
      imageObject.sizes.forEach((size) => {
        const sizeItem = clone(rawUploadItem)
        sizeItem.text = `${ place } - ${ mixGetSizeWordbook(size.name) }`
        sizeItem.path = size.path
        sizeItem.status = state.imageUploadStatuses.INITIAL
        newItems.unshift(sizeItem)
      })

      // Set store
      state.imageUploadList = newItems.concat(state.imageUploadList)

      // Set local storage
      setLocalStorageValue({
        key: keys.LS_PENDING_IMAGES_FOR_UPLOAD,
        value: JSON.stringify(state.imageUploadList),
      })
    },
    setPendingUploadItemPercentage: (state, { path, percentage }) => {
      const item = state.imageUploadList.find((x) => x.path === path)

      item.status = state.imageUploadStatuses.UPLOADING
      item.uploadedPercentage = percentage
    },
    setPendingUploadItemState: (state, { path, status }) => {
      const item = state.imageUploadList.find((x) => x.path === path)

      item.status = status

      // Set localStorage
      setLocalStorageValue({
        key: keys.LS_PENDING_IMAGES_FOR_UPLOAD,
        value: JSON.stringify(state.imageUploadList),
      })
    },
    clearUploadList: (state) => {
      state.imageUploadList = state.imageUploadList.filter((x) => !['success', 'failed'].includes(x.status))

      // Set localStorage
      setLocalStorageValue({
        key: keys.LS_PENDING_IMAGES_FOR_UPLOAD,
        value: JSON.stringify(state.imageUploadList),
      })
    },
  },
  actions: {
    setupUploadStatusListOnLoad: ({ commit }) => {
      // Set list state
      const answer = getLocalStorageValue({
        key: keys.LS_SHOW_UPLOADING_STATUS_LIST,
        defaultValue: false,
      })
      commit('setShowUploadStatusList', answer)

      // Set list items
      commit('setPendingUploadListOnLoad')
    },
    toggleShowUploadStatusList: ({ commit, getters }) => {
      commit('setShowUploadStatusList', !getters.showUploadStatusList)
    },
    async uploadImage({ commit, getters }, {
      imageObject, file, base64URL, place = '', makeSquared = false, makePortrait = false,
    }) {
      // Push to pending upload list
      commit('addToPendingUploadList', { imageObject, place })

      // Get file base 64 URL
      const newBase64URL = base64URL || await getFileBase64URL(file)

      // Create canvases for each size
      const canvasData = await createImageCanvases(imageObject, newBase64URL)

      // Create blobs for each size
      const allBlobs = await createImageBlobs(canvasData, { makePortrait, makeSquared })

      const baseImageBlob = allBlobs[0] && allBlobs[0].blob

      // Emit file loaded
      EventBus.$emit('image-loaded', { basePath: imageObject.base.path, blob: baseImageBlob })

      // Upload each size
      uploadBlobs(allBlobs, commit, getters, makeSquared)
    },
    clearUploadList: ({ commit }) => {
      commit('clearUploadList')
    },
  },
  getters: {
    imageUploadStatuses: (state) => state.imageUploadStatuses,
    imageUploadList: (state) => state.imageUploadList,
    showUploadStatusList: (state) => state.showUploadStatusList,
  },
}

// Helpers
const getFileBase64URL = (file) => new Promise((resolve) => {
  const reader = new FileReader()

  reader.onload = (e) => {
    resolve(e.target.result)
  }
  reader.readAsDataURL(file)
})

const createImageCanvases = (imageObject, base64URL) => new Promise((resolve) => {
  const newImage = new Image()
  const allCanvases = []

  newImage.onload = () => {
    // Base size
    const mainCanvas = createCanvasForResize(
      newImage.height,
      newImage.width,
      imageObject.base.minSize,
    )
    allCanvases.push({ canvas: mainCanvas, path: imageObject.base.path })

    // Extra sizes
    imageObject.sizes.forEach((size) => {
      const extraCanvas = createCanvasForResize(newImage.height, newImage.width, size.minSize)
      allCanvases.push({ canvas: extraCanvas, path: size.path })
    })

    resolve({ allCanvases, newImage })
  }

  newImage.src = base64URL
})

const createCanvasForResize = (imageHeight, imageWidth, minSize) => {
  const newCanvas = document.createElement('canvas')
  let ratio = 1
  if (imageWidth <= imageHeight) {
    ratio = minSize / imageWidth
  }
  else {
    ratio = minSize / imageHeight
  }

  newCanvas.width = imageWidth * ratio
  newCanvas.height = imageHeight * ratio

  return newCanvas
}

const makeCanvasSquare = (originalCanvas) => {
  if (originalCanvas.width === originalCanvas.height) {
    return originalCanvas
  }

  const minimumSize = Math.min(originalCanvas.width, originalCanvas.height)

  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')

  canvas.height = minimumSize
  canvas.width = minimumSize
  context.save()

  // Place the cursor at where the original image's top left corner would be, if it was overlayed
  // centered on top of the squared image.
  // The resulting image will be cropped using the center of the image as anchor.
  context.translate(
    -(originalCanvas.width - canvas.width) / 2,
    -(originalCanvas.height - canvas.height) / 2,
  )
  context.drawImage(
    originalCanvas,
    0, 0, originalCanvas.width, originalCanvas.height,
  )
  context.restore()

  return canvas
}

const rotateCanvasToPortrait = (originalCanvas) => {
  if (originalCanvas.height >= originalCanvas.width) {
    return originalCanvas
  }

  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')

  canvas.height = originalCanvas.width
  canvas.width = originalCanvas.height

  context.save()
  context.translate(canvas.width / 2, canvas.height / 2) // Middle
  context.rotate(-Math.PI / 2)
  // Draw the full original image, but on the new rotated canvas
  context.drawImage(originalCanvas, 0, 0, originalCanvas.width, originalCanvas.height,
    -(canvas.height / 2), -(canvas.width / 2), canvas.height, canvas.width)
  context.restore()

  return canvas
}

const createImageBlobs = (canvasData, { makePortrait, makeSquared }) => new Promise((resolve) => {
  const pica = Pica()
  const allBlobs = []

  canvasData.allCanvases.forEach((item) => {
    pica.resize(canvasData.newImage, item.canvas)
      .then((result) => {
        let canvas = makePortrait ? rotateCanvasToPortrait(result) : result
        canvas = makeSquared ? makeCanvasSquare(canvas) : canvas

        pica.toBlob(canvas, 'image/jpeg', 0.90)
          .then((blob) => {
            allBlobs.push({ blob, path: item.path })

            if (allBlobs.length === canvasData.allCanvases.length) {
              resolve(allBlobs)
            }
          })
      })
  })
})

const uploadBlobs = (allBlobs, commit, getters) => {
  allBlobs.forEach((blob) => {
    // Create the file metadata
    const metadata = {
      cacheControl: 'public,max-age=10800', // 3 hours
    }

    // Upload file and metadata
    const uploadTask = storage.ref().child(blob.path).put(blob.blob, metadata)

    EventBus.$emit('image-upload-started', { path: blob.path })

    // Follow upload progress
    uploadTask.on('state_changed', (state) => {
      commit('setPendingUploadItemPercentage', {
        path: blob.path,
        percentage: Math.ceil((state.bytesTransferred / state.totalBytes) * 100),
      })
    }, (err) => {
      commit('setPendingUploadItemState', { path: blob.path, status: getters.imageUploadStatuses.FAILED })
      commit('setShowUploadStatusList', true)
      requestAnimationFrame(() => {
        EventBus.$emit('expand-upload-list')
      })

      EventBus.$emit('image-upload-failed', { path: blob.path })

      Sentry.captureException(err)
    }, () => {
      commit('setPendingUploadItemState', { path: blob.path, status: getters.imageUploadStatuses.SUCCESS })

      EventBus.$emit('image-uploaded', { path: blob.path })
    })
  })
}
