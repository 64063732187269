import { accountChecklist } from './_checklists'

export const accountRoutes = [
  {
    path: '/account',
    meta: {
      requiresAuth: true,
      requiresSuperUser: true,
      checklist: [
        ...accountChecklist,
      ],
    },
    component: () => import('../views/Account/Overview.vue'),
    children: [
      {
        path: '',
        name: 'Account',
        meta: {
          requiresAuth: true,
          requiresSuperUser: true,
          checklist: [
            ...accountChecklist,
          ],
        },
        component: () => import('../views/Account/Account.vue'),
      },
      {
        path: 'users',
        name: 'AccountUsers',
        meta: {
          requiresAuth: true,
          requiresSuperUser: true,
          checklist: [
            ...accountChecklist,
          ],
        },
        component: () => import('../views/Account/Users.vue'),
      },
      {
        path: 'user/new',
        name: 'AccountUserNew',
        meta: {
          requiresAuth: true,
          requiresSuperUser: true,
          checklist: [
            ...accountChecklist,
          ],
        },
        component: () => import('../views/Account/User.vue'),
      },
      {
        path: 'user/:userID',
        name: 'AccountUser',
        meta: {
          requiresAuth: true,
          checklist: [
            ...accountChecklist,
          ],
        },
        component: () => import('../views/Account/User.vue'),
      },
    ],
  },
]
