<template>
  <div
    class="ToggleButton"
    @click="onClick">
    <label
      :class="{ 'IsActive': isSelected }"
      class="Button">
      <span
        :class="{ 'IsActive': isSelected }"
        class="Knob"/>
    </label>
    <span v-if="text">{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: 'ToggleButton',
  props: {
    groupName: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    isSelected: {
      type: Boolean,
      required: true,
    },
    text: {
      type: String,
      required: false,
      default: '',
    },
  },
  methods: {
    onClick() {
      this.$emit('check', { groupName: this.groupName, value: this.value })
    },
  },
}
</script>

<style lang="stylus" scoped>
  .ToggleButton
    display flex
    align-items center
  .Button
    position relative
    display block
    box(40px, 20px)
    border-radius 26px
    background-color $color_grey_lighter
    transition background 0.2s ease
    margin-right 10px
    &.IsActive
      background-color $color_primary

  .Input
    display block

  .Knob
    position absolute
    top -1px
    left -1px
    display block
    circle(22px)
    background-color #fff
    box-shadow 0 0 5px rgba(0, 0, 0, 0.2)
    transition left 0.2s ease
    &.IsActive
      left 19px
</style>
