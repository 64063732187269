export const mutations = {
  resetAllLoadedScreenings: (state) => {
    state.allScreenings = []

    if (state.unsubscribeAllScreenings) {
      state.unsubscribeAllScreenings()
      state.unsubscribeAllScreenings = null
    }
  },
  resetCurrentScreening: (state) => {
    state.currentScreening.id = null
    state.currentScreening.data = null
    state.currentScreening.metaData = null
    state.currentScreening.buildings = null
    state.currentScreening.units = null
    state.currentScreening.types = null
    state.currentScreening.interConnections = null
    state.currentScreening.addressImages = null
    state.currentScreening.samples = null
    state.currentScreening.pcbScreenings = null
    state.currentScreening.testResults = null
    state.currentScreening.wasteItems = null
    state.currentScreening.relatedScreenings = []
    state.currentScreening.currentEnterpriseID = ''

    state.currentScreeningSelectedFilterUnitID = null

    if (state.unsubscribeScreening) {
      state.unsubscribeScreening()
      state.unsubscribeScreening = null
    }
    if (state.unsubscribeMetaData) {
      state.unsubscribeMetaData()
      state.unsubscribeMetaData = null
    }
    if (state.unsubscribeBuildings) {
      state.unsubscribeBuildings()
      state.unsubscribeBuildings = null
    }
    if (state.unsubscribeUnits) {
      state.unsubscribeUnits()
      state.unsubscribeUnits = null
    }
    if (state.unsubscribeTypes) {
      state.unsubscribeTypes()
      state.unsubscribeTypes = null
    }
    if (state.unsubscribeInterConnections) {
      state.unsubscribeInterConnections()
      state.unsubscribeInterConnections = null
    }
    if (state.unsubscribeAddressImages) {
      state.unsubscribeAddressImages()
      state.unsubscribeAddressImages = null
    }
    if (state.unsubscribeSamples) {
      state.unsubscribeSamples()
      state.unsubscribeSamples = null
    }
    if (state.unsubscribePCBScreenings) {
      state.unsubscribePCBScreenings()
      state.unsubscribePCBScreenings = null
    }
    if (state.unsubscribeTestResults) {
      state.unsubscribeTestResults()
      state.unsubscribeTestResults = null
    }
    if (state.unsubscribeWasteItems) {
      state.unsubscribeWasteItems()
      state.unsubscribeWasteItems = null
    }
    if (state.unsubscribeRelatedScreenings) {
      state.unsubscribeRelatedScreenings()
      state.unsubscribeRelatedScreenings = null
    }
  },
  setSnapshotUnsubscribe: (state, { type, unsubscribe }) => {
    // Current screening
    if (type === 'screening') {
      state.unsubscribeScreening = unsubscribe
    }
  },
  updateCurrentScreening: (state, {
    screeningID,
    data,
    unsubscribe,
  }) => {
    state.currentScreening.id = screeningID
    state.currentScreening.data = data
    if (!state.unsubscribeScreening) {
      state.unsubscribeScreening = unsubscribe
    }
  },
  updateRelatedScreenings: (state, { relatedScreenings, unsubscribe }) => {
    state.currentScreening.relatedScreenings = relatedScreenings
    if (!state.unsubscribeRelatedScreenings) {
      state.unsubscribeRelatedScreenings = unsubscribe
    }
  },
  resetRelatedScreenings: (state) => {
    state.currentScreening.relatedScreenings = []
    if (state.unsubscribeRelatedScreenings) {
      state.unsubscribeRelatedScreenings()
      state.unsubscribeRelatedScreenings = null
    }
  },
  updateCurrentScreeningMetaData: (state, { data, unsubscribe }) => {
    state.currentScreening.metaData = data
    if (!state.unsubscribeMetaData) {
      state.unsubscribeMetaData = unsubscribe
    }
  },
  updateCurrentScreeningBuildings: (state, { buildings, unsubscribe }) => {
    state.currentScreening.buildings = buildings
    if (!state.unsubscribeBuildings) {
      state.unsubscribeBuildings = unsubscribe
    }
  },
  updateCurrentScreeningUnits: (state, { units, unsubscribe }) => {
    state.currentScreening.units = units
    if (!state.unsubscribeUnits) {
      state.unsubscribeUnits = unsubscribe
    }
  },
  updateCurrentScreeningTypes: (state, { types, unsubscribe }) => {
    state.currentScreening.types = types
    if (!state.unsubscribeTypes) {
      state.unsubscribeTypes = unsubscribe
    }
  },
  updateCurrentScreeningInterConnections: (state, { interConnections, unsubscribe }) => {
    state.currentScreening.interConnections = interConnections
    if (!state.unsubscribeInterConnections) {
      state.unsubscribeInterConnections = unsubscribe
    }
  },
  updateCurrentScreeningAddressImages: (state, {
    addressImages,
    unsubscribe,
  }) => {
    state.currentScreening.addressImages = addressImages
    if (!state.unsubscribeAddressImages) {
      state.unsubscribeAddressImages = unsubscribe
    }
  },
  updateCurrentScreeningSamples: (state, { samples, unsubscribe }) => {
    state.currentScreening.samples = samples
    if (!state.unsubscribeSamples) {
      state.unsubscribeSamples = unsubscribe
    }
  },
  updateCurrentScreeningPCBScreenings: (state, { pcbScreenings, unsubscribe }) => {
    state.currentScreening.pcbScreenings = pcbScreenings
    if (!state.unsubscribePCBScreenings) {
      state.unsubscribePCBScreenings = unsubscribe
    }
  },
  updateCurrentScreeningTestResults: (state, { testResults, unsubscribe }) => {
    state.currentScreening.testResults = testResults
    if (!state.unsubscribeTestResults) {
      state.unsubscribeTestResults = unsubscribe
    }
  },
  updateCurrentScreeningWasteItems: (state, { wasteItems, unsubscribe }) => {
    state.currentScreening.wasteItems = wasteItems
    if (!state.unsubscribeWasteItems) {
      state.unsubscribeWasteItems = unsubscribe
    }
  },
  updateAllScreenings: (state, { allScreenings, unsubscribe }) => {
    state.allScreenings = allScreenings
    if (!state.unsubscribeAllScreenings) {
      state.unsubscribeAllScreenings = unsubscribe
    }
  },
  updateCurrentEnterpriseID: (state, enterpriseID) => {
    state.currentScreening.currentEnterpriseID = enterpriseID
  },
  updateCrrentScreeningSelectedFilterUnitID: (state, unitID) => {
    state.currentScreeningSelectedFilterUnitID = unitID
  },
}
