/* eslint-disable no-use-before-define */
import { mixWB } from '@/globals/javascript/_util/mixins'
import store from '@/store/index'
import { firebase } from '@/firebase/init'
import { getTimeSpendText } from '@/globals/javascript/_util/util'
import { DB_SCREENINGS, setIDOnCreate } from './_helper'
import { Building } from './units/Building'

const TRACKING_MAP = {
  created: 'isCreatedSet',
  started: 'isStartedSet',
  completed: 'isCompletedSet',
  requisitionSent: 'isRequisitionSentSet',
  billable: 'isBillableSet',
}

export default class Screening {
  constructor({
    id = null,
    enterpriseID = '',
    createdByUserID = null,
    userID = null,
    appVersion = null,
    accountID = null,
    screeningNumber = null,
    samplingID = null,

    // Derived data
    address = {
      address: null,
      postalCode: null,
      city: null,
    },
    projectType = null,
    builderFullName = null,
    selectedUnits = {
      count: 0,
      ids: [],
      units: [],
    },
    frontImage = null,
    skippedCategories = {
      general: [],
    },
    tenderDetails = {
      otherTexts: [],
      selectedQuestionIDs: [],
      startDate: null,
      endDate: null,
    },
    requisition = {
      deliveryDayOptions: '5-days-17',
    },

    // Booleans
    isStarted = false,
    isCompleted = false,
    isArchived = false,
    hasSavedBBRUnits = false,
    isTestDataUploaded = false,
    isRequisitionSent = false,

    // Timings
    createdAt = null,
    lastUpdated = null,
    screeningYear = null,
    screeningMonth = null,
    screeningArchivedTime = null,
    screeningTimeTracking = [],
    screeningStartTime = null,
    screeningEndTime = null,

    // Outdated
    selectedBuildings = {
      count: 0,
      ids: [],
    },

    statsTracking = {
      isCreatedSet: false,
      isStartedSet: false,
      isCompletedSet: false,
      isRequisitionSentSet: false,
      isBillableSet: false,
    },

  }) {
    this.id = setIDOnCreate(id, DB_SCREENINGS)
    this.enterpriseID = enterpriseID
    this.createdByUserID = createdByUserID || store.getters.currentUser.id
    this.userID = userID
    this.appVersion = appVersion || store.getters.packageVersion
    this.accountID = accountID
    this.screeningNumber = screeningNumber
    this.samplingID = samplingID

    // Derived data
    this.address = {
      ...address,
    }
    this.projectType = projectType
    this.builderFullName = builderFullName
    this.selectedUnits = {
      count: selectedUnits.count || 0,
      ids: selectedUnits.ids || [],
      units: selectedUnits.units || [],
    }
    this.frontImage = frontImage
    this.skippedCategories = {
      ...skippedCategories,
    }
    this.tenderDetails = {
      ...tenderDetails,
    }
    this.requisition = {
      ...requisition,
    }

    // Booleans
    this.isStarted = isStarted
    this.isCompleted = isCompleted
    this.isArchived = isArchived
    this.hasSavedBBRUnits = hasSavedBBRUnits
    this.isTestDataUploaded = isTestDataUploaded
    this.isRequisitionSent = isRequisitionSent

    // Timings
    this.createdAt = createdAt || firebase.firestore.FieldValue.serverTimestamp()
    this.lastUpdated = lastUpdated || firebase.firestore.FieldValue.serverTimestamp()
    this.screeningYear = screeningYear || new Date().getFullYear()
    this.screeningMonth = screeningMonth || new Date().getMonth() + 1
    this.screeningArchivedTime = screeningArchivedTime
    this.screeningTimeTracking = screeningTimeTracking
    this.screeningStartTime = screeningStartTime
    this.screeningEndTime = screeningEndTime

    // Outdated
    this.selectedBuildings = {
      ...selectedBuildings,
    }

    // Stats tracking
    this.statsTracking = {
      isCreatedSet: statsTracking.isCreatedSet || false,
      isStartedSet: statsTracking.isStartedSet || false,
      isCompletedSet: statsTracking.isCompletedSet || false,
      isRequisitionSentSet: statsTracking.isRequisitionSentSet || false,
      isBillableSet: statsTracking.isBillableSet || false,
    }
  }

  caseNumber() {
    if (!this.screeningNumber) {
      return false
    }
    return `${
      store.getters.currentAccount.companyInitials
    }-${
      this.screeningYear
    }-${
      this.screeningNumber
    }`
  }

  getSamplingID() {
    const user = store.getters.allUsers.find((x) => x.id === this.userID)
    if (!user || !store.getters.currentAccount?.companyInitials) {
      return ''
    }
    return `${ store.getters.currentAccount.companyInitials }-${ user.initials }-${ this.samplingID }`
  }

  getStatus() {
    if (this.isTestDataUploaded) {
      return {
        class: 'FlowClass3',
        text: mixWB('STATUS_REPORT_READY'),
      }
    }

    if (this.isRequisitionSent) {
      return {
        class: 'FlowClass2',
        text: mixWB('STATUS_AWAITING_TEST_RESULTS'),
      }
    }

    if (this.isCompleted) {
      return {
        class: 'FlowClass2',
        text: mixWB('STATUS_COMPLETED'),
      }
    }

    if (this.isStarted) {
      return {
        class: 'FlowClass1',
        text: mixWB('STATUS_STARTED'),
      }
    }
    return {
      class: 'FlowClass1',
      text: mixWB('STATUS_NOT_STARTED'),
    }
  }

  // Methods
  updateScreeningTimeUsed() {
    // Add first entry
    if (!this.screeningTimeTracking.length) {
      const newInterval = {
        start: new Date().getTime(),
        last: new Date().getTime(),
      }
      this.screeningTimeTracking.push(newInterval)
      return
    }

    const intervalToUse = this.screeningTimeTracking[this.screeningTimeTracking.length - 1]
    const maxPause = 30 * 60 * 1000 // 30 min
    const currentTime = new Date().getTime()
    const diff = currentTime - intervalToUse.last

    // Pause exeeded - add new entry
    if (diff > maxPause) {
      const newInterval = {
        start: new Date().getTime(),
        last: new Date().getTime(),
      }
      this.screeningTimeTracking.push(newInterval)
      return
    }

    intervalToUse.last = currentTime
  }

  getScreeningTimeUsed() {
    // Handle old screenings
    if (!this.screeningTimeTracking.length && (this.screeningStartTime && this.screeningEndTime)) {
      return getTimeSpendText({
        start: this.screeningStartTime,
        end: this.screeningEndTime,
      })
    }

    if (!this.screeningTimeTracking.length) {
      return false
    }

    const accumulatedTimeUsed = this.screeningTimeTracking.reduce((prev, interval) => {
      const diff = interval.last - interval.start
      if (diff) {
        prev += diff
      }

      return prev
    }, 0)

    if (!accumulatedTimeUsed) {
      return false
    }

    return getTimeSpendText({
      duration: accumulatedTimeUsed,
    })
  }

  getBuildingNames() {
    return this.selectedUnits.units.reduce((prev, unit) => {
      if (unit.type !== 'building') {
        return prev
      }

      const building = new Building({
        ...unit,
      })

      prev.push(building.getTitle())

      return prev
    }, [])
  }

  trackStats(name) {
    this.statsTracking[TRACKING_MAP[name]] = true
  }

  hasStatsTracking(name) {
    return this.statsTracking[TRACKING_MAP[name]]
  }

  static showInBackend(screeningID) {
    if (process.env.NODE_ENV === 'development') {
      const url = `https://console.firebase.google.com/u/0/project/milva-pro-dev/firestore/data/~2FScreenings~2F${ screeningID }`
      window.open(url, '_blank')
    }
    else {
      const url = `https://console.firebase.google.com/u/0/project/milva-pro-prod/firestore/data/~2FScreenings~2F${ screeningID }`
      window.open(url, '_blank')
    }
  }
}
