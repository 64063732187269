/* eslint-disable no-use-before-define */
import { union } from 'lodash-es'
import store from '@/store'
import GroupedTypeResult from '@/globals/javascript/models/GroupedTypeResult'
import { testResultsGroups } from '@/globals/javascript/_util/test-results'
import { mixWB } from '@/globals/javascript/_util/mixins.js'

export const getWorkDescriptionForGroupedType = ({ groupedType }) => {
  const {
    isTestDataUploaded,
    screeningSamples,
    categoryTypes,
    workDescriptionGroups,
    workDescriptionCasesAsArray,
  } = store.getters

  const resultObject = new GroupedTypeResult({
    groupedTypeID: groupedType.id,
  })

  // No test results uploaded
  if (!isTestDataUploaded) {
    resultObject.isMissingUploadedData = true
    return resultObject
  }

  // Resale
  const firstResaleItem = groupedType.types.find((x) => x.resale === 'yes')
  if (firstResaleItem) {
    resultObject.isResale = true
    return resultObject
  }

  const allTypes = groupedType.types
  const allTypeIDs = allTypes.map((x) => x.id)
  const isMultiType = allTypes.length > 1
  const samples = screeningSamples.filter((x) => allTypeIDs.includes(x.typeID))
  const {
    allCategoryTypes,
    allMaterials,
  } = getAllCategoryTypesAndMaterials(allTypes)
  const isAllMetalOrIron = getIsAllMetalOrIron(allMaterials)

  let wdgID = !isMultiType ? getWDGID(allTypes, allCategoryTypes) : 'WDG-8'

  resultObject.wdgID = wdgID || null
  resultObject.isMultiType = isMultiType
  resultObject.isAllMetalOrIron = isAllMetalOrIron
  resultObject.isFirstTypeTilesWithAdhesives = getIsFirstTypeTilesWithAdhesives(allTypes[0])
  resultObject.isFirstTypeLeveling = getIsFirstTypeLeveling(allTypes[0])
  resultObject.isLastTypeWallpaperWithCoating = getIsLastTypeWallpaperWithCoating(
    allTypes[allTypes.length - 1],
  )
  resultObject.allTypesHasMaterialSample = getAllTypesHasMaterialSample(
    samples,
    allTypes,
    resultObject.isLastTypeWallpaperWithCoating,
  )
  resultObject.hasSamples = !!samples.length

  // No work description group ID
  if (!wdgID) {
    return resultObject
  }

  // Type is missing a sample
  const isMissingSample = checkForMissingSample({ groupedType, samples })
  if (isMissingSample) {
    resultObject.isMissingSample = true
    return resultObject
  }

  // Special cases
  if (groupedType.types.length === 1) {
    const isSpecialCase = checkForSpecialCase({ type: groupedType.types[0], resultObject })
    if (isSpecialCase) {
      return resultObject
    }
  }

  // Special tags
  if (groupedType.types.length === 1) {
    checkForSpecialTags({ type: groupedType.types[0], resultObject })
  }

  // No samples aka clean
  if (!samples.length) {
    return resultObject
  }

  // Get underlining for first type
  const firstType = allTypes[0]
  const underliningSampleForFirstType = screeningSamples.find(
    (x) => x.kindOfSample === 'underlining' && x.typeID === firstType.id,
  )

  // Get coating sample for last type
  const lastType = allTypes[allTypes.length - 1]
  const coatingSampleForLastType = screeningSamples.find(
    (x) => x.kindOfSample === 'coating' && x.typeID === lastType.id,
  )

  // Get all samples except underlining for first type and coating sample for the last type
  const multiTypeSamples = screeningSamples.filter((sample) => {
    if (coatingSampleForLastType && sample.id === coatingSampleForLastType.id) {
      return false
    }

    if (underliningSampleForFirstType && sample.id === underliningSampleForFirstType.id) {
      return false
    }

    if (!allTypeIDs.includes(sample.typeID)) {
      return false
    }

    return sample
  })

  // Set left side contamination
  const firstTypeMaterialSample = screeningSamples.find((x) => x.typeID === firstType.id && x.kindOfSample === 'material')
  if (firstTypeMaterialSample) {
    const {
      wdcID,
    } = getResultData({ sample: firstTypeMaterialSample })

    if (wdcID) {
      resultObject.leftSideWDCID = wdcID
    }
  }

  if (underliningSampleForFirstType) {
    const {
      wdcID,
    } = getResultData({ sample: underliningSampleForFirstType })

    if (wdcID) {
      if (!resultObject.leftSideWDCID) {
        resultObject.leftSideWDCID = wdcID
      }
      else {
        const materialContaminationScaleValue = getContaminationScaleValue(
          resultObject.leftSideWDCID,
        )
        const underliningContaminationScaleValue = getContaminationScaleValue(wdcID)
        const combinedContaminationScaleValue = getCombinedContaminationScaleValue([
          materialContaminationScaleValue,
          underliningContaminationScaleValue,
        ])
        const finalWDCItem = workDescriptionCasesAsArray.find(
          (x) => x.contaminationScaleValue === combinedContaminationScaleValue,
        )
        resultObject.leftSideWDCID = finalWDCItem.id
      }
    }
  }

  // Set right side contamination
  if (coatingSampleForLastType) {
    const {
      wdcID,
    } = getResultData({ sample: coatingSampleForLastType })
    resultObject.rightSideWDCID = wdcID
  }
  else {
    const lastTypeMaterialSample = screeningSamples.find((x) => x.typeID === lastType.id && x.kindOfSample === 'material')
    if (lastTypeMaterialSample) {
      const {
        wdcID,
      } = getResultData({ sample: lastTypeMaterialSample })
      resultObject.rightSideWDCID = wdcID
    }
  }

  // Get results for coating
  if (coatingSampleForLastType) {
    const {
      wdcID,
      contaminatedGroupIDs = [],
      hazardiousGroupIDs = [],
      isMissingTestResults = false,
    } = getResultData({ sample: coatingSampleForLastType })
    resultObject.coating.wdcID = wdcID
    resultObject.coating.contaminatedGroupIDs = contaminatedGroupIDs
    resultObject.coating.hazardiousGroupIDs = hazardiousGroupIDs

    if (isMissingTestResults) {
      resultObject.isMissingTestResults = true
      return resultObject
    }
    // Check for assessments
    if (coatingSampleForLastType.assessNumber) {
      resultObject.coating.isAssessment = true
      addAssessmentCase({ resultObject, sample: coatingSampleForLastType, wdcID })
    }
  }

  // Get results for underlining
  if (underliningSampleForFirstType) {
    const {
      wdcID,
      contaminatedGroupIDs = [],
      hazardiousGroupIDs = [],
      isMissingTestResults = false,
    } = getResultData({ sample: underliningSampleForFirstType })
    resultObject.underlining.wdcID = wdcID
    resultObject.underlining.contaminatedGroupIDs = contaminatedGroupIDs
    resultObject.underlining.hazardiousGroupIDs = hazardiousGroupIDs
    resultObject.underlining.underliningTypeIDs = firstType.underlining.underliningTypeIDs

    if (isMissingTestResults) {
      resultObject.isMissingTestResults = true
      return resultObject
    }
    // Check for assessments
    if (underliningSampleForFirstType.assessNumber) {
      resultObject.coating.isAssessment = true
      addAssessmentCase({ resultObject, sample: underliningSampleForFirstType, wdcID })
    }
  }

  // Get combined result for multi type
  let materialContaminatedGroupIDs = []
  let materialHazardiousGroupIDs = []
  let materialWDCID = 'WDC-1'
  let isMaterialMissingTestResult = false
  multiTypeSamples.forEach((sample) => {
    if (isMaterialMissingTestResult) {
      return
    }

    const {
      wdcID,
      contaminatedGroupIDs = [],
      hazardiousGroupIDs = [],
      isMissingTestResults = false,
    } = getResultData({ sample })
    materialContaminatedGroupIDs = union(materialContaminatedGroupIDs, contaminatedGroupIDs)
    materialHazardiousGroupIDs = union(materialHazardiousGroupIDs, hazardiousGroupIDs)

    if (isMissingTestResults) {
      isMaterialMissingTestResult = true
    }

    // Set WDC ID
    if (wdcID) {
      const materialContaminationScaleValue = getContaminationScaleValue(materialWDCID)
      const sampleContaminationScaleValue = getContaminationScaleValue(wdcID)
      const combinedContaminationScaleValue = getCombinedContaminationScaleValue([
        materialContaminationScaleValue,
        sampleContaminationScaleValue,
      ])
      const finalWDCItem = workDescriptionCasesAsArray.find(
        (x) => x.contaminationScaleValue === combinedContaminationScaleValue,
      )
      materialWDCID = finalWDCItem.id
    }

    // Check for dusty asbestos
    if (wdgID !== 'WDG-9' && hazardiousGroupIDs.includes('ASB')) {
      const type = allTypes.find((x) => x.id === sample.typeID)
      let typeWDGID = null
      if (type.type.isOtherType) {
        typeWDGID = type.type.otherTypeWDGID
      }
      else {
        const categoryType = categoryTypes.find((x) => x.id === type.type.typeID)
        typeWDGID = categoryType.wdgID
      }
      const { hasDustyAsbestos } = workDescriptionGroups[typeWDGID]

      if (hasDustyAsbestos) {
        wdgID = 'WDG-9'
        resultObject.wdgID = wdgID
      }
    }

    // Check for assessments
    if (sample.assessNumber) {
      addAssessmentCase({ resultObject, sample, wdcID })
    }
  })
  resultObject.material.wdcID = materialWDCID
  resultObject.material.contaminatedGroupIDs = materialContaminatedGroupIDs
  resultObject.material.hazardiousGroupIDs = materialHazardiousGroupIDs

  if (isMaterialMissingTestResult) {
    resultObject.isMissingTestResults = true
  }

  return resultObject
}

// Private functions
const checkForMissingSample = ({ groupedType, samples }) => {
  let isMissingSample = false
  groupedType.types.forEach((type) => {
    if (isMissingSample) {
      return
    }

    const needMaterialSample = !!type.samples.sampleIDs.length
    const needCoatingSample = !!type.coating.samples.sampleIDs.length
    const needUnderliningSample = !!type.underlining.samples.sampleIDs.length

    if (needMaterialSample) {
      const materialSample = samples.find(
        (x) => x.typeID === type.id && x.kindOfSample === 'material',
      )
      if (!materialSample) {
        isMissingSample = true
      }
    }
    if (needCoatingSample) {
      const coatingSample = samples.find(
        (x) => x.typeID === type.id && x.kindOfSample === 'coating',
      )
      if (!coatingSample) {
        isMissingSample = true
      }
    }
    if (needUnderliningSample) {
      const underliningSample = samples.find(
        (x) => x.typeID === type.id && x.kindOfSample === 'underlining',
      )
      if (!underliningSample) {
        isMissingSample = true
      }
    }
  })

  return isMissingSample
}

const checkForSpecialCase = ({ type, resultObject }) => {
  let isSpecialCase = false

  // Electrical installations
  if (['CT-62', 'CT-63'].includes(type.type.typeID)) {
    resultObject.material.wdcID = 'WDC-3'
    resultObject.specialCase = {
      hideExplanationText: true,
      caseID: 'special-case-1',
    }
    isSpecialCase = true
  }

  // Isolation CT-66, CT-67
  if (['CT-66', 'CT-67'].includes(type.type.typeID)) {
    resultObject.material.wdcID = 'WDC-3'
    resultObject.specialCase = {
      hideExplanationText: true,
      caseID: 'special-case-2',
    }
    isSpecialCase = true
  }

  // Chimneys
  if (type.categoryID === 'C-16') {
    if (['CT-74', 'CT-91', 'CT-92'].includes(type.type.typeID) || type.type.isOtherType) {
      resultObject.material.wdcID = 'WDC-2'
      resultObject.specialCase = {
        caseID: 'special-case-3',
      }
      isSpecialCase = true
    }
  }

  return isSpecialCase
}

const checkForSpecialTags = ({ type, resultObject }) => {
  // No asbestos for tiles
  if (
    (type.categoryID === 'C-6' || type.categoryID === 'C-7')
    && !type.samples.sampleIDs.includes('ST-1')
    && type.hasTags({ tags: ['TAG-2'] })
  ) {
    resultObject.specialTags.push('no-asbestos-tiles')
  }

  // No asbestos as after 1988
  if (
    [
      'CT-1', 'CT-2', 'CT-8', 'CT-9', 'CT-16', 'CT-46', 'CT-51', 'CT-77', 'CT-79', 'CT-81', 'CT-101',
    ].includes(type.type.typeID)
    && !type.samples.sampleIDs.includes('ST-1')
  ) {
    resultObject.specialTags.push('no-asbestos-sample-required')
  }

  // Has oil stains
  if (type.hasTags({ tags: ['TAG-1'] })) {
    resultObject.specialTags.push('has-oil-stains')
  }
}

const getWDGID = (allTypes, allCategoryTypes) => {
  const type = allTypes[0]
  if (type.type.isOtherType) {
    return type.type.otherTypeWDGID
  }
  const categoryType = allCategoryTypes[0]
  return categoryType.wdgID
}

const getResultData = ({ sample }) => {
  // Normal or equivalent sample
  if (sample.sampleNumber || sample.equivalentSampleNumber) {
    return getNormalResultData({ sample })
  }

  // Assessed
  if (sample.assessNumber) {
    return getAssessResultData({ sample })
  }

  return false
}

const getNormalResultData = ({ sample }) => {
  const {
    screeningTestResults,
    screeningRelatedScreenings,
  } = store.getters
  let sampleNumber = false
  if (sample.sampleNumber) {
    sampleNumber = sample.sampleNumber
  }
  if (sample.equivalentSampleNumber) {
    sampleNumber = sample.equivalentSampleNumber
  }

  let testResultItem = false
  if (sampleNumber) {
    // Get test result from related screening
    if (sample.otherScreeningID) {
      const relatedScreening = screeningRelatedScreenings.find(
        (x) => x.data.id === sample.otherScreeningID,
      )
      testResultItem = relatedScreening?.testResults?.find(
        (x) => x.sampleNumber === sampleNumber,
      )
    }
    else {
      testResultItem = screeningTestResults.find((x) => x.sampleNumber === sampleNumber)
    }
  }

  if (!testResultItem) {
    return { isMissingTestResults: true }
  }

  let hazardiousGroupIDs = []
  let contaminatedGroupIDs = []

  if (testResultItem) {
    const testGroupsResults = getTestGroupsResults({ testResultItem })
    hazardiousGroupIDs = testGroupsResults.hazardiousGroupIDs
    contaminatedGroupIDs = testGroupsResults.contaminatedGroupIDs
  }

  if (hazardiousGroupIDs.includes('ASB')) {
    // Asbestos + hazardous waste
    if (hazardiousGroupIDs.length > 1) {
      return {
        wdcID: 'WDC-5',
        contaminatedGroupIDs,
        hazardiousGroupIDs,
      }
    }
    // Asbestos

    return {
      wdcID: 'WDC-4',
      contaminatedGroupIDs,
      hazardiousGroupIDs,
    }
  }

  // Hazardious
  if (hazardiousGroupIDs.length) {
    return {
      wdcID: 'WDC-3',
      contaminatedGroupIDs,
      hazardiousGroupIDs,
    }
  }

  // Contaminated
  if (contaminatedGroupIDs.length) {
    return {
      wdcID: 'WDC-2',
      contaminatedGroupIDs,
      hazardiousGroupIDs,
    }
  }

  // Clean / Safe
  return {
    wdcID: 'WDC-1',
    contaminatedGroupIDs,
    hazardiousGroupIDs,
  }
}

const getAssessResultData = ({ sample }) => {
  const {
    screeningTypes,
  } = store.getters

  const type = screeningTypes.find((x) => x.id === sample.typeID)
  let sampleIDs = []
  if (sample.kindOfSample === 'material') {
    sampleIDs = type.samples.sampleIDs
  }
  if (sample.kindOfSample === 'coating') {
    sampleIDs = type.coating.samples.sampleIDs
  }
  if (sample.kindOfSample === 'underlining') {
    sampleIDs = type.underlining.samples.sampleIDs
  }
  const { assessmentIDs } = type.assessments
  const caseData = {
    wdcID: null,
    contaminatedGroupIDs: [],
    hazardiousGroupIDs: [],
  }

  // Case of asbestos (perforated ceiling tiles)
  if (sample.status === 'auto-assess-asbestos-perforated-ceiling-tiles') {
    caseData.wdcID = 'WDC-4'
    caseData.hazardiousGroupIDs = union(caseData.hazardiousGroupIDs, ['ASB'])
  }

  // Case of asbestos (fiber concrete)
  if (sample.status === 'skip-asbest') {
    caseData.wdcID = 'WDC-4'
    caseData.hazardiousGroupIDs = union(caseData.hazardiousGroupIDs, ['ASB'])
  }

  // Case of hazardous waste
  if (sample.status === 'skip-hazardous-waste') {
    const riskOfPCB = sampleIDs.includes('ST-4')
    const hasAsbestos = sampleIDs.includes('ST-1') || assessmentIDs.includes('ST-1')
    caseData.wdcID = hasAsbestos ? 'WDC-5' : 'WDC-3'
    if (riskOfPCB) {
      caseData.hazardiousGroupIDs = union(caseData.hazardiousGroupIDs, ['PCB'])
    }
  }

  return caseData
}

const getTestGroupsResults = ({ testResultItem }) => testResultsGroups.reduce((prev, testGroup) => {
  // Get all tests in collection
  const collectionData = testGroup.tests.reduce((prevy, test) => {
    const item = testResultItem.results.find((x) => x.id === test.id)
    item.wordbook = test.wordbook

    // Update collection result
    if (item.result !== null) {
      if (prevy.collectionResult === null) {
        prevy.collectionResult = item.result
      }
      else if (item.result > prevy.collectionResult) {
        prevy.collectionResult = item.result
      }
    }

    // Update collection for having values
    if (!prevy.hasAnyValues && (item.value !== null || item.isTooLowToMeasure)) {
      prevy.hasAnyValues = true
    }

    prevy.items.push(item)
    return prevy
  }, {
    collectionResult: null,
    id: testGroup.id,
    items: [],
    hasAnyValues: false,
  })

  if (collectionData.collectionResult === 2) {
    prev.hazardiousGroupIDs.push(collectionData.id)
  }
  if (collectionData.collectionResult === 1) {
    prev.contaminatedGroupIDs.push(collectionData.id)
  }

  // Special cases
  if (testGroup.id === '7-metals' && !prev.hazardiousGroupIDs.includes('7-metals') && testResultItem.metalsAboveThreshold) {
    prev.hazardiousGroupIDs.push('7-metals-above-threshold')
  }
  if (testGroup.id === 'CP_SCREENING' && testResultItem.cpScreeningAutoProven) {
    prev.hazardiousGroupIDs.push('CP_SHORT')
    prev.hazardiousGroupIDs.push('CP_MEDIUM')
  }

  prev.groups[collectionData.id] = collectionData
  return prev
}, {
  groups: {},
  hazardiousGroupIDs: [],
  contaminatedGroupIDs: [],
})

// Helper functions
const getAllCategoryTypesAndMaterials = (allTypes) => {
  const {
    categoryTypes,
    materials,
  } = store.getters

  return allTypes.reduce((prev, type) => {
    if (type.type.isOtherType) {
      return prev
    }
    const categoryType = categoryTypes.find((x) => x.id === type.type.typeID)
    const material = materials.find((x) => x.id === categoryType.materialID)

    prev.allCategoryTypes = union(prev.allCategoryTypes, [categoryType])
    if (material) {
      prev.allMaterials = union(prev.allMaterials, [material])
    }
    return prev
  }, { allCategoryTypes: [], allMaterials: [] })
}

const getIsAllMetalOrIron = (allMaterials) => {
  if (!allMaterials.length) {
    return false
  }
  return !allMaterials.find((x) => !x.isMetalOrIron)
}

const getIsFirstTypeTilesWithAdhesives = (
  firstType,
) => !!['C-6', 'C-7', 'C-17'].includes(firstType.categoryID) && firstType.hasTags({ tags: ['TAG-2'] })

const getIsFirstTypeLeveling = (firstType) => {
  const {
    categoryTypes,
  } = store.getters

  const categoryType = categoryTypes.find((x) => x.id === firstType.type.typeID)
  return !!categoryType?.isLeveling
}

const getIsLastTypeWallpaperWithCoating = (lastType) => !!(lastType.type.typeID === 'CT-88'
  && lastType.coating.samples.sampleIDs.length)

const getAllTypesHasMaterialSample = (samples, allTypes, isLastTypeWallpaperWithCoating) => {
  const materialSamples = samples.filter((sample) => {
    // Don't include sample if its sample is of last type which is wallpater with coating
    if (isLastTypeWallpaperWithCoating) {
      const type = store.getters.screeningTypes.find((x) => x.id === sample.typeID)
      if (type.id === allTypes[allTypes.length - 1].id) {
        return false
      }
    }

    return sample.kindOfSample === 'material'
  })

  if (isLastTypeWallpaperWithCoating) {
    return materialSamples.length === allTypes.length - 1
  }
  return materialSamples.length === allTypes.length
}

const getContaminationScaleValue = (wdcID) => {
  const { workDescriptionCases } = store.getters
  return workDescriptionCases[wdcID].contaminationScaleValue
}

const getCombinedContaminationScaleValue = (valuesArray) => valuesArray.reduce((prev, number) => {
  if (prev === 5) {
    return prev
  }
  if (number === 5) {
    prev = 5
    return prev
  }
  if (number === 4) {
    if (prev === 3) {
      prev = 5
      return prev
    }
    prev = 4
    return prev
  }
  if (number === 3) {
    if (prev === 4) {
      prev = 5
      return prev
    }
    prev = 3
    return prev
  }
  if (number > prev) {
    prev = number
  }
  return prev
}, 1)

const addAssessmentCase = ({ resultObject, sample, wdcID }) => {
  const {
    screeningTypes,
  } = store.getters

  const type = screeningTypes.find((x) => x.id === sample.typeID)
  resultObject.assessCases.push({
    wdcID,
    status: sample.status,
    isAutoAssessment: sample.isAutoAssessment,
    sampleTitle: `V${
      sample.assessNumber
    } - ${
      type.getTitle()
    } ${
      sample.kindOfSample === 'coating' ? `(${ mixWB('COATING') })` : ''
    } ${
      sample.kindOfSample === 'underlining' ? `(${ mixWB('UNDERLINING') })` : ''
    }`,
  })
}
